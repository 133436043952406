import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CreateModelNode from "../components/Admin/CreateModelNode";
import ModelNodesList from "../components/Admin/ModelNodesList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={ value !== index }
      id={ `simple-tabpanel-${index}` }
      aria-labelledby={ `simple-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box sx={ { p: 3, overflow:'auto' } }>
          {children}
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AdminConsole () {

  const [value, setValue] = useState(0);
  const [isCreatingNewModelNode, setIsCreatingNewModelNode] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <Box sx={ { width:'100%', height:'100%', display:'flex',  alignItems:'center', flexDirection:'column', p:8 } }>
      <Box>
        <Typography>Admin console</Typography>
      </Box>

      <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
        <Tabs value={ value } onChange={ handleChange } aria-label="admin console tab">
          <Tab label="Models" { ...a11yProps(0) } />
          <Tab label="Placeholder" { ...a11yProps(1) } />
        </Tabs>
      </Box>
      <CustomTabPanel value={ value } index={ 0 }>
        <Box>
          {!isCreatingNewModelNode ?
            (
              <>
                <Button sx={ { mb:2 } } variant='contained' onClick={ ()=> setIsCreatingNewModelNode(true) }>Add new Model</Button>
                <ModelNodesList />
              </>
            ):(
              <CreateModelNode handleBack={ ()=>setIsCreatingNewModelNode(false) } />
            )}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={ value } index={ 1 }>Placeholder</CustomTabPanel>

    </Box>
  );
}

export default AdminConsole;