import { Box, Divider, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { color } from "../../colors";
import nodeTypesMap from "./nodeTypesMap";

function PropertiesDrawer ({ selectedNodes, updateNodeData, container }) {

  // useEffect(()=>{
  //     console.log("selectedNode",selectedNodes );
  // },[selectedNodes, container]);

  return (
    <Box
      sx={ {
        height:'100%',
        overflow:'auto',
        backgroundColor:color.Dark_Blue,
        borderColor:`${color.Dark_Grey} !important`,
        border:'1px solid',
        borderRadius:'4px',
      } }
    >
      {selectedNodes.length!==0 &&
            <Box sx={ {} }>
              <Box id="properties-title" sx={ { position:'fixed', width:'99%', backgroundColor:color.Dark_Blue, zIndex:'1000', pt:1 } }>
                <Typography variant="caption" fontWeight='bold' sx={ { py:1, px:2 } }>Properties</Typography>
                <Divider sx={ { mt:1 } } />
              </Box>
              <Box sx={ { pt:4 } }>
                {selectedNodes.map((node)=>
                {
                  const NodeComponent = nodeTypesMap[node.type];
                  if(!NodeComponent){
                    return (<Box key={ node.id } ></Box>);
                  }
                  else return (
                    <Box key={ node.id }>
                      {NodeComponent && selectedNodes.length > 1 ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1-content"
                            id={ `properties-drawer-item-${node.id}` }
                            sx={ {
                              borderBottom:'1px solid',
                              borderColor:color.Dark_Grey,
                              overflow:'hidden',
                            } }
                          >
                            <Typography variant="caption" fontWeight="bold" sx={ { textOverflow:'ellipsis', textWrap:'nowrap' } }>{node.data.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={ { p:2 } }>
                            <NodeComponent id={ node.id } data={ node.data } updateNodeData={ updateNodeData } container={ container } />
                          </AccordionDetails>
                        </Accordion>
                      ):(
                        <Box sx={ { p:1 } }>
                          <Typography variant="caption" fontWeight="bold">{node.data.name}</Typography>
                          <NodeComponent key={ node.id } id={ node.id } data={ node.data } updateNodeData={ updateNodeData } container={ container } />
                        </Box>
                      )}
                    </Box>
                  );
                },
                )}
              </Box>
            </Box>
      }
    </Box>
  );
}

export default PropertiesDrawer;