// nodeTypesMap.js
import { ModelBaseComponent, ResizeCore } from "../Nodes";
import ComfyCore from "../Nodes/ComfyCore";
import ExportCore from "../Nodes/ExportCore";
import NumberCore from "../Nodes/Helpers/NumberCore";
import ImportModelCore from "../Nodes/ImportModelCore";
import ImportModelCoreV2 from "../Nodes/ImportModelCoreV2";
import MaskExtractionCore from "../Nodes/MaskExtractionCore";


const nodeTypesMap = {
  integer: NumberCore,
  wildcardV2: ImportModelCoreV2,
  wildcard: ImportModelCore,
  resize: ResizeCore,
  custommodel:ImportModelCore,
  custommodelV2:ImportModelCoreV2,
  export: ExportCore,
  sd_outpaint: ModelBaseComponent,
  sd_inpaint: ModelBaseComponent,
  sd_sketch: ModelBaseComponent,
  sd_text2image: ModelBaseComponent,
  sd_upscale: ModelBaseComponent,
  sd_img2video: ModelBaseComponent,
  image2image: ModelBaseComponent,
  midjourney: ModelBaseComponent,
  br_text2image: ModelBaseComponent,
  br_vector: ModelBaseComponent,
  masks: MaskExtractionCore,
  comfy: ComfyCore,
  flux_pro: ModelBaseComponent,
  flux_fast: ModelBaseComponent,
  flux_lora: ModelBaseComponent,
  ig_text2image: ModelBaseComponent,
  sd_image23d: ModelBaseComponent,
  nim_cc: ModelBaseComponent,
  luma_video: ModelBaseComponent,
  rw_video: ModelBaseComponent,
  mochiv1: ModelBaseComponent,
  kling_pro: ModelBaseComponent,
  meshy_image23d: ModelBaseComponent,
  any_llm: ModelBaseComponent,
  prompt_enhance: ModelBaseComponent,
};

export default nodeTypesMap;
