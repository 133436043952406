import React from 'react';
import { Menu, MenuItem, Divider } from '@mui/material';

const NodeContextMenu = ({ mouseX, mouseY, menuItems, isOpen, onClose }) => {
  return (
    <Menu
      open={ isOpen }
      onClose={ onClose }
      anchorReference="anchorPosition"
      anchorPosition={
        isOpen ? { top: mouseY, left: mouseX } : undefined
      }
      className="dashboard-recipe-context-memu"
    >
      {menuItems.map((item, index) => {
        if (item === 'divider') {
          return <Divider key={ index } />; // Render a Divider for 'divider' items
        } else {
          return (
            <MenuItem key={ index } onClick={ item.onClick }>
              {item.label}
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
};

export default NodeContextMenu;
