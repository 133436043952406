import React, { useEffect, useRef, useState, useCallback } from 'react';
import { fabric } from 'fabric';
import { Popover, Box , Dialog, IconButton, Slider, FormControl, InputLabel,Select,MenuItem } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { SketchPicker } from 'react-color';
import PolylineIcon from '@mui/icons-material/Polyline';
import DrawIcon from '@mui/icons-material/Draw';
import { color as color_palette, colorMap }  from '../../colors' ;
import { useUserRole } from '../Recipe/UserRoleContext';
import { sizeOptions } from './ModelParams';
import { DynamicNode } from './VisualNodes';

const stopPropagation = (e) => e.stopPropagation();

const ToolTypes = {
  SELECT: 'select',
  COLOR_PICKER: 'colorPicker',
  BRUSH: 'brush',
  SHAPE: 'shape',
  POLYGON: 'polygon',
  // Add more tool types as needed
};

const getCursorSvg = (radius) => {
  return `
        <svg width="300" height="300" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="150" cy="150" rx="${radius}" ry="${radius}"
                fill="rgba(210,219,237,0.2)" stroke="#000" stroke-width="2" />
        </svg>
    `;
};

function PaintNode({ id, data, updateNodeData, onCanvasInteraction }) {

  // const updateNodeInternals = useUpdateNodeInternals();

  const role = useUserRole();

  const canvasRef = useRef(null);
  const menuRef = useRef(null);
  const nodeRef = useRef(null);
  const containerRef = useRef(null);
  const { handles } = data;

  const [canvas, setCanvas] = useState(null);
  const [canvasScaleFactor,setCanvasScaleFactor] = useState(null);
  const [color, setColor] = useState('#FFF');

  const [brushSize, setBrushSize] = useState(5);
  const [selectedSize, setSelectedSize] = useState('');
  const [hasImageInput, setHasImageInput] = useState(false);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [dynamicLine, setDynamicLine] = useState(null);
  const [polygonLines, setPolygonLines] = useState([]);

  /// MENU VARIABLES
  const [shapesAnchorEl, setShapesAnchorEl] = React.useState(null);
  const [isShapesMenuOpen, setIsShapesMenuOpen] = React.useState(false);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);

  const [activeTool, setActiveTool] = useState(ToolTypes.SELECT);

  useEffect(()=>{
    if(data.canvasSize){
      const index = sizeOptions.findIndex((option) => option.width === data.canvasSize.width && option.height === data.canvasSize.height);
      setSelectedSize(sizeOptions[index]);
    }
    else setSelectedSize(sizeOptions[0]);

  },[sizeOptions]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  /// INITIALISATION
  useEffect(() => {

    const maxWidth = 400;
    const maxHeight = 600;
    let scaleFactor;
    let canvasWidth;
    let canvasHeight;

    const fabricCanvas = new fabric.Canvas(canvasRef.current,{
      backgroundColor:'black',
    });
    if(!data.input["image"]){
      setHasImageInput(false);
      fabricCanvas.setBackgroundImage(null);
      // Becuase the UI should be smaller than the actual output the user wants we need to scale down the canvas (and scale back up on export)
      if(selectedSize.width > maxWidth || selectedSize.height > maxHeight){
        if(selectedSize.width >= selectedSize.height){
          scaleFactor = maxWidth/selectedSize.width;
        }
        else scaleFactor = maxHeight/selectedSize.height;
      }
      canvasWidth = selectedSize.width * scaleFactor;
      canvasHeight = selectedSize.height * scaleFactor;
      setCanvasScaleFactor(scaleFactor);
      fabricCanvas.setWidth(canvasWidth);
      fabricCanvas.setHeight(canvasHeight);
    }
    if (data.input["image"]) {
      setHasImageInput(true);
      fabric.Image.fromURL(data.input["image"].url, (img) => {
        let imageWidth = img.width;
        let imageHeight = img.height;


        // Check if image width exceeds maxWidth and adjust dimensions
        if (imageWidth > maxWidth) {
          scaleFactor = maxWidth / imageWidth;


          canvasWidth = imageWidth * scaleFactor;
          canvasHeight = imageHeight * scaleFactor;
        }
        setCanvasScaleFactor(scaleFactor);
        fabricCanvas.setHeight(canvasHeight);
        fabricCanvas.setWidth(canvasWidth);
        const index = sizeOptions.findIndex((option) => option.width === imageWidth && option.height === imageHeight);
        setSelectedSize(sizeOptions[index]);
        // setCanvasScaleFactor(scaleFactor);
        fabricCanvas.setBackgroundImage(img, fabricCanvas.renderAll.bind(fabricCanvas), {
          scaleX: fabricCanvas.width / img.width,
          scaleY: fabricCanvas.height / img.height,
        });
      },{ crossOrigin: 'Anonymous' });
    }

    // import all inputs
    // if(handles.input.length > 1 && data.input){
    //     Object.entries(input).forEach(([handleName, input]) => {
    //         if(input !== null){
    //             if(input.type === "image")
    //             fabric.Image.fromURL(input.url, function(img) {
    //                 img.set({
    //                     id : 'image_'+handleName,
    //                     width : fabricCanvas.width / 2,
    //                     height : fabricCanvas.height / 2
    //                 });
    //                 fabricCanvas.add(img);
    //             }, { crossOrigin: 'Anonymous' });
    //         }
    //     });
    // }
       
    // if(!canvas)
    setCanvas(fabricCanvas);


    fabricCanvas.upperCanvasEl.addEventListener('mousedown', stopPropagation);


    const savedCanvasState = data.canvas;
    if (savedCanvasState) {
      fabricCanvas.loadFromJSON(JSON.parse(savedCanvasState), fabricCanvas.renderAll.bind(fabricCanvas));
    }


    return () => {
      fabricCanvas.upperCanvasEl.removeEventListener('mousedown', stopPropagation);
      fabricCanvas.dispose();
    };
  }, [data.input["image"], selectedSize]);


  //// APPEARANCE AND STYLE

  const setCustomCursor = (radius) => {
    const svgString = getCursorSvg(radius);
    const base64EncodedSvg = btoa(svgString); // Base64 encode the SVG string
    const dataUrl = `data:image/svg+xml;base64,${base64EncodedSvg}`;

    if (canvas && canvas.upperCanvasEl) {
      canvas.freeDrawingCursor = `url('${dataUrl}') 150 150, crosshair`; // Use crosshair as fallback
    }
  };

  const resetCursor = () => {
    if (canvas && canvas.upperCanvasEl) {
      canvas.upperCanvasEl.style.cursor = 'default';
    }
  };

  const updateObjectSelectability = (isSelectable) => {
    if (canvas) {
      canvas.forEachObject((object) => {
        object.selectable = isSelectable;
        object.evented = isSelectable; // Disable event handling as well
      });
      canvas.renderAll();
    }
  };

  //// DRAWING POLYGONS
  const calculateDistance = (point1, point2) => {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;

    return Math.sqrt(dx * dx + dy * dy);
  };

  // Button toggle for drawing mode
  const toggleDrawingPolygon = () => {
    const newTool = activeTool !== ToolTypes.POLYGON ? ToolTypes.POLYGON : ToolTypes.SELECT;
    setActiveTool(newTool);
    updateObjectSelectability(newTool !== ToolTypes.POLYGON);
    setIsDrawingPolygon(newTool === ToolTypes.POLYGON);
    if(canvas)
      canvas.isDrawingMode = false;
    // if (isDrawingPolygon) {
    // finalizePolygon();
    // }
  };
  
  // Finalize polygon creation
  const finalizePolygon = () => {

    // Clear the dynamic line and the points
    if (dynamicLine) {
      canvas.remove(dynamicLine);
      setDynamicLine(null);
    }

    setPolygonPoints([]);
    polygonLines.forEach((line) => {
      canvas.remove(line);
    });
    setPolygonLines([]); // Clear all the lines that were part of the polygon
    toggleDrawingPolygon();
    // setIsDrawingPolygon(false);
  };
  
  // Mouse click handler for polygon drawing
  const handleCanvasClick = (options) => {
    if (!isDrawingPolygon) return;

    const pointer = canvas.getPointer(options.e);
    const newPoints = [...polygonPoints, { x: pointer.x, y: pointer.y }];
    setPolygonPoints(newPoints);

    // Only create the polygon once we have at least three points
    if (newPoints.length >= 3 && calculateDistance(newPoints[newPoints.length-1], newPoints[0]) < 10) {
      newPoints.pop();
      const polygon = new fabric.Polygon(newPoints, {
        fill: color,
        stroke: color,
        strokeWidth: 2,
        objectCaching:false,
      });

      setDynamicLine(null);
      finalizePolygon();
      setPolygonLines([]);

      // const vertices = [];
      // newPoints.forEach((point, index) => {
      //     const handle = new fabric.Circle({
      //         left: point.x,
      //         top: point.y,
      //         strokeWidth: 2,
      //         radius: 1,
      //         fill: 'yellow',
      //         stroke: 'yellow',
      //         hasBorders: false,
      //         hasControls: false,
      //         originX: 'center',
      //         originY: 'center',
      //         polygonIndex: index, // Custom property to identify which vertex this handle is linked to
      //     });
      //     vertices.push(handle);
      //     canvas.add(handle);
      //     // Add dragging functionality to handle
      //     handle.on('moving', function(event) {
      //         polygon.points[this.polygonIndex] = { x: this.left, y: this.top };
      //         canvas.renderAll();
      //     });


      // });

      canvas.add(polygon);
      // polygon.on('moving', () => {
      //     // Get the transform matrix for the polygon
      //     const transformMatrix = polygon.calcTransformMatrix();

      //     // Apply the transformation to each handle (vertex)
      //     vertices.forEach((handle, index) => {
      //       // Calculate the position of the handle based on the polygon's points and matrix
      //       const transformedPoint = fabric.util.transformPoint(
      //         new fabric.Point(polygon.points[index].x, polygon.points[index].y),
      //         transformMatrix
      //       );

      //       // Set the new position for the handle
      //       handle.set({ left: transformedPoint.x, top: transformedPoint.y });

      //       // Update the handle's position on the canvas
      //       handle.setCoords();
      //     });

      //     // Render the canvas to show changes
      //     canvas.requestRenderAll();
      //   });


    }
    else{
      if (dynamicLine) {
        const points = [dynamicLine.x1, dynamicLine.y1, dynamicLine.x2, dynamicLine.y2];
        const line = new fabric.Line(points, {
          stroke: color,
          strokeWidth: 2,
          selectable: false,
          evented: false,
        });
        canvas.add(line);
        setPolygonLines([...polygonLines, line]);
        canvas.remove(dynamicLine);
        setDynamicLine(null);
      }

      // Create a new dynamic line that will follow the mouse
      const dynamicLineTemp = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
        stroke: color,
        strokeWidth: 1,
        selectable: false,
        evented: false,
        strokeDashArray: [5, 5],
      });
      setDynamicLine(dynamicLineTemp);
      canvas.add(dynamicLineTemp);
    }
    canvas.renderAll();
  };

  // Mouse move handler for dynamic line drawing
  const handleMouseMove = (options) => {
    if (!isDrawingPolygon || !dynamicLine) return;

    const pointer = canvas.getPointer(options.e);
    dynamicLine.set({ x2: pointer.x, y2: pointer.y });
    canvas.renderAll();
  };

  /// END OF POLYGON

  // HANDLE MOUSE AND KEYBOARD EVENT INSIDE CANVAS
  const handleDeleteElement = (event) => {
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      return;
    }

    // Prevent event from bubbling up

    if (activeObject.type === 'activeSelection') {
      // For multiple selected objects
      activeObject.forEachObject((obj) => {
        canvas.remove(obj);
      });
      canvas.discardActiveObject(); // Clear the selection
    } else {
      // For a single selected object
      canvas.remove(activeObject);
    }

    canvas.requestRenderAll();
    event.stopPropagation(); // Re-render the canvas
  };

  /// MENU ACTIONS AND FUNCTIONS

  const handleSelectToolClick = () => {
    setActiveTool(ToolTypes.SELECT);
    if(canvas){
      canvas.isDrawingMode = false;
      setIsDrawingPolygon(false);
      updateObjectSelectability(true);
    }
  };
  const handleOpenShapesMenu = (event)=>{
    setActiveTool(ToolTypes.SHAPE);
    setShapesAnchorEl(event.currentTarget);
    setIsShapesMenuOpen(true);
    if(canvas){
      canvas.isDrawingMode = false;
      setIsDrawingPolygon(false);
    }
  };

  const handleCloseShapesMenu = ()=>{
    setShapesAnchorEl(null);
    setIsShapesMenuOpen(false);
  };
  const handleColorChange = (changedColor) => {
    setColor(changedColor.hex);
    if (canvas) {
      canvas.freeDrawingBrush.color = changedColor.hex;
    }
  };

  const handleBrushSizeChange = (event, newValue) => {
    const newBrushSize = newValue < 1 ? 1: newValue;
    setBrushSize(newBrushSize);
    if (canvas && canvas.isDrawingMode) {
      canvas.freeDrawingBrush.width = newBrushSize;
    }
  };

  const toggleFreeDrawing = () => {
    const newTool = activeTool !== ToolTypes.BRUSH ? ToolTypes.BRUSH : ToolTypes.SELECT;
    setActiveTool(newTool);
    updateObjectSelectability(newTool !== ToolTypes.BRUSH);
    if (canvas) {
      const isDrawingMode = newTool === ToolTypes.BRUSH;
      canvas.isDrawingMode = isDrawingMode;

      if (isDrawingMode) {
        canvas.freeDrawingBrush.width = brushSize;
        canvas.freeDrawingBrush.color = color;
        setCustomCursor(50);
      }
      else {
        resetCursor();
      }
    }
  };

  //// ADDING GRAPHICS TO
  const addShape = (shape, isFilled) => {
    if (canvas) {
      let newShape;

      switch (shape) {
        case 'circle':
          newShape = new fabric.Circle({
            radius: 50,
            fill: isFilled? color:'',
            stroke: color,
            strokeWidth:2,
            left: 100,
            top: 100,
          });
          break;
        case 'rectangle':
          newShape = new fabric.Rect({
            width: 100,
            height: 100,
            fill: isFilled? color:'',
            stroke: color,
            strokeWidth:2,
            left: 100,
            top: 100,
          });
          break;
        case 'triangle':
          newShape = new fabric.Triangle({
            width: 100,
            height: 100,
            fill: isFilled? color:'',
            stroke: color,
            strokeWidth:2,
            left: 100,
            top: 100,
          });
          break;
        case 'line':
          newShape = new fabric.Line([50, 100, 200, 200], {
            stroke: color, // Use the color state
          });
          break;
        default:
          return;
      }

      canvas.add(newShape);
    }
  };

  // Attach and detach event listeners
  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:down', handleCanvasClick);
      canvas.on('mouse:move', handleMouseMove);
    }
    
    return () => {
      if (canvas) {
        canvas.off('mouse:down', handleCanvasClick);
        canvas.off('mouse:move', handleMouseMove);
      }
    };
  }, [canvas, isDrawingPolygon, polygonPoints]);


  useEffect(() => {
    const innerHandleCanvasClick = () => {
      if (onCanvasInteraction) {
        // Directly check if the container is or contains the active element
        const containerElement = containerRef.current;
        if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
          onCanvasInteraction();
        }
      }
    };

    const handleKeyDown = (event) => {
      // Similar check as above for keydown events
      const containerElement = containerRef.current;
      if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
        // console.log("Key pressed:", event.key); // For debugging
        if (event.key === 'Delete' || event.key === 'Backspace') {
          handleDeleteElement(event);
        }
        if(canvas){
          if (event.key === '[') {
            handleBrushSizeChange(event, canvas.freeDrawingBrush.width-5);
          }
          if (event.key === ']') {
            handleBrushSizeChange(event, canvas.freeDrawingBrush.width+5);
          }
          if (event.key === 'Escape' || event.key === 'v'  || event.key === 'V') {
            handleSelectToolClick();
          }
          if (event.key === 'b' || event.key === 'B') {
            toggleFreeDrawing();
          }
        }
      }
    };

    const upperCanvas = canvas ? canvas.upperCanvasEl : null;
    if (upperCanvas) {
      upperCanvas.addEventListener('click', innerHandleCanvasClick);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (upperCanvas) {
        upperCanvas.removeEventListener('click', innerHandleCanvasClick);
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [canvas, onCanvasInteraction]);

  /// CHCEK FOR CHANGES AND EXPORT
  /// EXPORT CANVAS
  const handleExport = useCallback(() => {
    // console.log("Running canvas export");
    // console.log("Current selected size is" ,selectedSize);
    // console.log("Current canvas scale factor is: ", canvasScaleFactor);
    // console.log("Current canvas size: ", canvas.width,canvas.height );

    const multiplier = 1/canvasScaleFactor;

    if (canvas) {
      let originalBackground = null;

      // If exporting without background, temporarily remove it
      if (canvas.backgroundImage) {
        originalBackground = canvas.backgroundImage;
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
      }

      // Export the canvas as a data URL
      const outputDataUrlWithoutImage = canvas.toDataURL({
        format: 'png',
        multiplier: multiplier,
        // enableRetinaScaling: true
      });

      // Restore the original background if it was removed
      if (originalBackground) {
        canvas.setBackgroundImage(originalBackground, canvas.renderAll.bind(canvas));
      }
      const outputDataUrlWithImage = canvas.toDataURL({
        format: 'png',
        multiplier: multiplier,
        // enableRetinaScaling: true
      });

      const formattedOutput = {};
      const output = [{ type:'image', url:outputDataUrlWithImage },{ type:'image',url:outputDataUrlWithoutImage }];
      handles.output.forEach((elementName, index) => {
        formattedOutput[elementName] = output[index];
      });


      const canvasJson = JSON.stringify(canvas.toJSON());
      updateNodeData(id,  { canvas:canvasJson, canvasSize:selectedSize,  output:formattedOutput } );
    }
  }, [canvas]);

  const debounce = (func, wait, immediate) => {
    let timeout;

    return function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  useEffect(() => {
    if (canvas) {
      // Define a debounced export function
      const debouncedExport = debounce(() => handleExport(), 500);

      // Event handler that triggers the export
      const onChange = () => {
        debouncedExport();
      };

      // Attach event listeners
      canvas.on('object:modified', onChange);
      canvas.on('object:added', onChange);
      canvas.on('object:removed', onChange);
      // Add more event listeners as needed

      return () => {
        // Clean up event listeners
        canvas.off('object:modified', onChange);
        canvas.off('object:added', onChange);
        canvas.off('object:removed', onChange);
        // Clean up other event listeners
      };
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      handleExport();
    }
  }, [canvas,selectedSize , data.input["image"]]);

  // useEffect(()=>{
  //     console.log("Canvas Scale Factor: ", canvasScaleFactor);
  // },[canvasScaleFactor]);



  return (
    <>
      {/* <NodeResizer  onResize={handleResize} /> */}
      <Box ref={ nodeRef }
        sx={ {
          pointerEvents: role === "guest" ? 'none' : '',
          cursor: role === "guest" ? 'default' : '',
        } }
      >
        <DynamicNode id={ id } data={ data } className="painter" handleColor={ colorMap.get(data.color) }>
          {!hasImageInput &&
                <Box>
                  <FormControl fullWidth className="nowheel nodrag nopan" sx={ { my:1 } }>
                    <InputLabel id="canvas-size-select-label">Size</InputLabel>
                    <Select
                      labelId="canvas-size-select-label"
                      id="demo-simple-select"
                      value={ selectedSize }
                      label="Size"
                      onChange={ handleSizeChange }
                      size="small"
                    >
                      {sizeOptions.map((option) => (
                        <MenuItem key={ option.label } value={ option }>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
          }
          <Box sx={ { display:'flex', flexDirection:'row' } } ref={ containerRef } tabIndex="0" className="nowheel nodrag nopan" id="canvas-and-tools-container">

            <Box ref={ menuRef }  sx={ { display: 'flex', justifyContent: 'center', mr:'10px' } }>
              <Box
                id='paint-menu'
                sx={ { px:1, py:4,height:'300px', justifyContent:'space-between',display:'flex', flexDirection:'column', margin:'auto', borderRadius:6, backgroundColor:color_palette.Dark_BG } }
              >
                <IconButton onClick={ handleSelectToolClick } className={ activeTool === ToolTypes.SELECT ?  'active' : '' }>
                  <HighlightAltOutlinedIcon fontSize="small" />
                </IconButton>

                <Dialog open={ isPickerOpen } onClose={ () => setPickerOpen(false) }>
                  <SketchPicker color={ color } onChangeComplete={ handleColorChange } />
                </Dialog>
                <IconButton
                  onClick={ toggleFreeDrawing }
                  className={ activeTool === ToolTypes.BRUSH ? 'active' : '' }
                >
                  <DrawIcon fontSize="small" />
                </IconButton>

                <Popover
                  id='shapes-menu-popover'
                  open={ isShapesMenuOpen }
                  anchorEl={ shapesAnchorEl }
                  onClose={ handleCloseShapesMenu }
                  anchorOrigin={ {
                    vertical: 'center',
                    horizontal: 'right',
                  } }
                  transformOrigin={ {
                    vertical: 'center',
                    horizontal: 'left',
                  } }
                >
                  <Box sx={ { p:1 } }>
                    <IconButton onClick={ () => {addShape('circle', true); handleCloseShapesMenu();} }><CircleOutlinedIcon /></IconButton>
                    <IconButton onClick={ () => {addShape('rectangle', true); handleCloseShapesMenu();} }><RectangleOutlinedIcon /></IconButton>
                    <IconButton onClick={ () => {addShape('triangle', true); handleCloseShapesMenu();} }><ChangeHistoryIcon /></IconButton>
                  </Box>
                  {/* <Box sx={{pb:1, px:1}}>
                                        <IconButton onClick={() => {addShape('circle'); handleCloseShapesMenu()}}><CircleOutlinedIcon /></IconButton>
                                        <IconButton onClick={() => {addShape('rectangle'); handleCloseShapesMenu()}}><RectangleOutlinedIcon /></IconButton>
                                        <IconButton onClick={() => {addShape('triangle'); handleCloseShapesMenu()}}><ChangeHistoryTwoToneIcon/></IconButton>
                                    </Box> */}

                </Popover>
                <IconButton onClick={ handleOpenShapesMenu }><CategoryIcon fontSize="small" /></IconButton>
                <IconButton onClick={ toggleDrawingPolygon } className={ activeTool === ToolTypes.POLYGON ?  'active' : '' }><PolylineIcon fontSize="small"   /></IconButton>
                <IconButton onClick={ () => setPickerOpen(true) } style={ { color } }>
                  <ColorLensIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={ { position:'relative',display:'flex', justifyContent:'center' } }>
              <canvas ref={ canvasRef }  tabIndex="0" />
              {activeTool === ToolTypes.BRUSH &&
                        <Box className="brush-size-slider-container" sx={ { py:.5, px:2, borderRadius:8 } }>
                          <Slider
                            size='small'
                            value={ brushSize }
                            onChange={ handleBrushSizeChange }
                            aria-labelledby="brush-slider"
                            min={ 1 }
                            max={ 100 }
                            valueLabelDisplay="auto"
                            sx={ { width:100 } }
                          />
                        </Box>}
            </Box>
            {/* <Layers addLayer={handleAddLayer}/> */}
            {/* EXPAND FULLSCREEN MODE */}
            {/* <Box id="resize-painter-container" sx={{position:'absolute', top:0, right:0}}>
                            <IconButton onClick={handleClickFullScreen}>
                                <FullscreenIcon/>
                            </IconButton>
                   </Box> */}
          </Box>
        </DynamicNode>
      </Box>
    </>
  );
}

export default PaintNode;

