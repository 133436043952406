import React, { useCallback, useEffect, useRef, useState } from 'react';
import { addEdge, ReactFlowProvider } from 'reactflow';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Slide, Snackbar } from '@mui/material';
import { useTranslation } from "react-i18next";
import Flow from '../components/Recipe/Flow.jsx';
import { UserRoleProvider } from '../components/Recipe/UserRoleContext';
import axiosInstance from '../services/axiosConfig.js';
import { sanitizeNodes } from '../components/Nodes/Utils.jsx';
import { MediaGalleryProvider } from '../components/Recipe/FlowComponents/MediaGalleryContext.jsx';
import { color } from '../colors.js';
import I18N_KEYS from "../language/keys";

function SlideTransition(props) {
  return <Slide { ...props } direction="down" />;
}

function Loader () {
  return (
    <Box
      sx={
        { zIndex:100, background:'black', display:'flex', width:'100%', height:'100%', position:'absolute', top:0, left:0, alignItems:'center', justifyContent:'center' }
      }
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}

function Recipe ({ user }) {

  const { t: translate } = useTranslation();
  const compv2rollout = useFeatureFlagEnabled('compv2');

  const params = useParams();
  const recipeId = params.recipeId;
  const navigate = useNavigate();
  const flowRef = useRef(null);

  // const [allSaved, setAllSaved] = useState(true);
  const [recipeData, setRecipeData] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [nodeTypes, setNodeTypes] = useState([]);
  // const [lastUpdatedNodeId, setLastUpdatedNodeId] = useState(null);
  const [updateQueue, setUpdateQueue] = useState([]);
  // const [changedNodesSinceLastSave, setChangedNodesSinceLastSave] = useState(new Set());
  const [isLoadingRecipe, setIsLoadingRecipe] = useState(true);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState(false);

  const [isDuplicatingRecipe, setIsDuplicatingRecipe] = useState(false);

  /// permissions
  const [userRole, setUserRole] = useState(null);

  /// collaboration
  // const [isLocalChange, setIsLocalChange] = useState(false);

  const getRecipe = async (recipeIdToFetch) => {
    try {
      const res = await axiosInstance.get(`/v1/recipes/${recipeIdToFetch}`);
      setNodes(res.data.nodes);
      setEdges(res.data.edges);
      setIsLoadingRecipe(false);

      /// setting permissions
      setUserRole("guest"); /// DEFAULT AS GUEST

      if(user && res.data.owner === user.uid){
        setUserRole("editor");
      }

      setRecipeData({
        name: res.data.name,
        lastSaved: res.data.lastSaved,
        userPoster: res.data?.user_poster,
        poster: res.data?.poster,
        type: res.data?.type,
      });
    } catch(error) {
      console.log("Error getting flows", error);
      setIsLoadingRecipe(false);
      if(error.response && error.response.status === 403)
        navigate('/');
    }
  };

  const getNodesTypes = async () => {
    try {
      const res = await axiosInstance.get(`/v1/nodes/types`);
      setNodeTypes(res.data);
    } catch (error) {
      console.log("Error getting node types", error);
    }
  };
  
  useEffect(()=>{
    if(recipeId){
      getRecipe(recipeId);

    }
  },[recipeId]);

  useEffect(()=>{
    getNodesTypes();
  },[]);

  const updateNodeData = useCallback((nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        
        return node;
      }),
    );
    // setChangedNodesSinceLastSave((prevSet) => new Set(prevSet).add(nodeId));
    // setLastUpdatedNodeId(nodeId); // Track the last updated node ID
    setUpdateQueue((prevQueue) => [...prevQueue, nodeId]);
  },[setNodes, setUpdateQueue]);

  //// debugging
  // useEffect(()=>{
  //   console.log("List of changed nodes", changedNodesSinceLastSave);
  // },[changedNodesSinceLastSave])  

  // REAL TIME COLLABORATION
  // useEffect(() => {
  //   const docRef = doc(db, 'recipes', recipeId);
  //   const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
  //     if (docSnapshot.exists()) {
  //       const data = docSnapshot.data();
  //       setNodes(data.nodes);
  //       setEdges(data.edges);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   }, err => {
  //     console.log(`Encountered error: ${err}`);
  //   });

  //   return () => unsubscribe();
  // }, [setNodes, setEdges]);

  // useEffect(() => {
  //   const updateDiagram = async (newState) => {
  //     const docRef = doc(db, 'recipes', recipeId);
  //     try {
  //       await setDoc(docRef, newState, { merge: true }); // merge: true to update only provided fields
  //     } catch (error) {
  //       console.error("Error updating document: ", error);
  //     }
  //   };

  //   updateDiagram({ nodes, edges });
  // }, [nodes, edges]);

  const passDataOnConnect = (nodesData, sourceId, sourceHandle, targetId, targetHandle) => {
    const sourceNode = nodesData.find((node) => node.id === sourceId);

    if (!sourceNode) return;

    let dataToPass;
    if (sourceHandle && typeof sourceNode.data.output === 'object') {
      const outputKey = sourceHandle.split('-').pop().replace('output', ''); // 'map' or 'result'
      dataToPass = sourceNode.data.output[outputKey];
    } else {
      // Default to the entire data.output if no specific handle or output is not an object
      dataToPass = sourceNode.data.output;
    }
    // Update the target node with the new data based on the target handle
    setNodes((nds) => nds.map((node) => {
      if (node.id === targetId) {
        let match = targetHandle.match(/input-(.*)$/);
        let inputKey;
        if(match)
          inputKey = targetHandle.match(/input-(.*)$/)[1];
        else // backward compatibility for exisitng preview nodes to handle 13.5.24 changes
          inputKey = targetHandle.split('-').pop().replace('input', '');

        let updatedInput = { ...node.data.input };
        updatedInput[inputKey] = dataToPass;

        const updatedNodeData = { ...node.data, input: updatedInput };

        return { ...node, data: updatedNodeData };
      }

      return node;
    }));
  };

  const onConnect = useCallback((onConnectParams) => {
    const targetAlreadyConnected = edges.some((edge) => edge.target === onConnectParams.target && edge.targetHandle === onConnectParams.targetHandle);
    if (targetAlreadyConnected) {
      // console.log('This target handle is already connected.');
      return; // Prevent adding a new edge
    }

    const sourceNode = nodes.find((n) => n.id === onConnectParams.source);
    const targetNode = nodes.find((n) => n.id === onConnectParams.target);

    setEdges((eds) => addEdge({
      ...onConnectParams,
      type:'custom',
      data:
       {
         sourceColor: sourceNode.data?.color,
         targetColor: targetNode.data?.color,
       },
    }, eds));
    // console.log("onConnect is triggered");
    passDataOnConnect(nodes, onConnectParams.source, onConnectParams.sourceHandle, onConnectParams.target,  onConnectParams.targetHandle);
  }, [setEdges, nodes, setNodes]);


  const updateTargetsOnSourceChange = (sourceNodeId, updatedNode) => {
    const relevantEdges = edges.filter((edge) => edge.source === sourceNodeId);

    // Create a new array with updated nodes
    const updatedNodes = nodes.map((node) => {
      const isTargetNode = relevantEdges.some((edge) => edge.target === node.id);

      if (isTargetNode) {
      // Clone the input object to avoid mutating the state directly
        let updatedInput = { ...node.data.input };

        // Update the target node's input for each relevant edge
        relevantEdges.forEach((edge) => {
          if (edge.target === node.id) {
          // Get the output key from the source handle and input key from the target handle
            const outputKey = edge.sourceHandle.split('-').pop().replace('output', '');
            let match = edge.targetHandle.match(/input-(.*)$/);
            let inputKey;
            if (match)
              inputKey = match[1];
            else // backward compatibility for existing preview nodes to handle 13.5.24 changes
              inputKey = edge.targetHandle.split('-').pop().replace('input', '');

            // Get the appropriate output data based on the source handle
            if(updatedNode.data.output){
              // Fallback to the default output if the key doesn't exist
              // Assign the output data to the corresponding input key
              updatedInput[inputKey] = outputKey in updatedNode.data.output ? updatedNode.data.output[outputKey] : updatedNode.data.output;
            }
          }
        });

        const updatedNodeData = { ...node.data, input: updatedInput };

        return { ...node, data: updatedNodeData };
      }

      return node;
    });

    setNodes(updatedNodes);
  };

  const updateTargetsOnSourceChangeV2 = useCallback((sourceNodeId, updatedNode) => {
    const relevantEdges = edges.filter((edge) => edge.source === sourceNodeId);

    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        const isTargetNode = relevantEdges.some((edge) => edge.target === node.id);
        if (isTargetNode) {
          let updatedInput = { ...node.data.input };
          relevantEdges.forEach((edge) => {
            const outputKey = edge.sourceHandle.split('-').pop().replace('output', '');
            const inputKey = edge.targetHandle.match(/input-(.*)$/)?.[1] || edge.targetHandle.split('-').pop().replace('input', '');
            if (updatedNode.data.output) {
              updatedInput[inputKey] = outputKey in updatedNode.data.output ? updatedNode.data.output[outputKey] : updatedNode.data.output;
            }
          });
          const updatedNodeData = { ...node.data, input: updatedInput };

          return { ...node, data: updatedNodeData };
        }

        return node;
      });
    });
  }, [edges]);

  //  update all connected nodes when a node is changed.
  useEffect(() => {
    if (updateQueue.length > 0) {
      const nodeIdToUpdate = updateQueue[0];
      const updatedNode = nodes.find((node) => node.id === nodeIdToUpdate);
      if (updatedNode) {
        if(compv2rollout)
          updateTargetsOnSourceChangeV2(nodeIdToUpdate, updatedNode);
        else
          updateTargetsOnSourceChange(nodeIdToUpdate, updatedNode);
      };
    }
    // Reset the last updated node ID
    // setLastUpdatedNodeId(null);
    setUpdateQueue((prevQueue) => prevQueue.slice(1));
  }, [nodes]);

  // useEffect(()=>{
  //   console.log(updateQueue);
  // },[updateQueue])

  const handleDuplicateRecipe = async () => {
    setIsDuplicatingRecipe(true);
    const sanitizedNodes = sanitizeNodes(nodes);
    const recipeToDuplicate = {
      nodes: sanitizedNodes,
      edges: edges,
      name: recipeData.name,
      userPoster: recipeData.userPoster,
      poster: recipeData.poster,
    };
    try{
      const response = await axiosInstance.post(`/v1/recipes/${recipeId}/duplicate`, recipeToDuplicate);
      window.open(`https://weavy.ai/recipe/${response.data.id}`, '_blank');
      setIsDuplicatingRecipe(false);
    } catch(error){
      console.log("error duplicating recipe: ", error);
      setIsDuplicatingRecipe(false);
    }
  };

  //// ERROR SNACK BAR
  const handleErrorSnackBarClose = ()=> {
    setIsErrorSnackBarOpen(false);
  };

  return (
    <>
      <MediaGalleryProvider>
        <Box sx={ { display:'flex', flexDirection:'row', width:'100%', height:'100%' } }>
          <Box sx={ { width:'100%',height:'100%' } } id='canvas'>
            <Box sx={ { width:'calc(100%)', height:'100%', position:'relative' } } ref={ flowRef } >
              <ReactFlowProvider>
                <UserRoleProvider value={ userRole }>
                  {nodeTypes &&
                    <Flow
                      nodes={ nodes }
                      setNodes={ setNodes }
                      edges={ edges }
                      setEdges={ setEdges }
                      selectedNodes={ selectedNodes }
                      setSelectedNodes={ setSelectedNodes }
                      onConnect={ onConnect }
                      updateNodeData={ updateNodeData }
                      nodeTypes={ nodeTypes }
                      setNodeTypes={ setNodeTypes }
                      recipeId={ recipeId }
                      handleSaveError={ setIsErrorSnackBarOpen }
                      recipeData={ recipeData }
                      setRecipeData={ setRecipeData }
                      user={ user }
                      role={ userRole }
                      passData={ passDataOnConnect }
                      isLoadingRecipe={ isLoadingRecipe }
                      setIsLoadingRecipe={ setIsLoadingRecipe }
                      duplicateRecipe={ handleDuplicateRecipe }
                    />
                  }
                </UserRoleProvider>
              </ReactFlowProvider>
            </Box>
           
          </Box>
          {/* <Box sx={{width:'280px'}}>
              <PropertiesDrawer selectedNodes={selectedNodes} updateNodeData={updateNodeData}/>
        </Box> */}
        </Box>
        {isLoadingRecipe && <Loader />}
        <Snackbar
          open={ isErrorSnackBarOpen }
          autoHideDuration={ 5000 }
          onClose={ handleErrorSnackBarClose }
          anchorOrigin={ { vertical:'top',horizontal:'center' } }
          TransitionComponent={ SlideTransition }
        >
          <Alert
            onClose={ handleErrorSnackBarClose }
            severity="error"
            variant="filled"
            sx={ { width: '100%', color:'white' } }
          >
            {translate(I18N_KEYS.RECIPE_MAIN.ERROR_SAVE)}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
          open={ isDuplicatingRecipe }
          onClose={ () => setIsDuplicatingRecipe(false) }
          autoHideDuration={ 1500 }
          TransitionComponent={ SlideTransition }
        >
          <Alert
            variant="filled"
            sx={ { width: '100%', color: color.Yambo_Text_On_Dark, backgroundColor: color.Yambo_Light_Green } }
          >
              Duplicating File
          </Alert>
        </Snackbar>
      </MediaGalleryProvider>
    </>

  );
}

export default Recipe;
