import { Box, Input, Slider, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)({
  marginBottom: '16px',
});

export const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Title = styled(Typography)({
  marginRight: '8px',
});

export const StyledTooltip = styled(Tooltip)({
  fontSize: '10px',
});

export const SliderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export const StyledSlider = styled(Slider)({
  marginRight: '16px',
  width: '80%',
});

export const StyledInput = styled(Input)({
  fontSize: '10px',
});

export const FullWidthBox = styled(Box)({
  width: '100%',
});
