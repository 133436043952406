import React from "react";
import { Box } from "@mui/material";
import { Handle, Position } from 'reactflow';

function MenuNode ({ id }) {
  return(
    <Box id="temp-node" sx={ { width:'100px', height:'100px', backgroundColor:"white" } }>
      <Handle className='input-handle' style={ { top:0 } } type="target" position={ Position.Left } id={ `${id}-input-0` } />
    </Box>
  );
}

export default MenuNode;
