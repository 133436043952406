import React, { useRef, useState, useEffect, Suspense } from 'react';
import { Box } from '@mui/material';
import { Canvas, useThree } from "@react-three/fiber";
import { Environment, OrbitControls, Html, useProgress, ContactShadows } from "@react-three/drei";
import { Model } from './ThreedeeUtils.js';


const VIEWER_SIZE = { w: 304, h: 304 }; // the reason for 304 is 320 (css custom-node.preview width) minus the 2x*8px padding 


function ModelLoader() {
  const { progress } = useProgress();
  
  return <Html center>{progress} % loaded</Html>;
}

function SetCameraPosition() {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.x = -3;
    camera.position.y = 1.5;
    camera.position.z = 4;
        
    camera.updateProjectionMatrix();
  }, [camera]);

  return null;
}


function ThreeDeeViewer( { objUrl, containerSize } ) {
    
  const [objectUrl, setObjectUrl] = useState(null);
  const containerRef = useRef(null);
  const canvasRef = useRef();
  const viewerSize = containerSize || VIEWER_SIZE;

  useEffect(() => {
    if (objUrl) {
      fetch(objUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setObjectUrl(url);
        })
        .catch((error) => console.error("Error fetching OBJ file:", error));
    }
    
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objUrl]);

  useEffect(() => {
    if (containerRef.current) {
      const canvasElement = containerRef.current;
            
      // Focus canvas when mouse enters or interaction occurs
      const handleMouseEnter = () => {
        canvasElement.focus();
      };
            
      const handleWheel = (event) => {
        const containerElement = containerRef.current;
        if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
          event.stopPropagation();  // Prevent scrolling propagation
        }
      };
      const handleMouseClick = (event) => {
        const containerElement = containerRef.current;
        if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
          event.stopPropagation();  // Prevent scrolling propagation
        }
      };

      canvasElement.addEventListener('mouseenter', handleMouseEnter);
      canvasElement.addEventListener('click', handleMouseClick);
      canvasElement.addEventListener('wheel', handleWheel);

      return () => {
        canvasElement.removeEventListener('mouseenter', handleMouseEnter);
        canvasElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, [containerRef]);


  const handleCanvasCreated = () => {
    setCanvasSize();
  };

  const setCanvasSize = () => {
    if(canvasRef && canvasRef.current) {
      canvasRef.current.style.width = `${viewerSize.w}px`;
      canvasRef.current.style.height = `${viewerSize.h}px`;
    }
  };

  useEffect(() => {
    setCanvasSize();
  },[objUrl]);
    

  return (
    <Box
      ref={ containerRef }
      tabIndex={ 0 }
      className="3d_player"
      id="3d-player-wrapper"
      sx={ {
        width:`${viewerSize.w}px`,
        height:`${viewerSize.h}px` ,
      } }
    >
      {objectUrl && ( <Canvas
        key={ objectUrl }
        shadows
        onCreated={ ({ gl }) => {
          gl.setClearColor("#e0e0e0");
          handleCanvasCreated();
        } }
        gl={ { preserveDrawingBuffer: true } }
        ref={ canvasRef }
                      >
        <SetCameraPosition />
        <OrbitControls enableRotate target={ [0, 1.5, 0] } />
        <directionalLight
          intensity={ 1 }
          position={ [-5,10,-5] }
          castShadow
          shadow-mapSize-height={ 512 }
          shadow-mapSize-width={ 512 }
        />
        <ambientLight color={ '#dce7f5' } intensity={ .5 } />
        <Suspense fallback={ <ModelLoader /> }>
          <Model castShadow objUrl={ objectUrl } type={ 4 } />
          <ContactShadows rotation-x={ Math.PI / 2 } position={ [0, 0, 0] } opacity={ 1 } width={ 5 } height={ 5 } blur={ 1 } far={ 1 } />
        </Suspense>
        <Environment preset='city' blur={ 0.5 } />
      </Canvas>
      ) }
    </Box>
  );
}

export default ThreeDeeViewer;