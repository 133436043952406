import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { Box,Typography, TextField, ButtonBase, InputAdornment, CircularProgress } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import LoadingButton from '@mui/lab/LoadingButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useUpdateNodeInternals } from 'reactflow';
import lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useUserRole } from "../Recipe/UserRoleContext";
import ModelRunContext from '../Recipe/RunFlow/ModelRunContext';
import { color } from '../../colors';
import axiosInstance from "../../services/axiosConfig";
import { CreditsContext } from "../../services/CreditsContext";
import { ModelType } from "../../enums/model-type.enum";
import { useMediaGalleryContext } from "../Recipe/FlowComponents/MediaGalleryContext";
import { runModel } from "./RunModel";
import { extractInputSchemaDetails , renderDynamicField, cleanParamsForSaving, rgbaToRgb } from "./Utils";
import { SaveCustomNode } from "./SaveCustomNode";
import { civitSchema } from './Civit/CivitSchema';
import I18N_KEYS from "../../language/keys";
import { useTranslation } from "react-i18next";
import NodeImageList from "../Recipe/FlowComponents/NodeImageList";

const civitModelTypeError = (modelType) => {
  return `The model type ${modelType.toUpperCase()} is not yet supported. We currently support only Civit models (checkpoints)`;

};

let isRunning = new Set();

function ImportModelCoreV2 ({ id, data ,updateNodeData, setNodesTypes, editable, selectedOutput, setSelectedOutput, setOutput, container, setValidationError}) {

  const posthog = usePostHog();
  const saveParamsHistoryFlag = useFeatureFlagEnabled('save_params_history');
  const { t: translate } = useTranslation();

  const { credits, setUserCredits } = useContext(CreditsContext);

  const { modelRunTrigger, updateModelRunTriggerStatus } = useContext(ModelRunContext);

  const role = useUserRole();
  const updateNodeInternals = useUpdateNodeInternals();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [coverImage, setCoverImage] = useState(data?.model?.cover || '');
  const [modelName, setModelName] = useState(data.model.name);
  const [modelVersion, setModelVersion] = useState(data.model.version);
  const [cannotFindModel, setCannotFindModel] = useState(false);
  const [civitModelNotSupported, setCivitModelNotSupported] = useState(false);
  const [civitModelNotSupportedMessage, setCivitModelNotSupportedMessage] = useState();
  const service = useRef(null);

  const [predictionId, setPredictionId] = useState(null);
  const [predictionReady, setPredictionReady] = useState(false);
  const [pollingError, setPollingError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [predictionStatus, setPredictionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const predictionCanceled = useRef(null);
  const [sortedSchema, setSortedSchema] = useState([]);

  /// media gallery
  const { showGallery, setShowGallery, setMediaArray, setSelectedFile } = useMediaGalleryContext();
  const [showGalleryIcon, setShowGalleryIcon] = useState(false);

  // civit loader
  const [civitLoading, setCivitLoading] = useState(false);

  // seed management
  const prevSeed = useRef();


  /// storing params and input for latest run model
  const lastRunParamsAndInputs = useRef({params: null, input: null});

  const { handles, description, model, input, params, schema, version } = data;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  const prevInput = usePrevious(data.input);
  // const runInitiatedRef = useRef(new Set());

  const hasNewResults = useRef(null);

  // set the right service (civit or replicate)
  useEffect(()=>{
    if(data.model.service){
      service.current = data.model.service;
    }
  },[data.model.service]);

  const updateOutputWithNewResults = useCallback(()=>{
    // console.log("updating selected with last index ");
    if(hasNewResults.current){
      setSelectedOutput(data.result? data.result.length -1  : 0);
      setOutput(data.result.length -1);
    }
    hasNewResults.current = null;
        
  },[data.result, hasNewResults]);

  useEffect(() => {
    // console.log("data.result changed");
    if (hasNewResults.current) {
      updateOutputWithNewResults();
    }
  }, [data.result, updateOutputWithNewResults]);

  /// END -> HANDLING STACKING THE RESULTS OF THE MODEL (FOR MULTIPLE RUNS)

  const exposeDefaultHandles = useCallback((defaultHandles) => {
    const mergedInput = {...handles.input,...defaultHandles};
  
    updateNodeData(id, {
      handles: {
        ...handles,
        input: mergedInput,
      },
    });
  },[handles.input]);

  //// PARSING MODEL SCHEMA AND SETTING THE DEFAULTS OR EXISITNG DATA
  const populateSchema = useCallback((schemaToPopulate) => {
    const isParamsEmpty = !params || Object.keys(params).length === 0;
    const defaultHandles = [];
    // console.log(isParamsEmpty);
    if(isParamsEmpty){
      const initialFields = {};
      Object.keys(schemaToPopulate).forEach((key) => {
        const prop = schemaToPopulate[key];
        if (prop.default !== undefined) {
          initialFields[key] = prop.default;
        } else {
          initialFields[key] = ""; // Default for strings
        }
        if(prop.type === 'enum' && !prop.default) // handles options (enum) when no default is provided
          initialFields[key] = prop.options[0];
        // now, exposing handles for "textfield" props.
        if(prop.type === "string")
          defaultHandles[key] = {
              required: prop.required,
              description: prop.description,
              format: prop.format || "text",
              order: prop.order,
              id: uuidv4(),
          };
        if(prop.type === 'seed'){
          initialFields[key] = {
            isRandom: true,
            seed:lodash.random(1, 1000000),
          };
        }
      });
      updateNodeData(id,{
        params: initialFields,
      });
      exposeDefaultHandles(defaultHandles);
    }
    // else setDynamicFields(params);
  },[params, updateNodeData]);

  const parseModelSchema = (rawData) => {
    const newSchema = extractInputSchemaDetails(rawData);
    updateNodeData(id, {
      schema:newSchema,
      version: 2,
    });
    // setModelPropertiesSchema(schema);
    populateSchema(newSchema);
  };

  // soring the schema
  useEffect(()=>{
    if(schema){
      const sorted = Object.keys(schema).sort((a, b) => schema[a].order - schema[b].order);
      setSortedSchema(sorted);
    }
  },[schema]);

  //// EDN -> PARSING MODEL SCHEMA AND SETTING THE DEFAULTS OR EXISITNG DATA

  useEffect(()=>{ // this is to update the node internals when the input handles are exposed
    updateNodeInternals(id);
  },[handles.input]);

  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input', 'input-integer', 'input-float'].includes(schema[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    if(schema[key]?.type === 'seed'){
      parsedValue = {
        isRandom: newValue.isRandom,
        seed: parseFloat(newValue.seed) || 1,
      };
    }
    
    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined;
    }

    if(schema[key].type === 'array'){
      switch (schema[key].array_type){
        case 'number':
        case 'integer':
          parsedValue = newValue
          .map((item) => parseFloat(item))
          .filter((item) => !isNaN(item));
          break;
        case 'string':
        default:
          break;
      }
    }
    updateNodeData(id,{
      params:{
        ...params,
        [key]: parsedValue,
      },
    });
    // setDynamicFields(prev => ({ ...prev, [key]: parsedValue }));
  };

  const resetModelParams = ()=>{
    setModelVersion(null);
    updateNodeData(id, {
      handles: {
        ...handles,
        input: [],
      },
      params:{},
      schema:{},
      model:{
        name:"",
        label:"",
        version:"",
      },
    });
  };

  const updateNameAndDesciprtion = (name, updatedDescription, version, cover) => {
    updateNodeData(id, {
      name,
      description: updatedDescription,
      model:{
        ...model,
        name,
        version,
        cover,
        service: service.current,
      },
    });
  };
  
  const handleModelNameChange = (event) => {
    setModelName(event.target.value);
    setCannotFindModel(false);
    if(event.target.value === ""){
      resetModelParams();
    }
  };

  const getModelVersionByName = async (modelNameToFetch) => {
    try {
      const response = await axiosInstance.get(`/v1/models/${modelNameToFetch}`);
      setCoverImage(response.data.cover_image_url);
      updateNameAndDesciprtion(modelNameToFetch, response.data.description, response.data.latest_version.id, response.data.cover_image_url);

      return response.data.latest_version.id;
    } catch (error) {
      setCannotFindModel(true);
    }

  };

  const getCivitModelVersionByName = async (civitModelName) => {
    // console.log("Getting model version from civit");
    const civitId = civitModelName.split('/').filter((part) => /^\d+$/.test(part))[0];
    try {
      const response = await axiosInstance.get(`/v1/models/c/${civitId}`);
      // console.log(response);

      return response.data;
    } catch (error) {
      setCannotFindModel(true);
    }
  };
  
  const getCivitModelVersionByID = async (civitId) => {
    // console.log("Getting model version from civit");
    try {
      const response = await axiosInstance.get(`/v1/models/c/version/${civitId}`);

      return response.data;
    } catch (error) {
      console.error("Could not get civit version ", error);
    }
  };

  const getModelVersionParams = async () => {
    try {
      const response = await axiosInstance.get(`/v1/models/${modelName}/${modelVersion}`);
      parseModelSchema(response.data);
    } catch (error) {
      // console.error("Could not get version params ", error);
    }
  };

  const handleStopProcessing = (status) => {
    lastRunParamsAndInputs.current = {params: null, input: null};
    isRunning.delete(id);
    setIsProcessing(false);
    setPredictionId(null);
    // runInitiatedRef.current.delete(id);
    setProgress(0);
    if(status)
      updateModelRunTriggerStatus(id, status);
  };

  const handleModelNamePaste = async (event) =>{
    setModelVersion(null);
    setCivitModelNotSupported(false);
    let version;
    if(event.clipboardData.getData('text').includes('/civitai.com')){
      service.current = "civit";
      setCivitLoading(true);
      const res1 = await getCivitModelVersionByName(event.clipboardData.getData('text'));
      const res2 = await getCivitModelVersionByID(res1.modelVersions[0].id);
      
      const regex = /urn:air:[^:]+:([^:]+):[^:]+:[^@]+@[^@]+/;
      const match = res2.air.match(regex);
      if(match[1] !== "checkpoint"){
        setCivitModelNotSupported(true);
        setCivitModelNotSupportedMessage(civitModelTypeError(match[1]));
        
        return;
      }
      version = res2.air;
      setCoverImage(res2.images[0].url);
      updateNameAndDesciprtion(
        res1.name,
        "Civit.ai Model",
        res2.air,
        res2.images[0].url,
      );
    }
    else {
      service.current = "replicate";
      version = await getModelVersionByName(event.clipboardData.getData('text'));
    }
    if(!version){
      setCannotFindModel(true);
      setCivitLoading(false);
    }
    else {
      setModelVersion(version);
      setCivitLoading(false);
    }
  };


  useEffect(()=>{
    if(modelVersion && service.current){
      if(service.current === ModelType.Replicate){
        if(Object.keys(schema).length === 0)
          getModelVersionParams();
        else populateSchema(schema);
      }
      else if (service.current === ModelType.Civit) {
        if(Object.keys(schema).length === 0){
          updateNodeData(id, {
            schema: civitSchema,
            version: 2,
          });
          populateSchema(civitSchema);
        }
      }
    }
  },[modelVersion]);


  useEffect(()=>{
    updateNodeData(id, {
      model:
        { ...model,
          name:modelName,
        },
    },
    );
  },[modelName]);

  const handleInputConnected = useCallback (() => {
    if (input) {
      Object.keys(input).forEach((key) => {
        // if (dynamicFields[key] !== undefined) {
        //     setDynamicFields(prevDynamicFields => ({
        //         ...prevDynamicFields,
        //         [key]: input[key]
        //     }));
        // }
        if (params[key] !== undefined) {
          updateNodeData(id,{
            params:{
              ...params,
              [key]:input[key],
            },
          });
        }
      });
    }
  }, [input]);

  useEffect(()=>{
    // console.log("input changed", input);
    // recheck validation;
    if(input){
      const validationResult = validateRequiredInput(handles, input);
      setValidationError?.(validationResult.missingKeys);
    }
    /// when a connection is made or present we need to update the params (dynamic fields accordingly)
    handleInputConnected();
  },[input]);

  const handleStartProccessing = useCallback( () => {
    if(saveParamsHistoryFlag){
      lastRunParamsAndInputs.current = {
        params: cleanParamsForSaving(params, prevSeed.current),
        input,
      };
    }
    isRunning.add(id);
    setPredictionId(null);
    setErrorMessage(null);
    setPredictionStatus("starting");
    setIsProcessing(true);
    predictionCanceled.value = false;
    setPredictionReady(false);
    setPollingError(false);
    // runInitiatedRef.current.add(id);
  },[params, input]);

  const validateRequiredInput = (handles, input) => {
    if (version !== 2) return { isValid: true, missingKeys: null }; // backwards compatibility
    let missingKeys = [];
    if (!input) {
      missingKeys = Object.entries(handles.input)
        .filter(([, handle]) => handle.required)
        .map(([key]) => key);
  
      return { isValid: false, missingKeys };
    }
  // Iterate over handles to find missing required inputs
    for (const [key, handle] of Object.entries(handles.input)) {
      if (handle.required && !input[key]) {
        missingKeys.push(key);
      }
    }
    return missingKeys.length > 0 ? { isValid: false, missingKeys } : { isValid: true, missingKeys: null };
  };
  
  /// RUN MODEL AND HADNLE POLLING
  const run =  async () => {

    // validate required input (version is new node version 28.9.24)
    setValidationError([]);
    const validationResult = validateRequiredInput(handles, input);
    if(!validationResult.isValid) {
      setValidationError(validationResult.missingKeys);
      setErrorMessage(`${translate(I18N_KEYS.MODEL_NODE.ERROR_REQUIRED)} ${validationResult.missingKeys}`);
      setPollingError(true);
      posthog.capture('run_model_error', { model: modelName, type: service.current, error:'missing required input' });
      return;
    }

    posthog.capture('run_model_start', { model: modelName, type: service.current });
    

    // handle seed management
    if(params?.seed && typeof params.seed === 'object'){ // case when seed is an object and not an integer
      if(Object.keys(params.seed).length > 0){ // case it's not an empty object (has isRandom and seed)
        if(params.seed.isRandom){
          prevSeed.current = lodash.random(1, 1000000);
        }
        else prevSeed.current = params?.seed.seed || undefined;
      }
      else params.seed = undefined; // case it's an empty object - turn to undefined to avoid sending empty object
      // } else if (params.seed === NaN ){
      //     params.seed = undefined;
    } // else seed is not an object, it's an integer and we keep it as is
    handleStartProccessing();

    if(params?.seed && typeof params.seed === 'object' && Object.keys(params.seed).length>0) { // in case the seed is an object we need to update the seed value
      updateNodeData(id,{
        params:{
          ...params,
          seed: {
            ...params.seed,
            seed: prevSeed.current,
          },
        },
      });
    } // else the value is an integer and we keep it as is

    /// end of seed management

    // exception for re-light model - remove alpha channel

    const cloned_input = _.cloneDeep(input);
    if(model.name === 'gbieler/change-background-and-relight' ||
      model.name === 'zsxkib/ic-light'
    ) {
      cloned_input.subject_image.url = await rgbaToRgb(cloned_input.subject_image.url);
    }

    try {
      const newPredictionId = (await runModel(handles, cloned_input, { name: modelName, version: modelVersion, service: service.current }, params ,prevSeed.current, version))?.predictionId;
      setPredictionId(newPredictionId);
      updateModelRunTriggerStatus(id, "starting", 0 ,newPredictionId);
      // when the seed is random, we need to update the seed value to a random number

    }
    catch (error)
    {
      setPollingError(true);
      setErrorMessage(error?.response?.data?.error?.detail || error.message);
      handleStopProcessing("failed");
    }
  };
  
  /// HANDLE RUNNING MODEL
  useEffect(()=>{
    const currentModel = modelRunTrigger.find((m) => m.id === id);
        
    if(!currentModel)
      return;

    if(currentModel && !modelVersion){
      updateModelRunTriggerStatus(id, "failed");
      
      return;
    }

    if(currentModel.status === "canceled"){
      predictionCanceled.value = true;
      handleStopProcessing();
      
      return;
    }

    // if(runInitiatedRef.current.has(id) || isRunning.has(id)) return;
    if(isRunning.has(id)) return;

    if(currentModel.status !== "pending") return;

    const inputsChanged = JSON.stringify(input) !== JSON.stringify(prevInput);
        
    setIsProcessing(true);

    /// prevent from running the model twice in parallel (because of the input and modelRunTriggger dependency)
    if(predictionId)
      return;

    const { predecessors } = currentModel;
    if (!predecessors || predecessors.length === 0) {
      run();
      
      return;
    }

    const predecessorsStatus = predecessors.map((predecessorId) =>
      modelRunTrigger.find((m) => m.id === predecessorId)?.status,
    );

    const allPredecessorsReady = predecessorsStatus.every((status) => status === "ready");
    const anyPredecessorFailedOrCanceled = predecessorsStatus.some((status) => status === "failed" || status === "canceled");
        
    if(anyPredecessorFailedOrCanceled){
      handleStopProcessing("canceled");
      
      return;
    }
    if(allPredecessorsReady && inputsChanged){
      run();
    }
  }, [modelRunTrigger, input]);

  
  /// POLLING
  const checkPredictionStatus = async (predictionIdToCheck) => {
    try {

      const response = await axiosInstance.get(`/v1/models/predict/${predictionIdToCheck}/status`);
      setPredictionStatus(response.data.status);

      if(predictionCanceled.value) // this is to check if there was a cancel after the status request was sent 
        return;

      switch(response.data.status){
        case "succeeded":
          
          if(!isRunning.has(id)) return;
          setPredictionReady(true);

          if(response.data.remainingCredits != null){
            setUserCredits(response.data.remainingCredits);
          }
          // console.log("Prediction is ready", response.data);
          hasNewResults.current = true;
          if(saveParamsHistoryFlag) {
            updateNodeData(id, {
              result: data?.result 
                ? [
                    ...data.result,
                    ...response.data.results.map(result => ({
                      ...result,
                      params: lastRunParamsAndInputs.current.params,
                      input: lastRunParamsAndInputs.current.input,
                    }))
                  ] 
                : response.data.results.map(result => ({
                    ...result,
                    params: lastRunParamsAndInputs.current.params,
                    input: lastRunParamsAndInputs.current.input,
                  })),
              mjdata: response.data?.mjdata, 
            });
          } else {
            updateNodeData(id, {
              result: data?.result ? [...data.result, ...response.data.results] : response.data.results,
              mjdata: response.data?.mjdata,
            });
          }
          
         
          handleStopProcessing();
          posthog.capture('run_model_end', { model: modelName, type: service.current });
                    
          break;
        case "starting":
          break;
        case "processing":
          setProgress(response.data.progress);
          updateModelRunTriggerStatus(id, "processing", response.data.progress, predictionIdToCheck);
          break;
        case "failed":
          posthog.capture('run_model_error', { model: modelName, type: service.current,  error:response.data.error});
          handleStopProcessing("failed");
          setErrorMessage(`Imported Model Error: ${response.data.error}`);
          setPollingError(true);
          break;
        case "canceled":
          handleStopProcessing();
          break;
      }
    } catch (error) {
      setPollingError(true);
      handleStopProcessing("failed");
    }
  };

  useEffect(()=> {
    if(predictionStatus === "succeeded" && data.result) {
      updateModelRunTriggerStatus(id, "ready", 100);
      // runInitiatedRef.current.delete(id);
      isRunning.delete(id);
    }
  },[data.result, predictionStatus]);

  useEffect(() => {
    if (predictionId && !predictionReady && !pollingError) {
      let startTime = Date.now();
      let isMounted = true; // Flag to track the mounted status

      const handlePolling = () => {
        const elapsedTime = Date.now() - startTime;

        let interval;
        if (elapsedTime < 10000) {
          interval = 1000;
        } else if (elapsedTime < 30000) {
          interval = 2500;
        } else {
          interval = 5000;
        }

        checkPredictionStatus(predictionId).then(() => {
          // Check if the component is still mounted before scheduling the next poll
          if (isMounted && !predictionReady && !pollingError) {
            setTimeout(handlePolling, interval);
          }
        }).catch(() => {
          if (isMounted) {
            setPollingError(true); // Set polling error if there's an exception
          }
        });
      };

      handlePolling();

      // Cleanup function to clear pending timeouts and update the mounted flag
      return () => {
        isMounted = false; // Indicate the component has unmounted
        isRunning.delete(id);
      };
    }
  }, [predictionId, predictionReady, pollingError]);
  //// END OF RUN MODEL AND HANDLE POLLING

  
  const renderStatus = (status) => {
    switch (status){
      case "starting":
        return (
          <Typography>Starting</Typography>
        );
      case "processing":
        return (
          <Typography>{progress}%</Typography>
        );
      default:
        return (
          <Typography>Waiting</Typography>
        );
    }
  };

  const handleOpenGalleryClick = () => {
    setMediaArray(data.result);
    setSelectedFile(selectedOutput);
    setShowGallery(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space' && showGalleryIcon) {
        event.preventDefault();
        handleOpenGalleryClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showGalleryIcon]);

  return(
    <>
      {container === "node" && <>
        <Typography variant="caption">{description}</Typography>
            
        <Box className={ isFocused ? "nowheel nodrag nopan": "" } sx={ { mt:2, width:'100%', position:'relative' } }>
          <TextField
            autoComplete="new-password"// prevent autocomplete
            onFocus={ () => {setIsFocused(true);} }
            onBlur={ () => {setIsFocused(false);} }
            disabled={ !!(!editable || modelVersion || role === "guest") }
            sx={ { mb:1 } }
            size="small"
            label="Model Name"
            fullWidth
            value={ modelName }
            onChange={ handleModelNameChange }
            onPaste={ (event) => handleModelNamePaste(event) }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {civitLoading && <CircularProgress size={14} color="weavy_cta_secondary"/>}
                </InputAdornment>
              ),
            }}
          />
          {cannotFindModel && <Typography color={ color.Red } variant="caption" sx={ { mt:-1, fontSize:'9px', position:'relative', top:'-5px', left:'2px' } }>Could not find model</Typography>}
          {/* <TextField
                size="small"
                label="Model Version"
                fullWidth
                value={modelVersion}
                onChange={handleModelVersionChange}
                /> */}
        </Box>
        {coverImage && !data.result && <Box sx={ { width:'100%', mb:1 } }>
          <img src={ coverImage } width={ '100%' } />
        </Box>}
        {data.result &&
        <Box sx={ { position:'relative' } } onMouseEnter={ ()=>setShowGalleryIcon(true) } onMouseLeave={ ()=>setShowGalleryIcon(false) }>
          <NodeImageList images={ data.result } selected={ selectedOutput } setSelected={ setSelectedOutput } container={ "node" } />
          {data.result.length > 0 && data.result[0].type !== 'text' &&
          <ButtonBase onClick={ handleOpenGalleryClick } sx={ { position:'absolute', bottom:'11px', right:'5px', opacity:showGalleryIcon? 1:0, transition:'opacity 0.2s' } }>
            <FullscreenIcon fontSize='medium' sx={ { filter: 'drop-shadow(0px 0px 1px black)' } } />
          </ButtonBase>}
        </Box>
        }
           

        <LoadingButton
          size="small"
          onClick={ ()=>run() }
          endIcon={ data.result && data.result.length > 0 ? <ReplayIcon />: <PlayArrowIcon /> }
          loading={ isProcessing }
          loadingPosition="end"
          variant="contained"
          fullWidth
          color="weavy_cta"
          disabled={ !modelVersion || role !== 'editor' }
        >
          {isProcessing ? (
            renderStatus(predictionStatus)
          ):(
            <>{data.result && data.result.length > 0 ? 'Re-run model':'Run Model'}</>
          )}
        </LoadingButton>
      </>
      }
      {/* Advances mode */}
      {Object.keys(schema).length !== 0 && container === "drawer" && <Box id='advanced-mode-container' sx={ { width:'100%', mt:1 } }>
        {/* <Box id='advanced-mode-button' sx={{width:'100%', textAlign:'right', mb:1}}>
                    <Link
                        component="button"
                        variant="caption"
                        onClick={toggleAdvancedSection}
                    >
                        {isAdvancedOpen ? "Close" : "Advance"}
                    </Link>
                </Box> */}
        {/* {isAdvancedOpen && ( */}
                    

        <Box id='advnaced-mode-content-container' className="nowheel nodrag nopan">
          {sortedSchema
            .map((key) => renderDynamicField(params, key, schema[key], handleChange))
          }
        </Box>
      </Box>}
      {(pollingError || civitModelNotSupported) && <Box id="model-error-container" sx={ { mt:1 } }>
        {pollingError && <Typography variant="caption" color='error'>{errorMessage ? errorMessage : "Something went wrong"}</Typography>}
        {civitModelNotSupported && <Typography variant="caption" color='error'>{civitModelNotSupportedMessage}</Typography>}
      </Box>}
      {modelName &&  modelVersion && editable && role==='editor' && <Box id="save-model-container" sx={ { mt:1, width:'100%', justifyContent:'center', display:'flex' } }>
        <SaveCustomNode id={ id } data={ data } setNodesTypes={ setNodesTypes } />
      </Box>}
    </>

  );
}

export default ImportModelCoreV2;
