import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Stage, Layer, Line, Image as KonvaImage, Rect } from 'react-konva';
import Konva from 'konva';
import { 
  TextField, 
  Box, 
  IconButton, 
  Dialog, 
  Slider, 
  Divider, 
  Typography, 
  Link,
  InputAdornment,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import useImage from 'use-image';
import { useViewport } from 'reactflow';
import { color as colors } from "../../colors";
import { useUserRole } from "../Recipe/UserRoleContext";
import { enableCustomCursor, disableCustomCursor, setCursorRadius , setCursorColor, hideCustomCursor, showCustomCursor } from "../Recipe/FlowComponents/Editor/CustomCursor";

const debounce = (func, wait) => {
  let timeout;
  
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const NODE_WIDTH = 400;
const MIN_SIZE = 50;
const MAX_SIZE = 4096;
const STEP = 1;
const DEFAULT_WIDTH = 400;
const DEFAULT_HEIGHT = 400;

const checkersStyle = (size) => {
  return (
      {
      backgroundColor:'#686868',
    backgroundImage: 
    `linear-gradient(45deg, #8c8c8c 25%, transparent 25%), 
    linear-gradient(-45deg, #8c8c8c 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #8c8c8c 75%), 
    linear-gradient(-45deg, transparent 75%, #8c8c8c 75%)`,
    backgroundSize: `${size}px ${size}px`,
    backgroundPosition: `0 0, 0 ${size/2}px, ${size/2}px -${size/2}px, -${size/2}px 0px`,
    }
  )
}

function PaintCore({ id, data , updateNodeData }) {

  const { zoom } = useViewport();
  const { handles } = data;
  const [localSelectedSize, setLocalSelectedSize] = useState(
    data.result?.selectedSize ? data.result.selectedSize :
    { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }
  );
  const [localScaleFactor, setLocalScaleFactor] = useState(data?.result?.scaleFactor || NODE_WIDTH / DEFAULT_WIDTH);
  const [tempWidth, setTempWidth] = useState(data.result?.selectedSize?.width / data?.result?.scaleFactor || data.result?.canvasSize?.width || DEFAULT_WIDTH ); // canvasSize for backwards compatibilty
  const [tempHeight, setTempHeight] = useState(data.result?.selectedSize?.height / data?.result?.scaleFactor || data.result?.canvasSize?.height || DEFAULT_HEIGHT); // canvasSize for backwards compatibilty
  const [aspectRatio, setAspectRatio] = useState(data.result?.aspectRatio?? 1);
  const [lockAspectRatio, setLockAspectRatio] = useState(data.result?.lockAspectRatio ?? true);
  const [isFocused, setIsFocused] = useState(false);
  const [tool, setTool] = useState('brush');
  const [localBackgroundColor, setLocalBackgroundColor] = useState(data?.result?.backgroundColor || '#000000FF' );
  const [localColor, setLocalColor] = useState(data?.result?.color || '#FFFFFF');
  const [localBrushSize, setLocalBrushSize] = useState(data?.result?.brushSize ||  50);
  const [mouseMoved, setMouseMoved] = useState(false);
  const [localLines, setLocalLines] = useState(data?.result?.lines || []);
  const [history, setHistory] = useState([data?.result?.lines || []]);
  const [redoHistory, setRedoHistory] = useState([]);
  const isDrawing = useRef(false);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [isBackgroundPickerOpen, setIsBackgroundPickerOpen] = useState(false);
  const [image] = useImage(data.input?.image?.url || '' , 'anonymous');
  // const [imageSizeOption, setImageSizeOption] = useState(null);
  const stageRef = useRef(null);
  const customCursorRef = useRef(null);

  const role = useUserRole();


  const handleMouseDown = (e) => {
    if(role !== 'editor') return;
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const scaledPos = {
      x: pos.x / localScaleFactor,
      y: pos.y / localScaleFactor
    };
    if (scaledPos.x >= 0 && scaledPos.x <= stage.width() / localScaleFactor && scaledPos.y >= 0 && scaledPos.y <= stage.height() / localScaleFactor) {
      isDrawing.current = true;
      setMouseMoved(false);
      setLocalLines([...localLines, { tool, points: [scaledPos.x, scaledPos.y], color: localColor, brushSize: localBrushSize / localScaleFactor }]);
    }
  };

  const handleMouseMove = (e) => {
    if(role !== 'editor' || !isDrawing.current) return;
    setMouseMoved(true);
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const scaledPoint = {
      x: point.x / localScaleFactor,
      y: point.y / localScaleFactor
    };
    const lastLine = localLines[localLines.length - 1];
    lastLine.points = lastLine.points.concat([scaledPoint.x, scaledPoint.y]);
    setLocalLines([...localLines.slice(0, localLines.length - 1), lastLine]);
  };

  const handleMouseUp = useCallback(() => {
    if(role !== 'editor') return;
    if (isDrawing.current) {
      if (!mouseMoved) {
        const stage = stageRef.current;
        const point = stage.getPointerPosition();
        const scaledPoint = {
          x: point.x / localScaleFactor,
          y: point.y / localScaleFactor
        };
        const lastLine = localLines[localLines.length - 1];
        lastLine.points = lastLine.points.concat([scaledPoint.x, scaledPoint.y]);
        setLocalLines([...localLines.slice(0, localLines.length - 1), lastLine]);
      }
      setHistory((prevHistory) => [...prevHistory, localLines]);
      setRedoHistory([]);
    }
    isDrawing.current = false;
  }, [localLines, mouseMoved, role, localScaleFactor]);

  const handleMouseEnter = useCallback(() => {
    if(role !== 'editor') return;
    showCustomCursor(customCursorRef);
  }, [localColor, localBrushSize]);
    
  const handleMouseLeave = () => {
    isDrawing.current = false;
    hideCustomCursor(customCursorRef);
  };



    
  useEffect(() => {
    if( role !== 'editor') return;
    
    const stage = stageRef.current;
    if (stage) {
      const container = stage.container();
      container.style.cursor = 'none';
      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
      enableCustomCursor(customCursorRef, container, stage, localColor, localBrushSize*zoom);
      hideCustomCursor(customCursorRef);
    
      // Clean up custom cursor on component unmount
      return () => {
        disableCustomCursor(customCursorRef);
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);


  useEffect(() => {
    if (data.input?.image) {
      const newScaleFactor = NODE_WIDTH / data.input.image.width;
      const imageSize = {
        width: data.input.image.width * newScaleFactor,
        height: data.input.image.height * newScaleFactor,
      };
      setLocalSelectedSize(imageSize);
      setTempWidth(data.input.image.width);
      setTempHeight(data.input.image.height);
      // setImageSizeOption(imageSize);
      setLocalScaleFactor(newScaleFactor);
    } else {
      if(data.result?.selectedSize) return;
      let newScaleFactor;
      if(data.result?.selectedSize) {
        if(data.result?.selectedSize?.width >= data.result?.selectedSize?.height) {
          newScaleFactor = NODE_WIDTH / data.result?.selectedSize?.width;
        }
        else if (data.result?.selectedSize?.width < data.result?.selectedSize?.height) {
          newScaleFactor = NODE_WIDTH / data.result?.selectedSize?.height;
        }
        else newScaleFactor = 1;
      }
      else newScaleFactor = NODE_WIDTH / DEFAULT_WIDTH;

      setLocalSelectedSize(
        data.result?.selectedSize ? 
        data.result?.selectedSize :
        { 
          width: DEFAULT_WIDTH * newScaleFactor, 
          height: DEFAULT_HEIGHT * newScaleFactor
        }
      ); 
      setTempWidth(data.result?.selectedSize?.width || DEFAULT_WIDTH);
      setTempHeight(data.result?.selectedSize?.height || DEFAULT_HEIGHT);
      setLocalScaleFactor(newScaleFactor);
    }
    // force render on init
    handleExport(
      localLines, 
      localBrushSize, 
      localColor, 
      localSelectedSize, 
      localScaleFactor, 
      localBackgroundColor, 
      aspectRatio, 
      lockAspectRatio
    );

  }, [data.input?.image]);

  const handleUndo = useCallback(() => {
    setHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        const newHistory = prevHistory.slice(0, -1);
        setLocalLines([...newHistory[newHistory.length - 1]]);
        setRedoHistory((prevRedoHistory) => [prevHistory[prevHistory.length - 1], ...prevRedoHistory]);
        
        return newHistory;
      }
      
      return prevHistory;
    });
  }, [setLocalLines]);
    
  const handleRedo = useCallback(() => {
    setRedoHistory((prevRedoHistory) => {
      if (prevRedoHistory.length > 0) {
        const [nextState, ...newRedoHistory] = prevRedoHistory;
        setHistory((prevHistory) => [...prevHistory, nextState]);
        setLocalLines(nextState);
        
        return newRedoHistory;
      }
      
      return prevRedoHistory;
    });
  }, [setLocalLines]);
    
  const handleReset = useCallback(() => {
    setLocalLines([]);
    setHistory([[]]);
    setRedoHistory([]);
  }, []);

  useEffect(() => {
    setCursorColor(customCursorRef, localColor);
  }, [localColor]);

  useEffect(() => {
    setCursorRadius(customCursorRef, localBrushSize*zoom);
  }, [localBrushSize, zoom]);

  useEffect(() => {
    if(tool === 'brush'){
      setCursorColor(customCursorRef, localColor);
    } else {
      setCursorColor(customCursorRef, '#FFFFFF');
    }
  }, [tool]);

  /// handle export
  const handleExport = useCallback((lines, brushSize, color, selectedSize, scaleFactor, backgroundColor, aspectRatio ,lockAspectRatioState) => {
    if(!selectedSize || !scaleFactor) return;
    const stage = stageRef.current;
    // handle saving data
    const stageJSON = stageRef.current.toJSON();
    updateNodeData(id, {
      result:
            {
              canvasData: stageJSON,
              backgroundColor,
              selectedSize,
              scaleFactor,
              aspectRatio,
              color,
              brushSize,
              lines,
              lockAspectRatio: lockAspectRatioState,
            },
    });
    const fullCanvasDataURL = stage.toDataURL({
      mimeType: "image/png",
      pixelRatio: 1 / scaleFactor,
    });

    // Temporarily remove or hide the background image
    const backgroundImage = stage.findOne('Image');
    if (backgroundImage) {
      backgroundImage.hide();
      stage.draw();
    }
    // Store original colors
    const originalColors = [];
    const originalAlphas = [];
    const drawingLayer = stage.findOne('#drawingLayer');
    drawingLayer.children.forEach((line) => {
      if (line instanceof Konva.Line) {
        const originalColor = line.stroke();
        let alpha = "FF";

        if (originalColor.length === 9) { // If color is in #RRGGBBAA format
          alpha = originalColor.slice(7, 9);
        }
        originalColors.push(originalColor);
        originalAlphas.push(alpha);

        // Set the stroke to white with the same alpha value
        line.stroke(`#FFFFFF${alpha}`);
        // line.stroke(`#FFFFFF`);
      }
    });

    const backgroundLayer = stage.findOne('#backgroundLayer');
    backgroundLayer.getChildren()[0].fill('black');
    stage.draw();

    // Export the lines only (without the background image)
    const linesOnlyDataURL = stage.toDataURL({
      mimeType: "image/png",
      pixelRatio: 1 / scaleFactor,
    });

    // Restore the original settings
    // console.log(
    //   backgroundColor,
    // );
    backgroundLayer.getChildren()[0].fill(backgroundColor);
    drawingLayer.children.forEach((line, index) => {
      if (line instanceof Konva.Line) {
        line.stroke(originalColors[index]);
      }
    });
    if (backgroundImage) {
      backgroundImage.show();
    }
    stage.draw();

    const formattedOutput = {};
    const output = [
      {
        type:'image',
        url:fullCanvasDataURL,
        width: selectedSize.width / scaleFactor,
        height: selectedSize.height / scaleFactor,
      },
      {
        type:'image',
        url:linesOnlyDataURL,
        width: selectedSize.width / scaleFactor,
        height: selectedSize.height / scaleFactor,
      },
    ];
    handles.output.forEach((elementName, index) => {
      formattedOutput[elementName] = output[index];
    });
    updateNodeData(id, {
      output: formattedOutput,
    });
        
  }, [updateNodeData, id]);

  const debouncedExport = useRef();

  useEffect(() => {
    debouncedExport.current = debounce((lines, brushSize, color, selectedSize, scaleFactor, backgroundColor, aspectRatio, lockAspectRatioState) => {
      handleExport(lines, brushSize, color, selectedSize, scaleFactor, backgroundColor,aspectRatio ,lockAspectRatioState);
    }, 500);
  }, []);

  useEffect(() => {
    if (debouncedExport.current) {
      debouncedExport.current(localLines, localBrushSize, localColor, localSelectedSize, localScaleFactor, localBackgroundColor, aspectRatio, lockAspectRatio);
    }
  }, [localLines, localBrushSize, localColor, localSelectedSize, localScaleFactor, localBackgroundColor, aspectRatio ,lockAspectRatio, data.input]);
    

  const handleSizeUpdate = useCallback((dimension, value) => {
    let newSize = parseInt(value) || MIN_SIZE;
    newSize = Math.max(MIN_SIZE, Math.min(MAX_SIZE, newSize));

    let newScaleFactor, newAspectRatio;

    if (lockAspectRatio) {
      if (dimension === "width") {
        newScaleFactor = NODE_WIDTH / newSize;
        setTempWidth(newSize);
        setTempHeight(Math.round(newSize / aspectRatio));
        setLocalSelectedSize(() => ({
          width: newSize * newScaleFactor,
          height: Math.round(newSize / aspectRatio * newScaleFactor) ,
        }));
      } else if (dimension === "height") {
        setTempHeight(newSize);
        setTempWidth(Math.round(newSize * aspectRatio));
        newScaleFactor = NODE_WIDTH / Math.round(newSize * aspectRatio);
        setLocalSelectedSize(() => ({
          width: Math.round(newSize * aspectRatio * newScaleFactor) ,
          height: newSize * newScaleFactor,
        }));
      }
    } else {
      newAspectRatio = dimension === 'width' ? 
        newSize / tempHeight : tempWidth / newSize;
      newScaleFactor = dimension === 'width' ? NODE_WIDTH / newSize : NODE_WIDTH / Math.round(newSize * newAspectRatio);
      if(dimension === 'width'){
        setTempWidth(newSize);
        setLocalSelectedSize(() => ({
          width: newSize * newScaleFactor,
          height: Math.round( newSize / newAspectRatio * newScaleFactor),
        }))
      }
      else if (dimension === 'height'){
        setTempHeight(newSize);
        setLocalSelectedSize(() => ({
          width: Math.round(newSize * newAspectRatio * newScaleFactor) ,
          height: newSize * newScaleFactor,
        }))
      }
      setAspectRatio(newAspectRatio);
    };
    setLocalScaleFactor(newScaleFactor);

  },[aspectRatio, setAspectRatio, localScaleFactor, setLocalScaleFactor, localSelectedSize, setLocalSelectedSize, lockAspectRatio]);

  return (
    <Box className="painter-container">
      <Box id="painter-settings" sx={ { display:'flex', justifyContent:'space-between',alignItems:"center", width:'100%', mb: role === 'editor' ? 0 : 1 } }>
        <Box id='painter-size-settings'
            sx={ {
              display: "flex",
              // mt:1,
              alignItems:'center',
              cursor: role === "guest" ? 'default' : '',
              pointerEvents: role === "guest" ? 'none' : '',
            } }
          >
            <TextField
              disabled={ !!data.input?.image }
              // label="Width"
              fullWidth
              aria-label="Width"
              type="number"
              name="width"
              variant="outlined"
              size="small"
              value={ tempWidth }
              onChange={ (e) => setTempWidth(e.target.value) }
              onKeyDown={ (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission if within a form
                  handleSizeUpdate('width', tempWidth);
                  e.target.blur();
                }
              } }
              onBlur={ () => {handleSizeUpdate('width', tempWidth); setIsFocused(false);} }
              inputProps={ {
                min: MIN_SIZE,
                max: MAX_SIZE,
                step: STEP,
              } }
              className={ isFocused ? "nowheel nodrag nopan": "" }
              onFocus={ () => {setIsFocused(true);} }
              InputProps={ {
                startAdornment: <InputAdornment position="start" sx={{color:'red'}}>w</InputAdornment>,
              } }
              
            
            />
            <IconButton
              disabled={ !!data.input?.image }
              sx={ {
                width:'20px',
                height:'20px',
                py:.2,
                borderRadius:"4px",
                fontSize:'12px',
                mx:.4
              } }
              onClick={() => setLockAspectRatio((prev) => !prev)}
            >
            { lockAspectRatio ?
              (
                <i className="fa-light fa-lock fa-xs"></i>
              ):(
                <i className="fa-light fa-lock-open fa-xs"></i>
              )
            }
            </IconButton>
            <TextField
              disabled={ !!data.input?.image }
              // label="Height"
              aria-label='Height'
              fullWidth
              type="number"
              name="height"
              variant="outlined"
              size="small"
              value={ tempHeight }
              onChange={ (e) => setTempHeight(e.target.value) }
              onKeyDown={ (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSizeUpdate('height', tempHeight);
                  e.target.blur();
                }
              } }
              onBlur={ () => { handleSizeUpdate('height', tempHeight); setIsFocused(false);} }
              inputProps={ {
                min: MIN_SIZE,
                max: MAX_SIZE,
                step: STEP,
              } }
              className={ isFocused ? "nowheel nodrag nopan": "" }
              onFocus={ () => {setIsFocused(true);} }
              InputProps={ {
                startAdornment: <InputAdornment position="start">h</InputAdornment>,
              } }
              // sx={ { ml:1 }}
            />
            
        </Box>
        <Dialog sx={{maxHeight:'100vh'}} className="weavy-color-picker-dialog" open={ isBackgroundPickerOpen } onClose={ () => setIsBackgroundPickerOpen(false) }>
          <HexAlphaColorPicker color={ localBackgroundColor } onChange={ setLocalBackgroundColor } />
        </Dialog>
        <Box 
          id='paint-size-tools-color'
          sx={ {
            display:'flex',
            flexDirection:'row', 
            alignItems:'center'
          } }
          >
          <Box 
            disabled={data?.input?.image ? true : false}
            onClick={ () => { 
              if(role === 'editor' && !data?.input && !data.input?.image) 
                setIsBackgroundPickerOpen(true);
              } }
            sx={ { 
              cursor: role === 'editor' && !data?.input?.image ? "pointer": 'auto',
              mx:1,
              } }>
            <Box
              sx={ {
                position:'relative',
                width:"34px",
                height:"34px",
                borderRadius:1,
                border:"1px solid",
                borderColor:colors.Yambo_Black_Stroke,
                overflow:'hidden'
              } }
            >
            <Box 
              id="painter-background-color-background" 
              sx={{
                width:'100%',
                height:'100%',
                ...checkersStyle(8),
                }}
              >
            </Box>
            <Box 
              id="painter-background-color-color" 
              sx={{ 
                position:'absolute', 
                top:0, 
                left:0,
                width:'100%', 
                height:'100%', 
                background: localBackgroundColor,
                }}>
                
              </Box>
              <Box
                sx={{ 
                  position:'absolute', 
                  top:'calc(50% - 6px)', 
                  left:'calc(50% - 6px)',
                  }}
              >
                <i className="fa-regular fa-fill-drip"></i>
              </Box>
            </Box>
           
          </Box>
            
        </Box>
      </Box>
      <Box id='painter-main' sx={ { display: 'flex', flexDirection: 'column', alignItems: 'center', width:'100%' } }>
        {role === 'editor' && <>
        <Box id="painter-tools-container" sx={ { display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', alignItems:'center', my:1 } }>
          <Box d="painter-tools">
            <IconButton
              className={ tool === 'brush' ? 'painter-tool selected':'painter-tool' }
              onClick={ () => setTool('brush') }
              sx={ {
                width:'30px',
                height:'30px',
                p:.2,
                borderRadius:"4px",
              } }
            >
               <i className="fa-regular fa-paintbrush fa-xs"></i>
            </IconButton>
            <IconButton
              className={ tool === 'eraser' ? 'painter-tool selected':'painter-tool' }
              onClick={ () => setTool('eraser') }
              sx={ {
                width:'30px',
                height:'30px',
                p:.2,
                borderRadius:"4px",
                ml:1,
              } }
            >
              <i className="fa-regular fa-eraser fa-xs"></i>
            </IconButton>
            </Box>
            <Box id='painter-undo-settings'>
              <IconButton onClick={ handleUndo } disabled={ history.length === 1 } sx={ { width:'30px', height:'30px',p:.2, borderRadius:"4px" } }>
                <UndoIcon fontSize='small' />
              </IconButton>
              <IconButton onClick={ handleRedo } disabled={ redoHistory.length === 0 } sx={ { width:'30px', height:'30px',p:.2, borderRadius:"4px" } }>
                <RedoIcon fontSize='small' />
              </IconButton>
              <Link onClick={ ()=>handleReset() } sx={ { cursor:'pointer', fontSize:'12px', mx:1 } }>Clear</Link>
          </Box>
        </Box> 
        
        </>
        }
        <Divider sx={ { width:'100%' } } />
        { role === 'editor' && <Box id="painter-brush-attributes-container" sx={ { width:'100%', display:"flex", alignItems:"center", my:1 } }>
          <Dialog className="weavy-color-picker-dialog" open={ isPickerOpen } onClose={ () => setPickerOpen(false) }>
            <HexAlphaColorPicker color={ localColor } onChange={ setLocalColor } />
          </Dialog>
          <Typography variant="caption" sx={ { mr:1 } }>Color</Typography>
          <Box onClick={ () => setPickerOpen(true) } sx={ { cursor:"pointer", mr:2, position:'relative' } }>
            <Box sx={ { ...checkersStyle(8), width:"20px", height:"20px", borderRadius:"50%" } }></Box>
            <Box sx={ { position: 'absolute', top:0, left:0, background: localColor, width:"20px", height:"20px", borderRadius:"50%" } }></Box>
          </Box>
          <Typography variant="caption" sx={ { mr:1 } }>Size</Typography>
          <Slider
            value={ localBrushSize }
            sx={ { width:"160px" } }
            onChange={ (e, value) => setLocalBrushSize(value) }
          />
          <Typography variant="caption" sx={ { mx:1, fontWeight:'bold' } }>{localBrushSize}</Typography>
        </Box> }
        <Box sx={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } }>
          <Stage
            ref={ stageRef }
            tabIndex="0"
            width={ localSelectedSize.width }
            height={ localSelectedSize.height }
            style={ { ...checkersStyle(30) } }
            onMouseDown={ handleMouseDown }
            onMousemove={ handleMouseMove }
            onMouseup={ handleMouseUp }
            scaleX={ localScaleFactor }
            scaleY={ localScaleFactor } 
          >
            <Layer id="backgroundLayer">
              <Rect
                x={ 0 }
                y={ 0 }
                width={ localSelectedSize.width / localScaleFactor }
                height={ localSelectedSize.height / localScaleFactor }
                fill={ localBackgroundColor }
              />
            </Layer>
            <Layer>
              {image && <KonvaImage image={ image } width={ localSelectedSize.width / localScaleFactor } height={ localSelectedSize.height / localScaleFactor } />}
            </Layer>
            <Layer id="drawingLayer">
              {localLines.map((line, i) => (
                <Line
                  key={ i }
                  points={ line.points }
                  stroke={ line.tool === 'eraser' ? '#FFFFFF' : line.color }
                  strokeWidth={ line.brushSize }
                  tension={ .5 }
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  }
                  
                />
              ))}
            </Layer>
          </Stage>
        </Box>
      </Box>
    </Box>
  );
}

export default PaintCore;
