import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES } from './languages';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: require('./en').default,
      },
    },
    lng: LANGUAGES.ENGLISH,
    fallbackLng: LANGUAGES.ENGLISH,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
