import { Box, ButtonBase, Modal, Typography, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { extrenal_links } from '../../externalLinks';
import { useTranslation } from "react-i18next";
import I18N_KEYS from "../../language/keys";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1112px',
    height:'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline:'none',
  };



function UpgradeModal ({currentUser, open, closeModal}) {
    
    const { t: translate } = useTranslation();

    const handleClose = () => {
        closeModal(false);
    };
    
    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h6">{translate(I18N_KEYS.UPGRADE_MODAL.TITLE)}</Typography>
                {/* <Typography variant="body1">
                    <Link target="_blank" href={extrenal_links.notionLumaArticle}>
                        {translate(I18N_KEYS.UPGRADE_MODAL.CTA)}
                    </Link>
                </Typography> */}
            </Box>
            <stripe-pricing-table pricing-table-id="prctbl_1Q1t1lKB1Jh4IYAtEXcMv1wD"
            publishable-key="pk_live_51PSKvDKB1Jh4IYAtAOvLi9e5vRbb731SYIsmmS2niY5XJdwGYRBcxd4Ha9gqQffOKzWNKs3e305tELKd7oPfyqFQ00nKuh9th4"
            client-reference-id={currentUser.uid}
            >
            </stripe-pricing-table>
            <ButtonBase sx={{position:'absolute', top:8, right:8}} onClick={handleClose}>
                   <CloseIcon/>
            </ButtonBase>
        </Box>

        </Modal>
    )
}

export default UpgradeModal;