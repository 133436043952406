import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../colors";

function DesignApp ({ setOpenDesignApp, nodes }) {

  const [inputs, setInputs] = useState([])

  useEffect(()=>{
    setInputs(() => {
      const filteredPrompts = nodes.filter(n => n.type === 'promptV2').slice(0, 2); // Limit promptV2 to 3
      const importInput = nodes.find(n => n.type === 'import'); // Get only the first import
  
      // Combine the limited prompts and import (if found)
      return importInput ? [...filteredPrompts, importInput] : filteredPrompts;
    });
  },[nodes]);



  useEffect(()=>{
    console.log(inputs, nodes);
  },[inputs, ]);

  return (
    <Box 
      sx={ {
        width:'100%',
        height:'100%',
        background:`${color.Yambo_BG}DD`,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'relative',
      } }
      >
        <Button onClick={ ()=>setOpenDesignApp(false) } sx={ { position:'absolute', top:30, right: 20 } }>
        <i className="fa-regular fa-xmark" style={ { fontSize:'28px' } }></i>
        </Button>
        <Box sx={{ border:'1px solid #444', width:'80%', height:'80%', background:color.Yambo_BG, flexDirection: 'row', display:'flex', p:2}}>
          
          <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', p:2, }}>
            {inputs && inputs.map((input) => {
              const inputContent = (() => {
                switch (input.type) {
                  case 'promptV2':
                    return (
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        value={input.data.result.prompt}
                      />
                    );
                  case 'import':
                    return (
                      <Box id="upload-container" sx={{ textAlign: 'center' }}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            height: '160px',
                            border: '1px dashed',
                            borderColor: color.Yambo_Blue_Stroke,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 1
                          }}
                        >
                          <Typography variant="body">Drag & Drop or Click to Upload</Typography>
                        </Box>
                      </Box>
                    );
                  default:
                    return null;
                }
              })();

              // Add the input name to all cases
              return (
                <Box key={input.id} sx={ { mb: 2 } }>
                  <Typography variant="body2" sx={ { mb: 1 } }>{input.data.name}</Typography> 
                  {inputContent}
                 
                </Box>
              );
            })}
             <Button variant="contained" color="weavy_cta" fullWidth>
              Publish App&nbsp;
              <i className="fa-light fa-rocket-launch"></i>
              </Button>
          </Box>
          <Box sx={{width: '50%', m:2, border:'1px solid #888', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i className="fa-thin fa-image fa-xl" style={ { fontSize:'50px' } }></i>
          </Box>
      </Box>
    </Box>
  )
}

export default DesignApp;