import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import LinearProgress from '@mui/material/LinearProgress';
import { useDropzone } from "react-dropzone";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import { color, colorMap } from '../../colors';

import axiosInstance from "../../services/axiosConfig";
import { useUserRole } from "../Recipe/UserRoleContext";
import { OutputOnly } from "./VisualNodes";

function ImportCloudinaryNode({ id, data, updateNodeData }) {

  const role = useUserRole();
  const [fileLink, setFileLink] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const [initialData] = useState(data?.initialData); // support drag from library
  const [uploadedFileType, setUploadedFileType] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [hasError, setHasError] = useState(false);

  /// this useEffect is to handle a case where the node was created from an existing file dragged from the media library
  useEffect(() => {
    if (initialData) {
      updateNodeData(id,
        {
          result: data.initialData,
          output:
            {
              file:data.initialData,
            },
          initialData: null,
        });
    }
  }, [initialData]);

  /// this useEffect is to handle a case where the node was created from copy and paste image (browser/os copy image)
  useEffect(() => {
    if(data.pastedData && data.pastedData?.imageFile){
      onDrop([data.pastedData.imageFile]);
      data.pastedData.imageFile = null;
      
      return;
    }
  }, [data.pastedData]);

  const uploadSuccess = (uploadData) => {

    updateNodeData(id,
      {
        result: {
          url: uploadData.url,
          thumbnailUrl: uploadData.thumbnailUrl,
          viewUrl: uploadData.viewUrl,
          type: uploadData.type,
          width: uploadData.width,
          height: uploadData.height,
          publicId: uploadData.publicId,
          visualId: uploadData.visualId,
        },
        output:
        {
          file:
          {
            url: uploadData.url,
            publicId: uploadData.publicId,
            type: uploadData.type,
            width: uploadData.width,
            height: uploadData.height,
            visualId: uploadData.visualId,
          },
        },
      });
         
    setUploadProgress(100);
  };

  const handleLinkChange = (event) => {
    setHasError(false);
    setFileLink(event.target.value);
  };

  useEffect(()=>{
    switch (data.result?.type) {
      case 'video':
        setPreviewImage(data.result?.thumbnailUrl);
        setUploadedFileType('image');
        break;
      case 'image':
        setPreviewImage(data.result?.thumbnailUrl);
        setUploadedFileType('image');
        break;
      case 'audio':
        setPreviewImage("/audio.png");
        setUploadedFileType('audio');
        break;
      case '3D':
        setPreviewImage(data.result?.thumbnailUrl);
        setUploadedFileType('3D');
      default:
        break;
    }
    
  },[data.result?.thumbnailUrl]);



  const onDrop = async (acceptedFiles) => {
    setHasError(false);
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileName = file.name.toLowerCase();
      
      if (file.type.startsWith('video')) {
        setUploadedFileType('video');
      } else if (file.type.startsWith('image')) {
        setUploadedFileType('image');
      } else if (file.type.startsWith('audio')) {
        setUploadedFileType('audio');
      } else if (fileName.endsWith('.glb')) {
        setUploadedFileType('3D');
      }
      if(file.type.startsWith('audio')) {
        setPreviewImage("/audio.png");
      } else {
        setPreviewImage(URL.createObjectURL(file)); // Create a temporary URL for the file
      }
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("media_metadata", true);

      
      try {
        setUploadProgress(0); // Initialize the progress bar
        const response = await axiosInstance.post(`/v1/nodes/upload`, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 95,
            );
            setUploadProgress(progress);
          },
        });
        
        uploadSuccess(response.data);
        // console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
        setHasError(true);
      } finally {
        setUploadProgress(0); // Reset the progress bar
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png','.webp','.webm', '.mp4', '.mov'],
      'audio/*': ['.mp3', '.wav', '.ogg'],
      'model/gltf-binary': ['.glb']
    },
    multiple: false, // Allow only one file to be dropped/uploaded
  });

  const openFileUploader = () => {
    // Trigger the input file element's click event
    const inputElement = document.getElementById(`file-upload-input-${id}`);
    inputElement.click();
  };

  const handleLinkInput = async (link) => {
    setUploadProgress(0);
    setHasError(false);
    setPreviewImage(link);
    setUploadedFileType('image');
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
    if (!urlPattern.test(link)) {
      console.error("Invalid URL");
      setHasError(true);
      
      return;
    }

    try {
      const parsedUrl = new URL(link);
      const filePath = parsedUrl.pathname;
      // const fileUrlPattern = /[/|.|\w|\s|-]*\.(?:jpg|jpeg|png|obj|fbx|glb)/gi;

      if (filePath) {
        const response = await axiosInstance.post(`/v1/nodes/upload`, null, {
          params: {
            file: fileLink,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 95,
            );
            setUploadProgress(progress);
          },
        });

        uploadSuccess(response.data);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
    finally {
      setUploadProgress(0); // Reset the progress bar after completion or error
    }
  };

  function renderMediaElement(url, type) {
    switch (type) {
      case 'video':
        return <video draggable='false' crossOrigin="anonymous" src={ url } width='100%' style={ { display:'block' } } />;
      case 'audio':
      case '3D':
      case 'image':
        return <img draggable='false' src={ url } width='100%'  alt="Media" style={ { display:'block' } } />;
      // Add more cases for other types
      default:
        return <Typography>No preview</Typography>;
    }
  }

  return (
    <OutputOnly id={ id } data={ data } className="import" inputsLabels={ ['prompt'] } handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <Box sx={ { width:'100%', pointerEvents: role === "guest"? 'none':'' } }>

        {((data && data.result) || previewImage) && !hasError ? (
          <>
            <Box sx={ { position:'relative' } }>
              <Box className='media-container' sx={ { width: "100%", height: "100%" } } onClick={ openFileUploader }>
                {renderMediaElement(previewImage, uploadedFileType)}
                {data.result && data.result.width && data.result.height &&
                    <Typography
                      variant="caption"
                      sx={ { position:'absolute', top:5, left:5 } }
                    >
                      {data.result.width} x {data.result.height}
                    </Typography>}
              </Box>
              {uploadProgress > 0 && uploadProgress < 100  &&
            <Box sx={ { width:'100%', position:'absolute', top:0 } } id='upload-progress-container'><LinearProgress variant="determinate" value={ uploadProgress } /></Box>}
            </Box>
          </>
        ) : (
          <Box id="upload-container" sx={ { textAlign:'center' } }>
            <Box
              { ...getRootProps() }
              sx={ { cursor: "pointer", height:'60px', border:'1px dashed', borderColor:color.Yambo_Blue_Stroke, display:'flex', alignItems:'center', justifyContent:'center', mb:1 } }
            >
              <input { ...getInputProps() } />
              <Typography variant="body">Drag & Drop or Click to Upload</Typography>
            </Box>
            <Box sx={ { mb:1 } }>
              <Typography variant="caption">Or</Typography>
            </Box>
            <OutlinedInput
              className={ isFocused ? "nowheel nodrag nopan": "" }
              onFocus={ () => {setIsFocused(true);} }
              onBlur={ () => {setIsFocused(false);} }
              type="text"
              placeholder="Paste a file link"
              value={ fileLink }
              onChange={ handleLinkChange }
              fullWidth
              size='small'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="upload-from-link"
                    onClick={ () => handleLinkInput(fileLink) }
                    edge="end"
                  >
                    <UploadIcon />
                  </IconButton>
                </InputAdornment> }
            />
          </Box>
        )}
      </Box>
      <input
        id={ `file-upload-input-${id}` }
        type="file"
        style={ { display: "none" } }
        { ...getInputProps() }
      />
      {hasError && <Box sx={ { mt:1 } }>Oops... Looks like upload error occured. Please try again.</Box>}
    </OutputOnly>


  );
}

export default ImportCloudinaryNode;
