import { Box, MenuItem, Select, FormControl, InputLabel , IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from "three"; // Import the Three.js library
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Html } from '@react-three/drei';

const arrowStyle = {
  marginTop:'5px',
};

let mainModel;

export function normalizeModel(model, scaleFactor) {
  // Calculate the size of the model
    
  const box = new THREE.Box3().setFromObject(model);
  const size = new THREE.Vector3();
  box.getSize(size);
    
  const maxDimension = Math.max(size.x, size.y, size.z);
  
  // Scale the model to fit within the desired scale factor
  const scale = scaleFactor / maxDimension;
  model.scale.set(scale, scale, scale);
    
  // Calculate the center of the bounding box
  const box2 = new THREE.Box3().setFromObject(model);
  const center = new THREE.Vector3();
  const size2 = new THREE.Vector3();
  box2.getSize(size2);

  box2.getCenter(center);
     
  // Translate the model to center it at the origin (0,0,0)
  model.position.sub(center);
  // Adjust the model position so its bottom aligns with y=0
  const height = size2.y;

  // Adjust the position to align the bottom of the model with y = 0
  model.position.y += height / 2;

 
    
  
  return model;
}

  

export function StraightenModel(){

  const [axis, setAxis] = useState('x');
    
    
  const straightenModel = (direction) =>{
    const angle = direction * Math.PI/2; // rotate -90 degrees (Z to Y)
    switch (axis){
      case 'x':
        mainModel.rotateX(angle);
      case 'y':
        mainModel.rotateY(angle);
      case 'z':
        mainModel.rotateZ(angle);
      default:
        return;
    }
      
  };

  const resetModel = () => {

  };

  const handleChange = (event) => {
    setAxis(event.target.value);
  };
    
  const handleLeftClick = () => {
    straightenModel(-1);
  };
  const handleRightClick = () => {
    straightenModel(1);
  };
  
  return (
    <Box sx={ { display:'flex', flexDirection:'column', mt:2, justifyContent:'center', alignItems:'center' } }>
      <Box>
          Fix model orientation
      </Box>
      <Box>
        <IconButton sx={ { mt:2 } } onClick={ handleLeftClick }>
          <ArrowLeftIcon fontSize='large' />
        </IconButton>
        <FormControl variant="standard" sx={ { m: 1, minWidth: 60 } }>
          <InputLabel id="axis-dropdown-label">Axis</InputLabel>
          <Select
            labelId="model-straighten"
            id="model-straighten"
            value={ axis }
            onChange={ handleChange }
            label="Axis"
          >
            <MenuItem value={ 'x' }>X</MenuItem>
            <MenuItem value={ 'y' }>Y</MenuItem>
            <MenuItem value={ 'z' }>Z</MenuItem>
          </Select>
        </FormControl>
        <IconButton sx={ { mt:2 } } onClick={ handleRightClick }>
          <ArrowRightIcon fontSize='large' />
        </IconButton>
      </Box>
    </Box>

  );
}




export const Model= ({ objUrl, type }) => {
  const [obj, setObj] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (objUrl) {
      setLoading(true);
      let loader = null;
      if(type===2){
        loader = new OBJLoader();
      }
      else if(type===3){
        loader = new FBXLoader();
      }
      else if(type===4){
        loader = new GLTFLoader();
      }
      loader.load(
        objUrl,
        (loadedObj) => {
          if(type===4)
            setObj(loadedObj.scene);
          else setObj(loadedObj);
          setLoading(false);
        },
        undefined,
        (error) => {
          console.error("Error loading OBJ file:", error);
        },
      );
    }
  }, [objUrl]);

  const material = new THREE.MeshStandardMaterial({
    color:  new THREE.Color(.5,.5, .5),
    // roughness: 0.2,
    // metalness: 0.8,
  });

  // Apply the material to each mesh in the object's children
  useEffect(() => {
    if (obj) {
      if(type!=4){
        obj.children.forEach((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = material;
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
      
      }
  

      normalizeModel(obj,4.5);
    }
  }, [obj]);

  useEffect(() => {
    if (obj) {
      if(type===4){
      
        obj.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            if(!child.material)
              child.material = material;
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
      }
    }
  }, [obj]);

  useEffect(()=>{
    if (obj){
      mainModel = obj;
    }
  },[obj]);
  
  if (loading) {
    return <ModelLoader />; // Show loader when the object is loading
  }
  if (obj){
    return(
      <primitive receiveShadow castShadow object={ obj } />
    );
  }
  else return null;
  // return obj ? <primitive receiveShadow castShadow object={obj} />: null;
};



export function ModelLoader() {
  return (
    <Html center>
      <div>Loading model...</div>
    </Html>
  );
}