import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { color } from '../../../colors';
import { Rnd } from "./rnd";


const itemsPerRow = 3;
const middleBoxIndex = 4;
const totalItems = 9;
const gridColor = color.Yambo_Green_Stroke;
//
// function validateProperties(obj) {
//   // List of properties to check
//   const properties = ['height', 'width', 'x', 'y'];
//
//   // Check each property in the list
//   for (let prop of properties) {
//     const value = obj[prop];
//
//     // Check if the value is NaN, null, or undefined
//     if (value === null || value === undefined || Number.isNaN(value)) {
//       return false; // Return false if any property does not pass the check
//     }
//   }
//
//   return true; // Return true if all properties pass the check
// }

export function CropHandle ({ className }) {
  return (
    <Box className={ `handle ${className}` } sx={ { width:10, height:10, background:color.Light_Green } }>
            
    </Box>
  );
}

const CropOverlay = ({ isNew , lockAspectRatio, container, cropBox , cropDimensions ,setCropDimensions, onCropEnd,  shouldReset, setShouldReset }) =>  {

  const [cropArea, setCropArea] = useState({ x: 0, y: 0, width: '100%', height: '100%' });



  useEffect(()=>{
    let newCropArea;
    if(!isNew){
      if(cropBox)
        newCropArea = {
          x: cropBox.x,
          y: cropBox.y,
          width: cropBox.width,
          height: cropBox.height,
        };
    }
    else newCropArea = {
      x: 0,
      y: 0,
      width: container.width,
      height: container.height,
    };
    setCropArea(newCropArea);
    onCropEnd(newCropArea);
  },[container]);


  //// handle reset
  useEffect(()=>{
    if(!shouldReset)
      return;
    const newCropArea = {
      x: 0,
      y: 0,
      width: container.width,
      height: container.height,
    };
    setCropArea(newCropArea);
    onCropEnd(newCropArea);
    setShouldReset(false);
  }, [shouldReset]);
  
  // useEffect(()=>{
  //   console.log("CropArea: ", cropArea)
  // },[cropArea])

  // useEffect(()=>{
  //   if(cropArea.width && cropArea.width > 0 && cropArea.height && cropArea.height > 0 )
  //   onCropEnd(cropArea);
  // },[cropArea])

  const handleResizeStop = (e, direction, ref, delta, position) => {
    const newCropArea = {
      width: parseInt(ref.style.width),
      height: parseInt(ref.style.height),
      x: position.x,
      y: position.y,
    };
    setCropArea((prev) => ({
      ...prev,
      ...newCropArea,
    }));
    onCropEnd(newCropArea);
  };

  const handleDragStop = (e, d) => {
    const newCropArea = {
      ...cropArea,
      x: d.x,
      y: d.y,
    };
    setCropArea((prev) => ({ ...prev, ...newCropArea }));
    onCropEnd(newCropArea);
  };
  const handleResize = (e, direction, ref, delta, position) => {
    setCropDimensions({
      width: parseInt(ref.style.width),
      height: parseInt(ref.style.height),
      x: position.x,
      y: position.y,
    });
  };

  const handleCropAreaMove = (e, d) => {
    setCropDimensions({
      ...cropDimensions,
      x: d.x,
      y: d.y,
    });
  };

  useEffect(()=>{
    setCropDimensions(cropArea);
  },[cropArea]);

  return (
    <Rnd
      size={ { width: cropArea.width,  height:cropArea.height } }
      position={ { x: cropArea.x,  y:cropArea.y } }
      style={ { display:'flex' } }
      onResize={ (e, direction, ref, delta, position) => handleResize(e, direction, ref, delta, position) }
      onDrag={ handleCropAreaMove }
      onResizeStop={ (e, direction, ref, delta, position) => handleResizeStop(e, direction, ref, delta, position) }
      lockAspectRatio={ lockAspectRatio }
      bounds="parent"
      onDragStop={ handleDragStop }
      maxHeight={ container.height }
      maxWidth={ container.width }
      resizeHandleComponent={ {
        topRight:<CropHandle className={ 'ne' } />,
        topLeft: <CropHandle className={ 'nw' } />,
        bottomRight: <CropHandle className={ 'se' } />,
        bottomLeft: <CropHandle className={ 'sw' } />,

      } }
    >
      <Grid container spacing={ 0 } sx={ { width: '100%', height: '100%' } }>
        {Array.from({ length: totalItems }).map((_, index) => (
          <Grid item xs={ 4 } key={ index } sx={ {} }>
            <>
              <Box
                sx={ {
                  borderTop: `2px dashed ${gridColor}`,
                  borderLeft: `2px dashed ${gridColor}`,
                  borderRight: index % (itemsPerRow) === 2 ? `2px dashed ${gridColor}` :'none',
                  borderBottom: index >= 6 ?  `2px dashed ${gridColor}`:'none',
                  width: '100%',
                  height: '100%',
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                } }
              >
                {index === middleBoxIndex && (
                  <AddIcon sx={ { color:gridColor } } />
                )}
              </Box>
                
            </>
          </Grid>
        ))}
      </Grid>
    </Rnd>
  );
};

export default CropOverlay;
