import React, { useState, useRef, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Typography, ButtonBase, Menu, MenuItem, Divider, Tooltip } from '@mui/material';
import { useUserRole } from '../Recipe/UserRoleContext';
import { color } from '../../colors';

export const OutputOnly = ({ id, data, children, className, handleColor, headerColor }) => {
  const { handles } = data;

  const role = useUserRole();

  return (
    <>
      <Box className={ `custom-node output-only ${className}` } sx={ { cursor: role === "guest" ? 'default': '' } }>
        <Box className="node-header" sx={ { width:'100%', background:headerColor, flexDirection:'row', display:'flex' } }>
          <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
          <Typography variant='body1' sx={ { ml:1 } }>{data.name}</Typography>
        </Box>
        <Box className="node-content" sx={ { width:'100%' } }>
          {/* <Box>{id}</Box> */}
          {children}
        </Box>
        <Handle type="source"
          style={ {
            top:'18px',
            background:handleColor,
            cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
            pointerEvents: role === 'guest'  ? 'none' : 'auto',
          } } position={ Position.Right } id={ `${id}-output-${handles.output[0]}` }
        >
          <Box sx={ { position:'relative',left:30, top:-20 } }>
            {handles.output[0]}
          </Box>
                
        </Handle>
      </Box>
    </>
  );
};

export const InputOnly = ({ id,  data, children, className,handleColor , headerColor }) => {
    
  return (
    <Box className={ `custom-node input-only ${className}` }>
            
      <Box className="node-header" sx={ { width:'100%', background:headerColor, flexDirection:'row', display:'flex' } }>
        <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
        <Typography variant='body1' sx={ { ml:1 } }>{data.name}</Typography>
      </Box>
      <Box className="node-content" sx={ { width:'100%' } }>
        {children}
      </Box>
      <Handle type="target" style={ { top:'18px',background:handleColor } } position={ Position.Left } id={ `${id}-input0` } />
    </Box>
  );
};


export const DynamicNode = ({ id, data, children, className, handleColor, hideTitle }) => {

  const { handles } = data;
  const handlesSpace = 50;
  const handleTop = '40px';

  const role = useUserRole();

  return (
    <Box className={ `custom-node two-input-output ${className}` } sx={ { cursor: role === "guest" ? 'default' : '' } }>
      {!hideTitle && 
      <>
      <Box>{data.name}</Box>
        {data.paid && 
          <Typography>paid</Typography>
        }
      </>
      }
      {/* <Box><Typography>{id}</Typography></Box> */}
      {children}
      {handles.input.map((inputHandle, index)=>(
        <Handle
          key={ index }
          type="target"
          position={ Position.Left }
          style={ {
            top:`calc(${handleTop} + ${index}*${handlesSpace}px)`
            , backgroundColor:handleColor } }
          id={ `${id}-input-${inputHandle}` }
        >
          <Box sx={ { position:'relative',left:-40, top:-20 } }>
            {inputHandle}
          </Box>
        </Handle>
      ))}
      {handles.output.map((outputHandle, index)=>(
        <Handle
          key={ index }
          type="source"
          position={ Position.Right }
          style={ {
            top:`calc(${handleTop} + ${index}*${handlesSpace}px)`
            , backgroundColor:handleColor } }
          id={ `${id}-output-${outputHandle}` }
        >
          <Box sx={ { position:'relative',left:30, top:-20 } }>
            {outputHandle}
          </Box>
        </Handle>
      ))}
            
    </Box>
  );
};

const DynamicHandleLabel = ({ handleType, handlePosition, handleTop, handleSpace, handleColor, handleId, label, index, required, description, requiredValidationError}) => {
  const textRef = useRef(null);
  const [labelLeft, setLabelLeft] = useState(-40);

  const role = useUserRole();

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.offsetWidth;
      setLabelLeft(-width);
    }
  }, [label]); // Recalculate if label changes

  const stylesHandleLabel = (labelToHandle) => {
    return labelToHandle.replace('_',' ');
  };

  return (
    <Handle
      type={ handleType }
      position={ handlePosition }
      style={ {
        top: `calc(${handleTop} + ${index * handleSpace}px)`,
        backgroundColor: handleColor,
        cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
        pointerEvents: role === 'guest'  ? 'none' : 'auto',
      } }
      id={ handleId }
    >
      {handlePosition === 'left' ?
        (
        <Tooltip  title={ description } placement='right-start' enterDelay={800}>
          <Box sx={ { position: 'relative', left: labelLeft, top: -20, width:'fit-content' } } ref={ textRef }>
            <span style={ { textTransform:'capitalize', whiteSpace:'nowrap', fontWeight:'bold', color:requiredValidationError? color.Yambo_Orange:'' } }>
              {stylesHandleLabel(label)}
              </span>
              {
                required && 
                <span style={ { fontStyle:'italic',  } }>
                  &nbsp;[required]
                </span>
              }
          </Box>
        </Tooltip> 
        ):(
          <Box sx={ { position: 'relative', left: 15, top: -20, width:'fit-content' } } ref={ textRef }>
            <span style={ { textTransform:'capitalize', whiteSpace:'nowrap' } }>{stylesHandleLabel(label)}</span>
          </Box>
        )}
    </Handle>
  );
};

export const DynamicNode2 = ({ id, data, children, className, handleColor, headerColor, hideTitle, hideBody, icon ,handleYPos ,menu, validationError}) => {

  const role = useUserRole();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
    
  const { handles, version } = data;
  const handlesSpace = 50;
  const handleTop = handleYPos || '18px';
  
  // sort handles by its order
  const sortedInputHandles = Object.entries(handles?.input || {}).sort(([, a], [, b]) => (a.order || 0) - (b.order || 0));

  return (
    <Box className={ `custom-node two-input-output ${className}` } sx={ { cursor: role === "guest" ? 'default' : '' } }>
      {!hideTitle &&
                <Box className="node-header"
                  sx={ {
                    overflow:'hidden',
                    width:'100%',
                    background:headerColor || '',
                    flexDirection:'row',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between',
                  } }
                >
                  <Box sx={ { flexDirection:'row',
                    display:'flex',alignItems:'center' } }
                  >
                    {icon ? (
                      icon
                    ):(
                      <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
                    )
                    }
                    <Typography variant='body1' sx={ { mx:1, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' } }>{data.name}</Typography>
              
                  </Box>
                  <Box>
                    {role === "guest" && <i className="fa-solid fa-lock fa-sm" style={ { opacity:.6 } }></i>}
                    {role === "editor" && menu && menu.length > 0 && <>
                      {data.paid && <>
                        {/* <i className="fa-solid fa-crown fa-xs" style={ {marginRight:'4px', color:color.Yellow } }></i> */}
                    <Typography
                      variant="caption"
                      sx={ { 
                        border:'1px solid',
                        borderColor:color.Yambo_Purple_Stroke,
                        py:.4,
                        px:1,
                        borderRadius:'8px',
                        fontSize:'10px',
                        fontWeight:'bold'
                      } }
                      >
                        Paid Model
                    </Typography>
                    
                    </>
                  }
                      <ButtonBase size='small' sx={ { px:1, py:1, mr:-1, my:-1 } } onClick={ (e)=>setAnchorEl(e.currentTarget) }>
                        <img src='/icons/ellipsis-vertical.svg' width='12px' style={ { opacity:.6 } } />
                      </ButtonBase>
                      <Menu
                        id="node-menu"
                        anchorEl={ anchorEl }
                        keepMounted
                        open={ open }
                        onClose={ ()=>setAnchorEl(null) }
                        anchorOrigin={ {
                          vertical: 'top',
                          horizontal: 'right',
                        } }
                        transformOrigin={ {
                          vertical: 'top',
                          horizontal: 'right',
                        } }
                      >
                        {menu.map((item, index)=> {
                          if (item.name === 'divider') {
                            return <Divider key={ index } />;
                          } else {
                            return (
                              <MenuItem
                                key={ index }
                                onClick={ ()=>{
                                  item.action();
                                  setAnchorEl(null);
                                } }
                                sx={ {
                                  justifyContent:'space-between',
                                  width:'240px',
                                } }
                                disabled={ item.disabled }
                              >
                                <Typography variant='caption'>{item.name}</Typography>
                                <Typography variant='caption' sx={ { fontStyle:'italic' } }>{item.shortcut}</Typography>
                              </MenuItem>
                            );}})}
                      </Menu>
                    </>
                    }
                  </Box>
                </Box>
      }
      {/* <Box><Typography>{id}</Typography></Box> */}
      {!hideBody && <Box className="node-content" sx={ { width:'100%' } }>
        {children}
      </Box>}
      { version === 2 ?
        sortedInputHandles.map(([key, inputHandle], index) => (
          <DynamicHandleLabel
            key={index}
            handleType="target"
            handlePosition={Position.Left}
            handleTop={handleTop}
            handleSpace={handlesSpace}
            handleColor={handleColor}
            handleId={`${id}-input-${key}`}
            label={key}
            index={index}
            required={inputHandle.required}
            description={inputHandle.description}
            requiredValidationError={validationError?.includes(key)}
          />
        ))
        : 
        handles?.input.map((inputHandle, index) => (
          <DynamicHandleLabel
            key={index}
            handleType="target"
            handlePosition={Position.Left}
            handleTop={handleTop}
            handleSpace={handlesSpace}
            handleColor={handleColor}
            handleId={`${id}-input-${inputHandle}`}
            label={inputHandle}
            index={index}
          />
        ))
      }
      {handles?.output.map((outputHandle, index)=>(
        <DynamicHandleLabel
          key={ index }
          handleType="source"
          handlePosition={ Position.Right }
          handleTop={ handleTop }
          handleSpace={ handlesSpace }
          handleColor={ handleColor }
          handleId={ `${id}-output-${outputHandle}` }
          label={ outputHandle }
          index={ index }
        />
      ))}
            
    </Box>
  );
};