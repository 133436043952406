import React, { useState, useEffect } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { Widget } from '@typeform/embed-react';
import { useNavigate } from 'react-router-dom';

function EarlyAccess() {
  const [activeStep] = useState(0);

  const [, setStepTransitions] = useState({ 0: { translateY: '0%', opacity: 1 }, 1: { translateY: '100%', opacity: 0 }, 2: { translateY: '100%', opacity: 0 } });

  const navigate = useNavigate();
  // const [form, setForm] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   company: '',
  // });
  // const [errors, setErrors] = useState({
  //   firstName: false,
  //   lastName: false,
  //   email: false,
  // });

  // const validateCurrentStep = () => {
  //   let isValid = true;
  //   let newErrors = { firstName: false, lastName: false, email: false };
  //
  //   if (activeStep === 0) {
  //     if (!form.firstName.trim()) {
  //       newErrors.firstName = true;
  //       isValid = false;
  //     }
  //     if (!form.lastName.trim()) {
  //       newErrors.lastName = true;
  //       isValid = false;
  //     }
  //   } else if (activeStep === 1) {
  //     if (!form.email.trim() || !/^\S+@\S+\.\S+$/.test(form.email)) {
  //       newErrors.email = true;
  //       isValid = false;
  //     }
  //   }
  //
  //   setErrors(newErrors);
  //
  //   return isValid;
  // };

  // const handleChange = (event) => {
  //   setForm({
  //     ...form,
  //     [event.target.name]: event.target.value,
  //   });
  //   // Optionally reset error state on change
  //   if (errors[event.target.name]) {
  //     setErrors({ ...errors, [event.target.name]: false });
  //   }
  // };

  const updateStepTransitions = (newActiveStep) => {
    const newTransitions = {};
    Array.from({ length: 3 }).forEach((_, index) => {
      if (index < newActiveStep) {
        newTransitions[index] = { translateY: '-100%', opacity: 0 }; // Previous
      } else if (index === newActiveStep) {
        newTransitions[index] = { translateY: '0', opacity: 1 }; // Current
      } else {
        newTransitions[index] = { translateY: '100%', opacity: 0 }; // Next
      }
    });
    setStepTransitions(newTransitions);
  };

  // const handleNext = () => {
  //   if (validateCurrentStep() && activeStep < 2) {
  //     const newStep = activeStep + 1;
  //     updateStepTransitions(newStep);
  //     setActiveStep(newStep);
  //   }
  // };

  // const handleBack = () => {
  //   if (activeStep > 0) {
  //     const newStep = activeStep - 1;
  //     updateStepTransitions(newStep);
  //     setActiveStep(newStep);
  //   }
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log('Submitted form:', form);
  //   // Submit form logic here
  // };
  useEffect(() => {
    updateStepTransitions(activeStep); // Initialize transitions on mount
  }, []);

  // const stepContent = (step) => {
  //   switch (step) {
  //     case 0:
  //       return (
  //         <Box sx={ { width:'100%' } }>
  //           <Typography variant="h5">Welcome to Weavy! 👋</Typography><br />
  //           <Box sx={ { mb:1 } }>
  //             <Typography variant="body" >First things first, what's <b>your name?</b></Typography>
  //           </Box>
  //           <TextField
  //             required
  //             fullWidth
  //             size='small'
  //             variant='standard'
  //             name="firstName"
  //             label="First Name"
  //             error={ errors.firstName }
  //             value={ form.firstName }
  //             onChange={ handleChange }
  //             sx={ { mb: 2 } }
  //           />
  //           <TextField
  //             required
  //             fullWidth
  //             size='small'
  //             variant='standard'
  //             name="lastName"
  //             label="Last Name"
  //             value={ form.lastName }
  //             error={ errors.lastName }
  //             onChange={ handleChange }
  //             sx={ { mb: 4 } }
  //           />
  //           <Button variant="outlined" onClick={ handleNext }>
  //                           Ok &nbsp; <i className="fa-solid fa-check fa-sm"></i>
  //           </Button>
  //         </Box>
  //       );
  //     case 1:
  //       return (
  //         <Box sx={ { width:'100%' } }>
  //           <Typography variant="h5">Thank you {form.firstName}!</Typography><br />
  //           <Box sx={ { mb:1 } }>
  //             <Typography variant="body" >And what's your <b>work email?</b></Typography>
  //           </Box>
  //           <TextField
  //             required
  //             fullWidth
  //             size='small'
  //             variant='standard'
  //             name="email"
  //             label="Email"
  //             value={ form.email }
  //             onChange={ handleChange }
  //             error={ errors.email }
  //             sx={ { mb: 4 } }
  //           />
  //           <Link fontSize='.7rem' onClick={ handleBack }>Back</Link>
  //           <Button variant="outlined" onClick={ handleNext } sx={ { ml: 2 } }>Looks good</Button>
  //         </Box>
  //       );
  //     case 2:
  //       return (
  //         <Box>
  //           <Typography variant="h5">And lastly...</Typography><br />
  //           <Box sx={ { mb:1 } }>
  //             <Typography variant="body" >What's the name of your <b>company?</b></Typography>
  //           </Box>
  //           <TextField
  //             fullWidth
  //             size='small'
  //             variant='standard'
  //             name="company"
  //             label="Company"
  //             value={ form.company }
  //             onChange={ handleChange }
  //             sx={ { mb: 4 } }
  //           />
  //           <Link onClick={ handleBack } fontSize='.7rem'>Back</Link>
  //           <Button variant="outlined" onClick={ handleSubmit } sx={ { ml: 2 } }>&#128640; Sign me up for early access &#128640;</Button>
  //         </Box>
  //       );
  //     default:
  //       return <Box />;
  //   }
  // };

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Box sx={ { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', position:"relative" } }>
      {/* <Box sx={{ width:'50%',height:'100%' ,background:'white'}}></Box>
            <Box id='form-container' sx={{ width: '50%', height:'100%', position:'relative'}}>
                {Array.from({ length: 3 }).map((_, index) => (
                         <Box
                         key={index}
                         className="early-access-form-step"
                         sx={{
                            transform: `translateY(${stepTransitions[index].translateY})`,
                            opacity: stepTransitions[index].opacity,
                            transition: 'transform 0.4s, opacity 0.2s',
                            }}
                            >
                            {stepContent(index)}
                        </Box>
                ))}
            </Box> */}
           
      <Widget id="p4bKeVni" style={ { width:'100%', height:'100%' } } className="my-form" />
      <Box id='hero-header' sx={ { width:'100%', p:2, position:'absolute', top:0 } }>
        <ButtonBase onClick={ goToHomepage }>
          <img src="/logo.svg" alt="" width='30px' />
        </ButtonBase>
      </Box>
    </Box>
  );
}

export default EarlyAccess;
