import React from "react";
import { colorMap } from '../../colors';
import { DynamicNode2 } from './VisualNodes';
import ImportModelCore from "./ImportModelCore";

function ImportNodeComponent ({ id, data ,updateNodeData, setNodesTypes, editable }) {
  return(
    <DynamicNode2 id={ id } data={ data } className="model" handleColor={ colorMap.get(data.color) } >
      <ImportModelCore id={ id } data={ data } updateNodeData={ updateNodeData } setNodesTypes={ setNodesTypes } editable={ editable } />
    </DynamicNode2>
  );
}

export default ImportNodeComponent;
