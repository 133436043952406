/* eslint-disable no-template-curly-in-string */
import KEYS from './keys';

const EN_TEXT = {
  [KEYS.PAYMENTS.SUCCESS_MESSAGE]: '🎉&nbsp;Credits charged!&nbsp;🎉',
  [KEYS.NAVIGATION_DRAWER.BUY_MORE]: 'Buy More',
  [KEYS.DASHBOARD_BANNER.MESSAGE]: '🚀 🎥 Luma Dream Machine is now officially on Weavy 🎥 🚀',
  [KEYS.DASHBOARD_BANNER.CTA]: 'Check it out!',
  [KEYS.UPGRADE_MODAL.TITLE]: 'Buy more credits for Runway and Luma',
  [KEYS.UPGRADE_MODAL.CTA]: 'Learn more...',
  [KEYS.MODEL_NODE.ERROR_REQUIRED]: 'Missing inputs: ',
  [KEYS.MODEL_NODE.ERROR_IMAGE_INPUT]: 'Cannot read input image',
  [KEYS.RECIPE_MAIN.ERROR_SAVE]: 'Unable to save your work. Please contact support',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.SHOW_INFO]: 'Show Info',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.HIDE_INFO]: 'Hide Info',
  [KEYS.UPLOAD_LORA_NODE.UPLOAD_BUTTON_TEXT]: 'Click to Upload',
  [KEYS.UPLOAD_LORA_NODE.UPLOADING]: 'Uploading...',
  [KEYS.SHARED_DESIGN_APP.MODAL.DEFAULT_ERROR]: 'Failed to generate share link. Please try again.',
  [KEYS.SHARED_DESIGN_APP.MODAL.TITLE]: 'Share Design App',
  [KEYS.SHARED_DESIGN_APP.MODAL.LINK_COPIED]:'Link Copied!',
  [KEYS.SHARED_DESIGN_APP.MODAL.BUTTON_CTA]: 'Generate one-time link',
  [KEYS.FLOW_NAVBAR.SHARED_DESIGN_APP_BUTTON]: 'Share Design App',
  [KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.DEFAULT_ERROR]: 'Invalid or expired link. Please contact the sender for a new link.',
  [KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.TITLE]: 'Activating recipe, please wait...',
  [KEYS.MAIN_DASHBOARD.DESIGN_APPS.TITLE]: 'Shared with me',
};

export default EN_TEXT;
