import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { color } from "../../colors";

function ShowCaseRecipesList({ recipes }) {

  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(true);
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const scrollContainerRef = useRef(null);


  const goToRecipe = (recipeId) => {
    const url = `/recipe/${recipeId}`;
    window.open(url, '_blank');
  };

  const updateScrollArrows = () => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current;
      const isScrollable = scrollWidth > clientWidth;
      const isAtStart = scrollLeft <= 1; // Tolerance for minor discrepancies
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 1;

      setShowLeftScrollArrow(isScrollable && !isAtStart);
      setShowRightScrollArrow(isScrollable && !isAtEnd);
    }
  };

  useEffect(() => {
    const handleScroll = () =>  updateScrollArrows();

    const handleWheel = (event) => {
      if (scrollContainerRef.current) {
        if (event.deltaX !== 0) {
          event.preventDefault();
          scrollContainerRef.current.scrollLeft += event.deltaX;
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      scrollContainer.addEventListener('wheel', handleWheel);
      setTimeout(updateScrollArrows, 100);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 400, behavior: 'smooth' });
    }
  };
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    updateScrollArrows();
  }
  , [recipes]);

  return (
    <Box sx={ { position:'relative', width: '100%' } }>
      <Typography>Get Inspired by Top Artists</Typography>
      <Box ref={ scrollContainerRef } sx={ { overflowX: 'auto', whiteSpace: 'nowrap', my: 2, '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' } }>
        <Box sx={ { display: 'inline-flex', gap: 2 } }>
          {recipes && recipes.map((recipe) => {
            return (
              <Card key={ recipe.id }
                sx={ {
                  width:'200px',
                  backgroundColor:'transparent',
                  backgroundImage:'none',
                              
                } } onClick={ () => goToRecipe(recipe.id) }
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="100px"
                    image={ recipe.poster || '/empty.png' }
                    alt="recipe poster"
                    sx={ {
                      transition: 'transform 0.12s ease-out',
                      border:'1px solid',
                      borderColor:color.Yambo_Black,
                      borderRadius:2,
                    } }
                  />
                </CardActionArea>
                <CardContent sx={ { backgroundColor:'transparent', p:0, pl:0, pt:1 } }>
                  <Typography variant="caption">{recipe.name}</Typography>
                </CardContent>
                           
              </Card>
            );
          })}
        </Box>
        <IconButton onClick={ scrollRight }
          sx={ {
            backgroundColor:color.Yambo_Black,
            position: 'absolute',
            right: 0,
            top: '42%',
            transform: 'translateY(-50%)',
            transition:'opacity 0.1s',
            opacity: showRightScrollArrow ? .7 : 0,
          } }
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <IconButton onClick={ scrollLeft }
          sx={ {
            backgroundColor:color.Yambo_Black,
            position: 'absolute',
            left: 0,
            top: '42%',
            transform: 'translateY(-50%)',
            transition:'opacity 0.1s',
            opacity: showLeftScrollArrow ? .7 : 0,
          } }
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      </Box>
      <Divider />
    </Box>
  );
}

export default ShowCaseRecipesList;