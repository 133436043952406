import React, { useContext, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Footer from './components/layout/Footer';
import { color } from './colors.js';
import { Dashboard, Home, Recipe, AdminConsole } from './pages';
import './fonts.css';
import { AuthContext } from './AuthContext';
import { CreditsProvider } from "./services/CreditsContext";
import EarlyAccess from './components/Homepage/EarlyAccess.jsx';
import TempSignin from './components/Homepage/TempSignin.jsx';
import PaymentSuccessHandler from './components/SubscriptionsAndPayments/PaymentSuccessHandler';
import DesignAppShareActivationPage from './pages/DesignAppShareActivation.page';
import ProtectedRoutes from './ProtectedRoutes';

const usePreventZoom = () => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    const handleGesture = (event) => {
      event.preventDefault();
    };

    // Add event listeners
    document.addEventListener('wheel', handleWheel, { passive: false });
    document.addEventListener('gesturestart', handleGesture, { passive: false });
    document.addEventListener('gesturechange', handleGesture, { passive: false });

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('gesturestart', handleGesture);
      document.removeEventListener('gesturechange', handleGesture);
    };
  }, []); // Empty dependency array as we only want to set this up once
};

const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          color: ${color.Yambo_Text_On_Dark} !important;
          background:#101012;
        }
        body {
          color: ${color.Yambo_Text_On_Dark} !important;
        }
        .MuiButtonBase-root, .MuiButton-root {
          color: ${color.Yambo_Text_On_Dark} ;
        }
        .MuiTypography-root {
          color: ${color.Yambo_Text_On_Dark} !important;
        }
        .MuiTabs-indicator {
          background-color: ${color.Yambo_Purple} !important;
        }
      `,
    },
  },
  palette: {
    mode: 'dark',
    weavy_cta: {
      main: color.Yambo_Purple,
      light: color.Yambo_Green_Stroke,
      dark: color.Yambo_Purple_Stroke,
      contrastText: color.Yambo_Text_On_Dark,
    },
    weavy_cta_secondary: {
      main: color.Yambo_CTA_BG,
      light: color.Yambo_Text_On_Dark,
      dark: color.Yambo_White_BG,
      contrastText: color.Yambo_Text_On_White,
    },
    weavy_cta_blue: {
      main: color.Yambo_Blue,
      light: color.Yambo_Blue_Stroke,
      dark: color.Yambo_Blue_Stroke,
      contrastText: color.Yambo_Text_On_Dark,
    },
    success: {
      main: color.Yambo_Purple,
    },
    success_secondary: {
      main: color.Yambo_Purple_Dark,
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    caption:{ fontWeight:200 },
    body1:{ fontSize:'0.875rem', fontWeight:400, lineHeight:1.2 },
    body2:{ fontSize:'0.875rem', fontWeight:700 },
    h2:{ fontSize:'1rem', fontWeight:700 },
    button: { textTransform:'none' },
    link: { fontSize: '0.875rem' },
  },
});

function App() {
  usePreventZoom();

  const posthog = usePostHog();
  const { currentUser, signIn, isSigningIn } = useContext(AuthContext);
  // const { credits } = useContext(CreditsContext);

  // useEffect(() => {
  //   console.log("credits", credits);
  // }, [credits]);

  /// idetify user for posthog
  useEffect(() => {
    if (currentUser) {
      posthog.identify(
        currentUser.uid,
        { email: currentUser.email, name: currentUser.displayName },
      );
    }
  },[posthog, currentUser]);

  return (
    <ThemeProvider theme={ darkTheme }>
      <CssBaseline />
      <CreditsProvider>
        {currentUser && <PaymentSuccessHandler />}
        <Box sx={ { display: 'flex', flexDirection: 'column', height: '100vh' } } id="weavy-main">
          <Box component="main" sx={ { flexGrow: 1, overflow: 'auto' } }>
            <Routes>
              {/* Protected Routes */}
              <Route element={ <ProtectedRoutes user={ currentUser } /> }>
                <Route path="/design-app/share/activate" element={ <DesignAppShareActivationPage user={ currentUser } /> } />
                <Route path="/recipe/:recipeId" element={ <Recipe user={ currentUser } /> } />
              </Route>

              <Route path="/early" element={ <EarlyAccess  /> } />
              <Route path="/dlpwQTohM9hc" element={ <TempSignin  signIn={ signIn } /> } />
              <Route path="/signin" element={ <TempSignin  signIn={ signIn } /> } />
              <Route path="/" element={
                currentUser && !isSigningIn ?
                  <Dashboard user={ currentUser } /> :
                  <Home user={ currentUser } signIn={ signIn } /> }
              />
              {currentUser && currentUser.isAdmin && <Route path="/admin" element={ <AdminConsole /> } />}
              <Route path="*" element={ <Navigate to="/" replace /> } />
            </Routes>
          </Box>

        </Box>
      </CreditsProvider>
      {!currentUser && <Footer />}
    </ThemeProvider>
  );
}

export default App;
