import { fabric } from "fabric";

export const imageSerialization = () => {
  fabric.Image.prototype.toObject = (function(toObject) {
    return function(propertiesToInclude) {
      propertiesToInclude = (propertiesToInclude || []).concat(["customId"]); // Include 'customId' in serialization
      var obj = toObject.apply(this, [propertiesToInclude]);
      delete obj.src;
      
      return obj;
    };
  })(fabric.Image.prototype.toObject);
};


export const backgroundSerialization = () => {
  fabric.Canvas.prototype.toJSON = (function(toJSON) {
    return function(propertiesToInclude) {
      var json = toJSON.apply(this, [propertiesToInclude]);
      if(json.backgroundImage) {
        // Assuming you want to keep all other properties of the background image except 'src'
        delete json.backgroundImage.src;
      }
      
      return json;
    };
  })(fabric.Canvas.prototype.toJSON);
};

export const replaceImageAndCopyAttributes = (canvas, oldImage, newSrc) => {
  const imageJson = oldImage.toJSON(["src"]);
  delete imageJson.src; // Ensure 'src' is not included

  fabric.Image.fromURL(newSrc, (newImage) => {
    // Apply the serialized properties to the new image, excluding 'src'
    newImage.setOptions(imageJson);

    // Ensure new image is added and old one is removed
    canvas.add(newImage);
    canvas.remove(oldImage);

    canvas.renderAll();
  }, { crossOrigin: "anonymous" });
};

export const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};