import React from 'react';
import { getBezierPath, getMarkerEnd } from 'reactflow';
import { colorMap } from '../../colors';
import { useUserRole } from './UserRoleContext';

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  selected,
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const gradientId = `gradient-${id}`;

  const rectWidth = 10;
  const rectHeight = 10;
  const role = useUserRole();
  const pointerEvents = role === 'guest' ? 'none' : 'auto';
  const cursor = role === 'guest' ? 'default' : 'pointer';

  const clickStyle = {
    strokeWidth: 20,
    stroke: 'transparent',
    cursor: role === 'guest' ? 'default' : 'pointer', // Disable pointer for guests
    pointerEvents: role === 'guest'  ? 'none' : 'auto',
  };

  return (
    <>
      <defs>
        <linearGradient id={ gradientId } gradientUnits="userSpaceOnUse" x1={ sourceX } y1={ sourceY } x2={ targetX } y2={ targetY }>
          <stop offset="0%" stopColor={ colorMap.get(data.sourceColor) } />
          <stop offset="100%" stopColor={ colorMap.get(data.targetColor) } />
        </linearGradient>
      </defs>
      <path
        d={ edgePath }
        style={ clickStyle }
        fill='none'
      />
      
      <path
        id={ id }
        style={ { ...style,
          strokeWidth: selected? 3:1,
          cursor,
          pointerEvents,
        } }
        d={ edgePath }
        markerEnd={ markerEnd }
        stroke={ `url(#${gradientId})` }
        fill='none'
      />
      <rect
        style={ { cursor, pointerEvents } }
        width={ rectWidth }
        height={ rectHeight }
        fill={ colorMap.get(data.targetColor) } // Use the target color or any color you prefer
        transform={ `translate(${targetX - rectWidth / 2 -5}, ${targetY - rectHeight / 2})` } // Center the rectangle on the edge end
      />
      <rect
        style={ { cursor, pointerEvents } }
        width={ rectWidth }
        height={ rectHeight }
        fill={ colorMap.get(data.sourceColor) } // Use the target color or any color you prefer
        transform={ `translate(${sourceX - rectWidth / 2 + 5}, ${sourceY - rectHeight / 2})` }
      />
    </>
  );
};

export default CustomEdge;