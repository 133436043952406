import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { colorMap } from "../../colors";
import { OutputOnly } from './VisualNodes';


function PromptNode({ id, data, updateNodeData }) {

  // const textFieldRef = useRef(null);

  const [prompt, setPrompt] = useState(data.result.prompt);
  const [negativePrompt, setNegativePrompt] = useState(data.result.negative_prompt);


  const onPromptChange = (event) => {
    const updatedPrompt = event.target.value;
    setPrompt(updatedPrompt);
  };
  const onNegativePromptChange = (event) => {
    const updatedNegativePrompt = event.target.value;
    setNegativePrompt(updatedNegativePrompt);
  };

  useEffect(()=>{
    updateNodeData(id, {
      result:{
        prompt:prompt,
        negative_prompt:negativePrompt,
      },
      output:
          {
            type:"text",
            prompt: [
              {
                prompt:prompt,
                negative_prompt:negativePrompt,
              },
            ],
          },
    },
    );
  },[prompt, negativePrompt]);


  return (
    <OutputOnly id={ id } data={ data } className="prompt" handleColor={ colorMap.get(data.color) } backgroundColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'column',
          alignItems: "flex-end",
          width:'100%',
          mt:1,
        } }
      >
        <TextField
          className="nowheel nodrag nopan"
          value={ prompt }
          onChange={ onPromptChange }
          label="Prompt"
          multiline
          rows={ 3 }
          fullWidth
          size="small"
          onMouseDown={ (event) => event.stopPropagation() }
        />
        <TextField
          className="nowheel nodrag nopan"
          sx={ { mt:2 } }
          label="Negative Prompt"
          value={ negativePrompt }
          onChange={ onNegativePromptChange }

          multiline
          rows={ 3 }
          fullWidth
          size="small"
          onMouseDown={ (event) => event.stopPropagation() }
        />
      </Box>
    </OutputOnly>
  );
}

export default PromptNode;
