import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { Typography, Box } from "@mui/material";
import { useUpdateNodeInternals } from 'reactflow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import LoadingButton from '@mui/lab/LoadingButton';
import lodash from 'lodash';
import { useUserRole } from '../Recipe/UserRoleContext';
import ModelRunContext from '../Recipe/RunFlow/ModelRunContext';
import axiosInstance from "../../services/axiosConfig";
import { CreditsContext } from "../../services/CreditsContext";
import { runComfyWorkflow } from "./RunModel";
import { renderDynamicField } from "./Utils";
import { SaveComfyNode } from "./SaveCustomNode";
import NodeImageList from "../Recipe/FlowComponents/NodeImageList";

let isRunning = new Set();
  
function ComfyCore ({ id, data, setNodesTypes ,updateNodeData, selectedOutput, setSelectedOutput, setOutput, container }) {

  const posthog = usePostHog();
  const { credits } = useContext(CreditsContext);

  const [workflow, setWorkflow] = useState(data?.comfy?.json || null);
  const [workflowFileName, setWorkflowFileName] = useState(data?.comfy?.fileName || null);
    
  const updateNodeInternals = useUpdateNodeInternals();

  const { modelRunTrigger, updateModelRunTriggerStatus } = useContext(ModelRunContext);

  const role = useUserRole();

  const [isProcessing, setIsProcessing] = useState(false);



  const [predictionId, setPredictionId] = useState(null);
  const [predictionReady, setPredictionReady] = useState(false);
  const [pollingError, setPollingError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [predictionStatus, setPredictionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const predictionCanceled = useRef(null);

  const { handles, description, model, input, params, schema, editable } = data;

  // const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  const prevInput = usePrevious(data.input);
  // const runInitiatedRef = useRef(new Set());

  const hasNewResults = useRef(null);
  //seed management
  const prevSeed = useRef();

  const updateOutputWithNewResults = useCallback(()=>{
    // console.log("updating selected with last index ");
    if(hasNewResults.current){
      setSelectedOutput(data.result? data.result.length -1  : 0);
      setOutput(data.result.length -1);
    }
    hasNewResults.current = null;
        
  },[data.result, hasNewResults]);

  useEffect(() => {
    // console.log("data.result changed");
    if (hasNewResults.current) {
      updateOutputWithNewResults();
    }
  }, [data.result, updateOutputWithNewResults]);
    
  ///// schema and params
  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input'].includes(schema[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    if(schema[key]?.type === 'seed'){
      parsedValue = {
        isRandom: newValue.isRandom,
        seed: parseFloat(newValue.seed) || 1,
      };
    }

    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined;
    }
    updateNodeData(id,{
      params:{
        ...params,
        [key]: parsedValue,
      },
    });
  };

  const handleStopProcessing = (status) => {
    setIsProcessing(false);
    setPredictionId(null);
    // runInitiatedRef.current.delete(id);
    isRunning.delete(id);
    setProgress(0);

    if(status)
      updateModelRunTriggerStatus(id, status);
  };

  const handleStartProccessing = () => {
    setPredictionId(null);
    setErrorMessage(null);
    setPredictionStatus("starting");
    setIsProcessing(true);
    predictionCanceled.value = false;
    setPredictionReady(false);
    setPollingError(false);
    // runInitiatedRef.current.add(id);
    isRunning.add(id);
  };

  const handlePopulateComfyJSON = useCallback(() => {
    let inputObject = {};
    handles.input.forEach((handle) => {
      if(input[handle] !== undefined && input[handle] !== "" && input[handle] !== null){
        if(input[handle]?.type === "image" || input[handle]?.type === "video" || input[handle]?.type === "audio"){
          inputObject[handle] = input[handle].url;
        } else {
          inputObject[handle] = input[handle];
        }
      }
    });

    const updatedJson = { ...workflow };
    Object.keys(updatedJson).forEach((key) => {
      if(updatedJson[key]['class_type'] === 'CLIPTextEncode'){
        const title = updatedJson[key]['_meta']['title'];
        if(inputObject[title]){
          updatedJson[key]['inputs']['text'] = inputObject[title];
        }
      }
      if(updatedJson[key]['class_type'] === 'LoadImage'){
        const title = updatedJson[key]['_meta']['title'];
        if(inputObject[title]){
          updatedJson[key]['inputs']['image'] = inputObject[title];
        }
      }
    });

    // console.log("updatedJson", updatedJson);
    setWorkflow(updatedJson);
  },[workflow, input]);

  const run =  async () => {
    handleStartProccessing();
    posthog.capture('run_model_start', { model: model.name, type: model.name });
    // handle seed management
    if(params?.seed){
      if(params.seed.isRandom){
        prevSeed.current = lodash.random(1, 1000000);
      }
      else prevSeed.current = params?.seed.seed || undefined;
    }

    if(params?.seed) {
      updateNodeData(id,{
        params:{
          ...params,
          seed: {
            ...params.seed,
            seed: prevSeed.current,
          },
        },
      });
    }



    /// end of seed management

    handlePopulateComfyJSON();
    try {
      const newPredictionId = await runComfyWorkflow(workflow, model, params, null, credits);
      setPredictionId(newPredictionId);
      updateModelRunTriggerStatus(id, "starting", 0, newPredictionId);
    }
    catch (error)
    {
      setPollingError(true);
      setErrorMessage(error?.response?.data?.error?.detail);
      handleStopProcessing("failed");
    }
  };

  /// runnning the model from the outside (flow.jsx)
    
  useEffect(()=> {
    if(!modelRunTrigger.length){
      return;
    }

    const currentModel = modelRunTrigger.find((m) => m.id === id);
        
    if (!currentModel) return;

    if(currentModel.status === "canceled"){
      predictionCanceled.value = true;
      handleStopProcessing();
      
      return;
    }

    // if(runInitiatedRef.current.has(id)) return;
    if(isRunning.has(id)) return;

    if(currentModel.status !== "pending")
      return;

    const inputsChanged = JSON.stringify(input) !== JSON.stringify(prevInput);
        
    setIsProcessing(true);
        
    /// prevent from running the model twice in parallel (because of the input and modelRunTriggger dependency)
    if(predictionId)
      return;

    const { predecessors } = currentModel;
    if (!predecessors || predecessors.length === 0) {
      run();
      
      return;
    }

    const predecessorsStatus = predecessors.map((predecessorId) =>
      modelRunTrigger.find((m) => m.id === predecessorId)?.status,
    );

    const allPredecessorsReady = predecessorsStatus.every((status) => status === "ready");
    const anyPredecessorFailedOrCanceled = predecessorsStatus.some((status) => status === "failed" || status === "canceled");
        
    if(anyPredecessorFailedOrCanceled){
      predictionCanceled.value = true;
      handleStopProcessing("canceled");
      
      return;
    }
    if(allPredecessorsReady && inputsChanged){
      run();
    }
           
  }, [modelRunTrigger, predictionId ,input]);

  /// POLLING
  const checkPredictionStatus = async (predictionIdToCheck) => {
    try {
            
      const response = await axiosInstance.get(`/v1/models/predict/${predictionIdToCheck}/status`);
      setPredictionStatus(response.data.status);

      if(predictionCanceled.value) // this is to check if there was a cancel after the status request was sent 
        return;

      switch(response.data.status){
        case "succeeded":
          setPredictionReady(true);
          hasNewResults.current = true;
          // console.log("Got new results");
          updateNodeData(id, {
            result: data?.result ? [...data.result, ...response.data.results] : response.data.results,
            mjdata: response.data?.mjdata,
          });
          handleStopProcessing();
          posthog.capture('run_model_end', { model: model.name, type: model.name });
          break;
        case "starting":
          break;
        case "processing":
          setProgress(response.data.progress);
          updateModelRunTriggerStatus(id, "processing", response.data.progress, predictionId);
          break;
        case "failed":
          setPollingError(true);
          handleStopProcessing("failed");
          setErrorMessage(response?.data?.error);
          posthog.capture('run_model_error', { model: model.name, type: model.name });
          break;
        case "canceled":
          handleStopProcessing();
          break;
      }
            
    } catch (error) {
      // console.error("Error checking prediction status", error);
      setPollingError(true);
      handleStopProcessing("failed");
    }
  };

  useEffect(()=> {
    if(predictionStatus === "succeeded" && data.result) {
      updateModelRunTriggerStatus(id, "ready", 100);
      // runInitiatedRef.current.delete(id);
      isRunning.delete(id);
    }
  },[data.result, predictionStatus]);
    
  useEffect(() => {
    if (predictionId && !predictionReady && !pollingError) {
      let startTime = Date.now();
      let isMounted = true; // Flag to track the mounted status
    
      const handlePolling = () => {
        const elapsedTime = Date.now() - startTime;
    
        let interval;
        if (elapsedTime < 10000) {
          interval = 1000;
        } else if (elapsedTime < 30000) {
          interval = 2500;
        } else {
          interval = 5000;
        }
    
        checkPredictionStatus(predictionId).then(() => {
          // Check if the component is still mounted before scheduling the next poll
          if (isMounted && !predictionReady && !pollingError) {
            setTimeout(handlePolling, interval);
          }
        }).catch(() => {
          if (isMounted) {
            setPollingError(true); // Set polling error if there's an exception
          }
        });
      };
    
      handlePolling();
    
      // Cleanup function to clear pending timeouts and update the mounted flag
      return () => {
        isMounted = false; // Indicate the component has unmounted
        isRunning.delete(id);
      };
    }
  }, [predictionId, predictionReady, pollingError]);

  const parseJson = (json) => {
    let inputs = [];
    let classTypeCount = {};
    let existingTitles = new Set();

    Object.keys(json).forEach((key) => {
      const classType = json[key]['class_type'];

      if (classType === 'CLIPTextEncode' || classType === 'LoadImage') {
        if (!classTypeCount[classType]) {
          classTypeCount[classType] = 0;
        }

        let safeTitle = json[key]['_meta']['title']
          .toLowerCase()
          .replace(/\s+/g, '_') // Replace spaces with underscores
          .replace(/[^a-z0-9_]/g, '');  // allow only letters and numbers

        // Check if the safeTitle already exists
        if (existingTitles.has(safeTitle)) {
          classTypeCount[classType] += 1;
          safeTitle = `${safeTitle}_${classTypeCount[classType]}`;
        }

        json[key]['_meta']['title'] = safeTitle;
        inputs.push(safeTitle);
        existingTitles.add(safeTitle);

        // clear existing inputs from json
        if (json[key]['inputs']['text']) {
          json[key]['inputs']['text'] = "";
        }
        if (json[key]['inputs']['image']) {
          json[key]['inputs']['image'] = "";
        }
      }
    });

    updateNodeData(id, {
      handles: {
        ...handles,
        input: [...handles.input, ...inputs],
      },
    });
    updateNodeInternals(id);

    return json;
  };
  
  // handling upload json
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setWorkflow(json);
          setWorkflowFileName(file.name);
          const parsedJson = parseJson(json);
          updateNodeData(id, {
            comfy:{
              json: parsedJson,
              fileName: file.name,
            },
            name: "ComfyUI Workflow - " + file.name.split('.')[0],
          });
          setErrorMessage(null); // Clear any previous errors
        } catch (error) {
          setErrorMessage("Invalid JSON file");
        }
      };
      reader.readAsText(file);
    } else {
      setErrorMessage("Please upload a valid JSON file");
    }
  };
    

  // const fetchMarkdownList = async (url) => {
  //     try {
  //         const response = await axiosInstance.get(url);
  //         const text = response.data;
  //         // Assuming the list items are in the form of "- item" in the markdown file
  //         const listItems = text.split('\n')
  //             .filter(line => line.startsWith('- '))
  //             .map(line => line.slice(2).trim());
  //         console.log('List items:', listItems);
  //     } catch (error) {
  //         console.error('Error fetching the markdown file:', error);
  //         throw error;
  //     }
  // };

  // useEffect(() => {
  //     fetchMarkdownList('https://raw.githubusercontent.com/fofr/cog-comfyui/main/supported_weights.md');
  // }, []);



  useEffect(() => {

    const getModelVersionByName = async (modelName) => {
      try {
        const response = await axiosInstance.get(`/v1/models/${modelName}`);
        
        return response.data.latest_version.id;
      } catch (error) {
        console.error("Error getting model version", error);
      }
    
    };
    const getVersion =  async () => {

      let version = await getModelVersionByName(model.name);
      updateNodeData(id, {
        model: {
          ...model,
          version: version,
        },
      });
    };
    getVersion();
  },[model.name]);

   
  //todo: enum + reduce dupliaction
  const renderStatus = (status) => {
    switch (status){
      case "starting":
        return (
          <Typography>Starting</Typography>
        );
      case "processing":
        return (
          <Typography>{progress}%</Typography>
        );
      case "initial_processing":
        return (
          <Typography>Processing</Typography>
        );
      default:
        return (
          <Typography>Waiting</Typography>
        );
    }
  };
  
  return(
    <>
      {container === "node" &&
        <>
          <Typography variant="caption">{description}</Typography>
          {!data.results && <Box className="comfy-file-container" sx={ { mt:1 } }>
            {workflow ?
              (
                <Box>
                  <Typography>{workflowFileName}</Typography>
                </Box>
              ):
              (
                <input
                  type="file"
                  accept="application/json"
                  onChange={ handleUpload }
                />
              )}
          </Box>}
          <Box sx={ { mt:1 } }>
            {data.result && <NodeImageList images={ data.result } selected={ selectedOutput } setSelected={ setSelectedOutput } />}
          </Box>
          {/* {data.mjdata && <Typography>This is where the options will be</Typography>} */}
          <LoadingButton
            size="small"
            onClick={ ()=>run() }
            endIcon={ data.result && data.result.length > 0? <ReplayIcon />: <PlayArrowIcon /> }
            loading={ isProcessing }
            loadingPosition="end"
            variant="contained"
            color="weavy_cta"
            fullWidth
            disabled={ role !== 'editor' }
            sx={ { mt:1 } }
          >
            {isProcessing ? (
              renderStatus(predictionStatus)
            ):(
              <>{data.result && data.result.length > 0 ? 'Re-run Workflow':'Run Workflow'}</>
            )}
                
          </LoadingButton>
        </>
      }
      {schema && Object.keys(schema).length !== 0 && container === "drawer" && <Box id='advanced-mode-container' sx={ { width:'100%', mt:1 } }>
        <Box id='advnaced-mode-content-container' className="nowheel nodrag nopan">
          {/* <Typography variant="caption">{name}</Typography> */}
          {Object.keys(schema)
            .sort((a, b) => schema[a].order - schema[b].order)
            .map((key) =>
              renderDynamicField(params, key, schema[key], handleChange),
            )}
        </Box>

      </Box>
      }
      <Box sx={ { mt:1 } }>
        { pollingError &&
            <Typography sx={ { width:'100%', mt:1 } } variant="caption" color='error'>
              {errorMessage ? errorMessage : "Something went wrong. Check inputs"}
            </Typography>}
      </Box>
      <Box sx={ { mt:1, width:'100%', justifyContent:'center', display:'flex' } }>
        { workflow && editable && role === 'editor' &&
            <SaveComfyNode id={ id } data={ data } setNodesTypes={ setNodesTypes } />
        }
      </Box>
    </>

  );
}

export default ComfyCore;