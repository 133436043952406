import { Box, Typography, Button, Input, Checkbox, Tooltip, TextField } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axiosInstance from '../../services/axiosConfig';
import { NumberInputField } from "./input-fields/number-input-field/number-input-field.component";


export const getImageDimensions = async (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
  
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };
  
    img.onerror = (error) => {
      reject(error);
    };
  
    img.src = image;
  });
};

export const getFileDimensions = (file, type) => {
  return new Promise((resolve, reject) => {
    // For images
    if (type === 'image') {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = file;
    }
    // For videos
    else if (type === 'video') {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = reject;
      video.src = file;
    } else {
      reject(new Error('Unsupported file type'));
    }
  });
};
  
  
export const extractInputSchemaDetails = (jsonData) => {

  const schemas = jsonData.openapi_schema?.components?.schemas || {};
  const inputSchema = schemas.Input?.properties || {};
  const requiredKeys = schemas.Input?.required || [];
  const extractedDetails = {};
  
  const resolveRef = (ref) => {
    const refPath = ref.split('/');
    const refSchemaName = refPath[refPath.length - 1];
    
    return schemas[refSchemaName];
  };
  
  Object.entries(inputSchema).forEach(([propName, propInfo]) => {
    let propDetails = { type: propInfo.type };

    // support array of diffrent types
    if(propInfo.type === 'array'){
      propDetails.array_type = propInfo.items.type;
    }

    // Include default values
    if (propInfo.default !== undefined) {
      propDetails.default = propInfo.default;
    }
  
    // Handle enums directly within the property
    if (propInfo.enum) {
      propDetails.options = propInfo.enum;
    }
  
    // Handle min and max for numbers and integers
    if (propInfo.type === 'number' || propInfo.type === 'integer') {
      if (propInfo.minimum !== undefined) {
        propDetails.min = propInfo.minimum;
      }
      if (propInfo.maximum !== undefined) {
        propDetails.max = propInfo.maximum;
      }
      if(propInfo.minimum === undefined && propInfo.maximum === undefined){ // I assume it's not a slider but an input box
        if (propInfo.type === 'number'){
          propDetails.type = 'input-number';
        } else propDetails.type = 'input-integer';
        
      }
    }
  
    if(propName === 'Seed' || propName === 'seed'){
      propDetails.type = 'seed';
    }
    // Resolve references to other schemas and extract details
    // if (propInfo['allOf']) {
    //   const referencedSchema = resolveRef(propInfo['allOf'][0]['$ref']);
    //   // If the referenced schema is an enum, extract its options
    //   if (referencedSchema.enum) {
    //     propDetails.ref = propName;
    //     propDetails.options = referencedSchema.enum;
    //     propDetails.type = "enum";
    //   }
    //   // Extend to include other details from the referenced schema as needed
    // }
    if (propDetails.options) {
        propDetails.type = "enum";
    }

    /// fix name to be presentable
    function capitalizeWords(str) {
      return str.replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ') // Split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Rejoin the words into a single string
    }
    /// title
    propDetails.title = capitalizeWords(propName);
    /// description
    propDetails.description = propInfo['description'];
    if(propName === 'seed' || propName === 'Seed'){
      propDetails.description = 'Seed value for random number generator. Uncheck for reproducible results.';
    }
    /// order 
    propDetails.order = propInfo['x-order'];
    propDetails.required = requiredKeys.includes(propName) ? true : false;
    propDetails.format = propInfo['format'];

    // normalize seed names
    if(propName === 'seed' || propName === 'Seed'){
      propName = 'seed';
    }
    extractedDetails[propName] = propDetails;
  });
  
  const sortedDetailsArray = Object.entries(extractedDetails).sort((a, b) => {
    const orderA = a[1].order || 0; // Provide a default order if undefined
    const orderB = b[1].order || 0; // Provide a default order if undefined
    
    return orderA - orderB;
  });
  
  // Convert the sorted array back into an object
  const sortedExtractedDetails = sortedDetailsArray.reduce((acc, [key, value]) => {
    acc[key] = value;
    
    return acc;
  }, {});
  
  return sortedExtractedDetails;
};

const TextFieldWithCursor = ({ id, value, property, handleChange }) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  
  useEffect(() => {
    // If we have a stored cursor position and the input is focused
    if (cursorPosition !== null && inputRef.current === document.activeElement) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [value, cursorPosition]);

  const handleInputChange = (e) => {
    const newCursorPosition = e.target.selectionStart;
    setCursorPosition(newCursorPosition);
    handleChange(id, e.target.value);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" sx={{ mr: .5 }}>
        {property.title}
      </Typography>
      <Tooltip title={property.description} sx={{ fontSize: '10px' }}>
        <HelpOutlineIcon fontSize="10px" />
      </Tooltip>
      <TextField
        sx={{ mt: 1 }}
        fullWidth
        id={id}
        multiline
        value={value}
        rows={8}
        onChange={handleInputChange}
        size="small"
        inputRef={inputRef}
        inputProps={{
          style: { resize: "vertical" }
        }}
      />
    </Box>
  );
};

export default TextFieldWithCursor;
  
export const renderDynamicField = (params, key, property, handleChange) => {
  // const value = dynamicFields[key];
  const value = params[key];
  switch (property.type) {
    case "integer":
    case "number":
      return (
        <NumberInputField
          key= { `${key}-numberInputField` }
          inputKey={ key }
          title={ property.title }
          description={ property.description }
          value={ value }
          disabled={ property.expose }
          min={ property.min }
          max={ property.max }
          type={ property.type }
          onChange={ handleChange }
        />
      );
    case "input": // backward compatability 24.10 (adding separation between input of integer and floats)
      return (
        <Box key={ `${key}-textfield` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Input
            key={ `${key}-textfield` }
            fullWidth
            // label={key}
            value={ value }
            size="small"
            inputProps={ { type: 'number' } }
            onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
          />
        </Box>
      );
    case "input-integer":
      return (
        <Box key={ `${key}-textfield` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Input
            key={ `${key}-input-integer` }
            fullWidth
            // label={key}
            value={ value }
            size="small"
            inputProps={ { type: 'number' } }
            onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
          />
        </Box>
      );
    case "input-number":
      return (
        <Box key={ `${key}-textfield` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Input
            key={ `${key}-input-float` }
            fullWidth
            // label={key}
            value={ value }
            size="small"
            inputProps={ { type: 'number', step: '0.1' } }
            onChange={ (e) => handleChange(key, parseFloat(e.target.value)) }
          />
        </Box>
      );
    case "enum":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2 } }>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <FormControl fullWidth sx={ { mt:1 } }>
            {/* <InputLabel id={`${key}-label`}>{property.title}</InputLabel> */}
            <Select
              labelId={ `${key}-label` }
              id={ key }
              value={ value }
              // label={property.title}
              onChange={ (e) => handleChange(key, e.target.value) }
              size="small"
            >
              {property.options.map((option) => (
                <MenuItem key={ option } value={ option }>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
        
    case "boolean":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2, display:'flex', flexDirection:'row', alignItems:'center' } } >
          <FormControl >
            <Checkbox inputProps={ { 'aria-label': 'Checkbox' } } checked={ value } onChange={ (e) => handleChange(key, e.target.checked) } />
          </FormControl>
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
                  
        </Box>
      );
    case "seed":
      return (
        <Box key={ `${key}-enum` } sx={ { mb:2 } } >
          <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
            <FormControl >
              <Checkbox inputProps={ { 'aria-label': 'Checkbox' } } checked={ value?.isRandom }
                onChange={ (e) =>
                  handleChange(
                    key,
                    {
                      isRandom: e.target.checked,
                      seed: value.seed,
                    },
                  ) }
              />
            </FormControl>
            <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>Random</Typography>
                              
            <Input
              value={ value?.seed }
              inputProps={ { type: 'number' } }
              size="small"
              onChange={ (e) => handleChange(key, { isRandom: value?.isRandom, seed: e.target.value }) }
              disabled={ value?.isRandom }
              sx={ { ml:1 } }
            />
          </Box>
        </Box>
      );
    case "array":
      return (
        <Box key={ `${key}-array` } sx={ { mb:2 } } >
          <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
                    
          <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
            <HelpOutlineIcon fontSize="10px" />
          </Tooltip>
          <TextField
            sx={ { mt:1 } }
            fullWidth
            id={ key }
            multiline
            value={ Array.isArray(value) ? value.filter(Boolean).join(', ') : '' }
            rows={ 3 }
            onChange={ (e) => {
              const newValue = e.target.value.split(',').map((item) => item.trim()).filter(Boolean);
              console.log("newValue", newValue);
              handleChange(key, newValue);
            } }
            size="small"
          />
          <Typography variant="caption">Use comma for multiple entries </Typography>
                    
        </Box>
      );
    case "text":
      return (
        <TextFieldWithCursor 
          id={key}
          value={value}
          property={property}
          handleChange={handleChange}
        />
      );
    default:
      return null;
  }
};


export function sanitizeNodes(nodes) {
  const sanitizedNodes = nodes.map((node) => {
    if(node.data.output){
      for (const [name, conf] of Object.entries(node.data.output)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.output[name].url = "";
        }
      }
    }

    if(node.data.input){
      for (const [name, conf] of Object.entries(node.data.input)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.input[name].url = "";
        }
      }
    }

    return node;
  });

  return sanitizedNodes;
}

function performMatteChoke(imageData, amount) {
  const width = imageData.width;
  const height = imageData.height;
  const data = new Uint8ClampedArray(imageData.data);
  const result = new Uint8ClampedArray(data.length);

  const threshold = 128;
  const iterations = Math.abs(amount);
  const expand = amount > 0;

  for (let i = 0; i < iterations; i++) {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const idx = (y * width + x) * 4;
        let sum = 0;
        let count = 0;

        // Check neighboring pixels
        for (let dy = -1; dy <= 1; dy++) {
          for (let dx = -1; dx <= 1; dx++) {
            const nx = x + dx;
            const ny = y + dy;
            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
              const nidx = (ny * width + nx) * 4;
              sum += data[nidx] > threshold ? 1 : 0;
              count++;
            }
          }
        }

        // Determine if the pixel should be white or black
        const shouldBeWhite = expand ? (sum > 0) : (sum === count);
        const value = shouldBeWhite ? 255 : 0;

        // Set the result pixel
        result[idx] = result[idx + 1] = result[idx + 2] = value;
        result[idx + 3] = 255; // Alpha channel
      }
    }

    // Copy result back to data for next iteration
    data.set(result);
  }

  return new ImageData(result, width, height);
}

/// another options
export function matteChoker2(base64Image, amount) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const chokedImageData = performMatteChoke(imageData, amount);

      ctx.putImageData(chokedImageData, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
    img.src = base64Image;
  });
}

// bria register image
export const registerImageUtil = async (url) => {
  const res = await axiosInstance.post("/v1/models/br/run/masks/register", { url });
  
  return res.data.visualId;
};

export const getOS = () => {
  const platform = window.navigator.platform.toLowerCase();
  if (platform.includes('mac')) {
    return 'Mac';
  } else if (platform.includes('win')) {
    return 'Windows';
  }
  
  return 'Other';
};

export const flattenMenuItems = (items, searchTerm) => {
  let flatItems = [];
  const uniqueIds = new Set();

  const searchAndFlatten = (item) => {
    Object.keys(item).forEach((key) => {
      const currentItem = item[key];
      
      // Check if we have a valid item without children
      if (!currentItem.children && currentItem.id && !uniqueIds.has(currentItem.id)) {
        const searchTermLower = searchTerm.toLowerCase();
        
        // Check displayName
        const matchesDisplayName = currentItem.displayName.toLowerCase().includes(searchTermLower);
        
        // Check aliases
        const matchesAlias = currentItem.alias?.some(alias => 
          alias.toLowerCase().includes(searchTermLower)
        );

        if (matchesDisplayName || matchesAlias) {
          flatItems.push(currentItem);
          uniqueIds.add(currentItem.id);
        }
      }
      
      // Continue searching in children
      if (currentItem.children) {
        searchAndFlatten(currentItem.children);
      }
    });
  };

  searchAndFlatten(items);
  return flatItems;
};

export const cleanParamsForSaving = (params, latestSeed) => {
  const cleanParams = _.cloneDeep(params)
  if(cleanParams && cleanParams.seed){
    cleanParams.seed = latestSeed;
  }
  // if(cleanParams){
  //   for (const [name, conf] of Object.entries(cleanParams)){
  //     if (typeof conf === 'string' && conf?.url.includes("data:image/png;base64")) {
  //       cleanParams[name].url = "";
  //     }
  //   }
  // }
  
  return cleanParams;
}

/// IMAGE processing functions

export function rgbaToRgb(imageUrl, format = "jpeg") {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable cross-origin if needed
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas size to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas back to a data URL in the desired format
      const rgbImageUrl = canvas.toDataURL(`image/${format}`);

      // Cleanup: remove the canvas
      canvas.remove();

      resolve(rgbImageUrl);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image."));
    };
  });
}

/**
 * Negates the colors of an image from a URL or base64 string.
 * @param {string} imageSource - URL or base64 string of the image
 * @returns {Promise<string>} Promise that resolves to a base64 string of the negated image
 */
export async function negateImageColors(imageSource) {
  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  // Create and load the image
  const image = new Image();
  image.crossOrigin = 'Anonymous'; // Enable CORS if needed
  
  // Wait for the image to load
  await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
      image.src = imageSource;
  });
  
  // Set canvas size to match image
  canvas.width = image.width;
  canvas.height = image.height;
  
  // Draw image to canvas
  ctx.drawImage(image, 0, 0);
  
  // Get image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  
  // Invert colors
  for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = 255 - imageData.data[i];         // Red
      imageData.data[i + 1] = 255 - imageData.data[i + 1]; // Green
      imageData.data[i + 2] = 255 - imageData.data[i + 2]; // Blue
      // Alpha channel (i + 3) remains unchanged
  }
  
  // Put the negated image data back
  ctx.putImageData(imageData, 0, 0);
  
  // Return as base64 string
  return canvas.toDataURL('image/png');
}