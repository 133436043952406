import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import axiosInstance from '../services/axiosConfig';
import I18N_KEYS from '../language/keys';

const DesignAppShareActivationPage = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      const validateToken = async () => {
        try {
          const response = await axiosInstance.post('v1/recipes/design-app/share/validate', { token });

          // On success, redirect to the recipe page
          const recipeId = response.data.recipeId;
          navigate(`/recipe/${recipeId}`, { replace: true });
        } catch (e) {
          console.error('Share activation error:', e);
          setError(e?.response?.data?.error || translate(I18N_KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.DEFAULT_ERROR));
        }
      };

      validateToken();
    } else {
      setError('No token provided. Please use a valid link.');
    }
  }, [location.search, navigate]);

  return (
    <Box sx={ { display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 } }>
      {error ? (
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      ) : (
        <>
          <Typography variant="h6" sx={ { mt: 2 } }>
            { translate(I18N_KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.TITLE) }
          </Typography>
          <CircularProgress />
        </>
      )}
    </Box>
  );
};

export default DesignAppShareActivationPage;
