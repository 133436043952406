import React from "react";
import { colorMap } from '../../colors';
import { DynamicNode2 } from "./VisualNodes";
import ExportCore from "./ExportCore";

function ExportNode({ id, data, updateNodeData }) {

  return (
    <DynamicNode2 id={ id } data={ data } className="export" handleColor={ colorMap.get(data.color) } backgroundColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) } >
      <ExportCore id={ id } data={ data } updateNodeData={ updateNodeData }  container="node" />
    </DynamicNode2>
  );
}

export default ExportNode;