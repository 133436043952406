import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

function StringCore ({ id, data, updateNodeData }) {

  const [string, setString] = useState(data?.result?.string);

  const onStringChange = (e) => {
    setString(e.target.value);
  };

  useEffect(()=>{
    updateNodeData(id, {
      result:{
        string,
      },
      output:
            {
              type:"text",
              string,
            },
    },
    );
  },[string]);

  return (
    <TextField
      className="nowheel nodrag nopan"
      sx={ { mt:1 } }
      label="String"
      value={ string }
      onChange={ onStringChange }

      multiline
      rows={ 3 }
      fullWidth
      size="small"
      onMouseDown={ (event) => event.stopPropagation() }
    />
  );
}

export default StringCore;