import { Typography , Box } from "@mui/material";
import React from "react";

function Footer (){
  const currentYear = new Date().getFullYear();
  
  return(
    <Box sx={ { position:'absolute', bottom:'15px', left:'25px' } } id='footer-container'>
      <Typography variant="caption">© Weavy.ai {currentYear} </Typography>
    </Box>
  );
}

export default Footer;