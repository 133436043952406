import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC-qLy3TFyXMogJPfMkZJ9H_q46hEu1sxI",
  authDomain: "weavy.ai",
  projectId: "comfyweb-c31be",
  storageBucket: "comfyweb-c31be.appspot.com",
  messagingSenderId: "886041777019",
  appId: "1:886041777019:web:72eef87c590cef279823de",
  measurementId: "G-FZG9BNPQXK",
};

const firebase = initializeApp(firebaseConfig);

const auth = getAuth(firebase);
auth.settings.appVerificationDisabledForTesting = false;
const db = getFirestore(firebase);
const storage = getStorage(firebase);

export { firebase, auth, db, storage };
