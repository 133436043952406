import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = ({ user }) => {
  if (!user) {
    // Redirect to homepage if user is not signed in
    return <Navigate to="/" replace />;
  }

  // If user is authenticated, render the nested routes
  return <Outlet />;
};

export default ProtectedRoutes;
