import React, { ReactNode, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import UpgradeModal from "../components/SubscriptionsAndPayments/UpgradeModal";
import axiosInstance from "./axiosConfig";

export const CreditsContext = React.createContext({
  credits: null,
  setUserCredits: (credits)=>{},
  openUpgradeModal : () => {},
});

type CreditsProviderProps = {
  children: ReactNode,
}

export const CreditsProvider = ({ children }: CreditsProviderProps) => {

  const { currentUser, updateCurrentUser } = React.useContext(AuthContext);
  const [credits, setCredits] = React.useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);

  useEffect(() => {
    if (currentUser) {
      const fetchCredits = async () => {
        try {
          const response = await axiosInstance.get("/v1/users/credits");
          setCredits(response.data.credits);
          updateCurrentUser(response.data);
        } catch (error) {
          console.error("Error fetching credits:", error);
        }
      };
      fetchCredits();
    }
  }, []);

  const openUpgradeModal = () => {
    localStorage.setItem('lastPageBeforePayment', window.location.href);
    setShowUpgradeModal(true);
  }

  return (
    <CreditsContext.Provider value={ { credits, setUserCredits: setCredits, openUpgradeModal } }>
      {children}
      {currentUser && <UpgradeModal currentUser={ currentUser } open={ showUpgradeModal } closeModal={ () => setShowUpgradeModal(false) } />}
    </CreditsContext.Provider>
  );
};
