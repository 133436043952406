import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Slide,
  Input,
  CircularProgress,
} from "@mui/material";

import { color } from "../../colors";
import axiosInstance from "../../services/axiosConfig";
import NodeContextMenu from "./NodeContextMenu";
import ModelCard from "./ModelCard";


function SlideTransition(props) {
  return <Slide { ...props } direction="down" />;
}

function Loader ({ offset }) {
    
  return (
    <Box sx={ { zIndex:100, background:color.Yambo_BG, display:'flex', position:"absolute", top:0, left:0, width:'100%', height:'100%', alignItems:'center', justifyContent:'center' } }>
      <CircularProgress color="inherit" sx={ { left:`${offset/2}px`, position:'relative' } } />
    </Box>
  );
}

function CommunityModels ({ title }) {

  const [publicModels, setPublicModels] = useState([]);
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [loadingEnrichment, setLoadingEnrichment] = useState(true);
  const [isCloneSnackBarOpen, setIsCloneSnackBarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [contextMenu, setContextMenu] = useState({ mouseX: null, mouseY: null, isOpen: false, nodeId:null });

  const handleCloneSnackBarClose = ()=> {
    setIsCloneSnackBarOpen(false);
  };
  const getPublicModels = async () => {
    try{
      const response = await axiosInstance.get(`/v1/nodes/public`);
      // console.log(response.data);
      setPublicModels(response.data);
    } catch (error) {
      console.error("Error loading user nodes: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPoster = async (node) => {
    try{
      const response = await axiosInstance(`/v1/models/${node.data.model.name}`);
      // console.log("model: ",response.data);
      
      return response.data;
    } catch (error) {
      console.error("Error loading model attributes: ", error);
      setLoadingEnrichment(false);
    }
  };

  const handleFinishLoading = () =>{
    setTimeout(()=>setLoadingEnrichment(false), 2500);
  };
  
  useEffect(() => {
    const enrichNodes = async () => {
      if (publicModels) {
        const nodesWithPosters = await Promise.all(publicModels.map(async (node) => {
          const enrichment = await getPoster(node).catch(() => '/empty.png'); // Fallback to '/empty.png' in case of error
          
          return {
            ...node,
            poster: enrichment?.cover_image_url,
            creator: enrichment?.owner,
            license: enrichment?.license_url,
            github: enrichment?.github_url,
          };
        }));
        setPublicModels(nodesWithPosters);
        handleFinishLoading();
      }
    };
    enrichNodes();
  }, [publicModels.length]);

  useEffect(()=>{
    getPublicModels();
    // console.log("getting nodes");
  },[]);

  const handleMouseEnterCard = (id) => {
    setHoveredNodeId(id);
  };

  const handleMouseLeaveCard = () => {
    setHoveredNodeId(null);
  };

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      isOpen: true,
      nodeId:id,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({ ...contextMenu, isOpen: false });
  };

  const handleCloneSuccess = () => {
    setIsCloneSnackBarOpen(true);
  };

  const saveToMyNodes = async (id) => {
    try{
      const response = await axiosInstance.post(`/v1/nodes/${id}/clone`,{});
      handleCloneSuccess(response, id);
    } catch (error) {
      console.error("Error cloning node: ", error);
    }
  };

  const menuItems = [
    { label: 'Save to My Models', onClick: (e) => {e.preventDefault();saveToMyNodes(contextMenu.nodeId); handleContextMenuClose(); } },
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredModels = publicModels.filter((node) =>
    node.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        node.data.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        node.data.model.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );


  return (
    <>
      {isLoading &&
        <Box sx={ { position:"relative", width:"100%", height:"100vh", display:"flex" } }>
          <Loader offset={ 0 } />
        </Box>}
      {!isLoading && <Box component="main" sx={ { width:'100%', display:'flex', justifyContent:'center', flexDirection:'column', p:8 } } id="dashboard-nodes-container">
        <Box sx={ { display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:"center" } }>
          <Box>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="caption">Find awesome models that can be used in your files. Right-click to add to your models library</Typography>
          </Box>
          <Input placeholder="Search" sx={ { width:'200px' } } value={ searchTerm } onChange={ handleSearchChange } />
        </Box>
        <Box id="my-nodes-content-container" sx={ { mt:2 } }>
          <Grid container spacing={ 2 }>
            {filteredModels && filteredModels.map((node)=>{
              return(
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 4 } key={ node.id } onContextMenu={ (e) => handleContextMenu(e, node.id) } onClick={ (e) => handleContextMenu(e, node.id) }>
                  <ModelCard
                    handleMouseLeaveCard={ handleMouseLeaveCard }
                    handleMouseEnterCard={ handleMouseEnterCard }
                    loadingEnrichment={ loadingEnrichment }
                    model={ node }
                    hoveredModelId={ hoveredNodeId }
                  />
                            
                </Grid>);
            })}
          </Grid>
          {contextMenu.isOpen &&
              <NodeContextMenu
                mouseX={ contextMenu.mouseX }
                mouseY={ contextMenu.mouseY }
                isOpen={ contextMenu.isOpen }
                onClose={ handleContextMenuClose }
                menuItems={ menuItems }
              />
          }
        </Box>
        <Snackbar
          open={ isCloneSnackBarOpen }
          autoHideDuration={ 3000 }
          onClose={ handleCloneSnackBarClose }
          anchorOrigin={ { vertical:'top',horizontal:'center' } }
          TransitionComponent={ SlideTransition }
        >
          <Alert
            onClose={ handleCloneSnackBarClose }
            severity="success"
            variant="filled"
            sx={ { width: '100%', color:'white' } }
          >
                Model saved to your library
          </Alert>
        </Snackbar>
           
      </Box>}
    </>
  );
}

export default CommunityModels;