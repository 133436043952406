import { useEffect, useCallback } from "react";
import { handleZoom, handlePinchZoomGesture } from "./CanvasNavigationUtils";

const useCanvasPanAndZoom = (canvas, containerRef, isFocused) => {

  const resetViewport = useCallback(() => {
    if (canvas) {
      // Reset zoom and pan
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.setZoom(1);
      canvas.renderAll();
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      let isPanning = false;
      let spacePressed = false;
      let lastPosX = 0;
      let lastPosY = 0;

      const handleMouseDown = (opt) => {
        if (spacePressed) {
          const evt = opt.e;
          isPanning = true;
          lastPosX = evt.clientX;
          lastPosY = evt.clientY;
          canvas.selection = false; // Disable selection while panning
          opt.e.preventDefault(); // Prevent default action to avoid scrolling
          opt.e.stopPropagation();
        }
      };
    
      const handleMouseMove = (opt) => {
        if (isPanning && spacePressed && opt.e) {
          const e = opt.e;
          canvas.relativePan({ x: e.clientX - lastPosX, y: e.clientY - lastPosY });
          canvas.requestRenderAll();
          lastPosX = e.clientX;
          lastPosY = e.clientY;
        }
      };
    
      const handleMouseUpOrOut = () => {
        isPanning = false;
        canvas.selection = true;
      };
    
      const handleKeyDown = (e) => {
        if (containerRef.current && (containerRef.current === document.activeElement || containerRef.current.contains(document.activeElement))) {
          if (e.code === "Space") {
            spacePressed = true;
            canvas.selection = false;
            canvas.defaultCursor = "grab";
            canvas.upperCanvasEl.style.cursor = "grab";
            e.preventDefault();
          }
        }
      };
    
      const handleKeyUp = (e) => {
        if (e.code === "Space") {
          spacePressed = false;
          canvas.selection = true;
          canvas.defaultCursor = "default";
          canvas.upperCanvasEl.style.cursor = "default";
        }
      };
    
      canvas.on("mouse:down", handleMouseDown);
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUpOrOut);
      canvas.on("mouse:out", handleMouseUpOrOut);
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    
      return () => {
        canvas.off("mouse:down", handleMouseDown);
        canvas.off("mouse:move", handleMouseMove);
        canvas.off("mouse:up", handleMouseUpOrOut);
        canvas.off("mouse:out", handleMouseUpOrOut);
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      const handleWheel = (event) => {
        event.stopPropagation();
        if(event.ctrlKey){
          handlePinchZoomGesture(canvas, event);
        }
        else handleZoom(canvas, event);
      };

      const canvasEl = canvas.upperCanvasEl;
      canvasEl.addEventListener("wheel", handleWheel);

      return () => {
        canvasEl.removeEventListener("wheel", handleWheel);
      };
    }
  }, [canvas]);

  return { resetViewport };
};

export default useCanvasPanAndZoom;