import React, { useRef, useState, useCallback, useId } from 'react';
import { MenuItem, Menu } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ParentMenuItem = ({ label, children, id, MenuProps, onClose ,...other }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemRef = useRef(null);
  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const handleItemKeyDown = (ev) => {
    if ((ev.key !== 'ArrowRight' && ev.key !== 'Enter') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return;
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown = (ev) => {
    ev.stopPropagation();
    if ((ev.key !== 'ArrowLeft' && ev.key !== 'Escape') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return;
    ev.preventDefault();
    setIsOpen(false);
  };
  
  const onMenuClose = () => {
    close();
    onClose();
  };

  return (
    <MenuItem
      { ...other }
      onKeyDown={ handleItemKeyDown }
      ref={ menuItemRef }
      sx={ [{ justifyContent: 'space-between' }, isOpen && { backgroundColor: 'action.hover' }] }
      onMouseEnter={ open }
      onMouseLeave={ close }
      id={ normMenuItemId }
    >
      <span>{label}</span>
      <NavigateNextIcon sx={ { fontSize: '1.2rem' } } />
      <Menu
        TransitionProps={ { onExited: () => menuItemRef.current?.focus() } }
        className="flow-float-memu"
        disableRestoreFocus
        onKeyDown={ handleMenuKeyDown }
        sx={ {
          pointerEvents: 'none',
          '& .MuiList-root': {
            pointerEvents: 'auto',
          },
        } }
        MenuListProps={ {
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId,
        } }
        anchorEl={ menuItemRef.current }
        open={ isOpen }
        onClose={ onMenuClose }
        anchorOrigin={ MenuProps?.anchorOrigin ?? { vertical: 'top', horizontal: 'right' } }
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: 'top',
            horizontal: 'left',
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  );
};

export default ParentMenuItem;
