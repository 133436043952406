import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useUserRole } from "../Recipe/UserRoleContext";
import { DynamicNode2 } from './VisualNodes';


function StickyNoteNode({ id, data, updateNodeData }) {

  const role = useUserRole();
  // const { currentUser } = useContext(AuthContext);

  const [note, setNote] = useState(data.result.note);
  const [isFocused, setIsFocused] = useState(false);
  // const [userName, setUserName] = useState(data.result.user || currentUser.displayName);


  const onNoteChange = (event) => {
    const updatedNote = event.target.value;
    setNote(updatedNote);
  };

  useEffect(()=>{
    updateNodeData(id, { result:{ note:note } } );
  },[note]);

  return (
    <DynamicNode2 id={ id } data={ data } className="stickynote" hideTitle={ true }>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'column',
          alignItems: "flex-end",
          width:'100%',
          pointerEvents: role === "guest" ? 'none' : '',
        } }
      >
        <TextField
          className={ isFocused ? "nowheel nodrag nopan": "" }
          onFocus={ () => {setIsFocused(true);} }
          onBlur={ () => {setIsFocused(false);} }
          value={ note }
          onChange={ onNoteChange }
          multiline
          placeholder="Type in anything, @mention anyone"
          minRows={ 5 }
          fullWidth
          size="small"
          onMouseDown={ (event) => event.stopPropagation() }
          sx={ {
            p:0,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Removes the border
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Removes the border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Removes the border on focus
            },
          } }
        />
        {/* <Typography variant="caption" sx={{mt:1}}>{userName}</Typography> */}
      </Box>
    </DynamicNode2>
  );
}

export default StickyNoteNode;
