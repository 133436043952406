import { Box, Link } from "@mui/material";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { fabric } from 'fabric';
import { colorMap } from "../../colors";
import useCanvasPanAndZoom from "../Recipe/FlowComponents/Editor/usePanAndZoom";
import { useUserRole } from "../Recipe/UserRoleContext";
import { imageSerialization, backgroundSerialization, replaceImageAndCopyAttributes } from "./Painter/CanvasUtils";
import { DynamicNode2 } from './VisualNodes';
const stopPropagation = (e) => e.stopPropagation();

// let borderCount = 0; 

function CompNode ({ id, data, updateNodeData, onCanvasInteraction }) {

  const role = useUserRole();

  const [isFocused, setIsFocused] = useState(false);

  const { handles } = data;
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [canvasScaleFactor,setCanvasScaleFactor] = useState(1);
  const [localCanvasWidth, setLocalCanvasWidth] = useState(400);
  const [localCanvasHeight, setLocalCanvasHeight] = useState(400);
  const [canvasImages, setCanvasImages] = useState([]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const prevInputs = usePrevious(data.input);

  const debounceTimeoutRef = useRef();
  const initialLoadCompleted = useRef(false);

  const { resetViewport } = useCanvasPanAndZoom(canvas, containerRef);

  useEffect(() => {

    /// serializtion functions (to handles save canvas without src of images to prevent from saving base64)
    imageSerialization();
    backgroundSerialization();

    /// create new Fabric canvas
    const fabricCanvas = new fabric.Canvas(canvasRef.current,{
      backgroundColor:'#333',
    });
    setCanvas(fabricCanvas);
    // if (canvas && data.input && data.input[handles.input[0]]) {
    //     setCanvasBackgroundImage(data.input[handles.input[0]].url);
    // }

    fabricCanvas.upperCanvasEl.addEventListener('mousedown', stopPropagation);

    // const savedCanvasState = data.canvas;
    // if (savedCanvasState) {
    //     fabricCanvas.loadFromJSON(JSON.parse(savedCanvasState), fabricCanvas.renderAll.bind(fabricCanvas));
    //     console.log(JSON.parse(savedCanvasState));
    // }


    return () => {
      fabricCanvas.upperCanvasEl.removeEventListener('mousedown', stopPropagation);
      fabricCanvas.dispose();
    };
  }, []);

  const handleExport = useCallback(() => {
    if (canvas) {
      const originalZoom = canvas.getZoom();
      const originalViewportTransform = [...canvas.viewportTransform];

      // Reset to default view (no zoom and pan)
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.setZoom(1);

      const multiplier = 1/canvasScaleFactor;
      const outputDataUrlWithImage = canvas.toDataURL({
        format: 'png',
        multiplier: multiplier,
        // enableRetinaScaling: true
      });

      const canvasWidth = canvas.width * multiplier;
      const canvasHeight = canvas.height * multiplier;

      const formattedOutput = {};
      const output = {
        type:'image',
        url:outputDataUrlWithImage,
        width: canvasWidth,
        height: canvasHeight,
      };
      formattedOutput[handles.output[0]] = output;

      const canvasJson = JSON.stringify(canvas.toJSON());
      updateNodeData(id,  { canvas:canvasJson,  output:formattedOutput } );

      canvas.setViewportTransform(originalViewportTransform);
      canvas.setZoom(originalZoom);
      canvas.renderAll();
    }
  }, [canvas, canvasScaleFactor]);

  const debouncedExport = useCallback(() => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      handleExport();
    }, 500);
  }, [handleExport]);

  const setCanvasBackgroundImage = useCallback((backgroundImage) => {
    if(canvas){
      const maxWidth = 400;
      let scaleFactor;
      let canvasWidth;
      let canvasHeight;
      fabric.Image.fromURL(backgroundImage, (img) => {
        let imageWidth = img.width;
        let imageHeight = img.height;
        // Check if image width exceeds maxWidth and adjust dimensions
        if (imageWidth > maxWidth) {
          scaleFactor = maxWidth / imageWidth;
          canvasWidth = imageWidth * scaleFactor;
          canvasHeight = imageHeight * scaleFactor;
        }
        else {
          scaleFactor = 1;
          canvasWidth = imageWidth;
          canvasHeight = imageHeight;
        }

        setLocalCanvasWidth(canvasWidth);
        setLocalCanvasHeight(canvasHeight);
        canvas.setHeight(canvasHeight);
        canvas.setWidth(canvasWidth);

        // console.log(borderCount);
        // if(borderCount === 0){
        //   const border = new fabric.Rect({
        //     width: canvasWidth,
        //     height: canvasHeight,
        //     left: 0,
        //     top: 0,
        //     fill: 'transparent',
        //     stroke: '#555', 
        //     strokeWidth: 3, 
        //     selectable: false,
        //     evented: false, 
        //   });
        //   canvas.add(border);
          
        //   //  canvas.sendToBack(border);
        //   canvas.renderAll();
        //   // console.log("added border");
        //   borderCount++ ;
        // }

        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });

        setCanvasScaleFactor(scaleFactor);


      },{ crossOrigin: 'Anonymous' });
      debouncedExport();
    }
  },[canvas]);

  const addFrontImage = useCallback((frontImage, transformAttributes = null) => {
    if(canvas && localCanvasHeight && localCanvasWidth){
      setCanvasImages([...canvasImages, frontImage]);
      fabric.Image.fromURL(frontImage, function(img) {
        // console.log(canvasScaleFactor);
        // Set image properties
        if (transformAttributes) {
          img.set(transformAttributes);
        } else {
          // Apply default scale if no previous attributes
          img.set({
            scaleX: canvasScaleFactor,
            scaleY: canvasScaleFactor,
          });
        }
        img.customId = 'image_'+handles.input[1];
        canvas.add(img);
        canvas.renderAll();
      }, { crossOrigin: 'Anonymous' });
    }
  },[canvas, localCanvasHeight, localCanvasWidth]);

  //// initializaiton
  useEffect(() => {
    if (canvas && data.input && !initialLoadCompleted.current && data.canvas) {

      canvas.loadFromJSON(data.canvas, () => {
        // After loading, iterate through canvas objects to set their src
        canvas.getObjects().forEach((obj) => {
          if (obj.type === 'image') {
            const inputKey = Object.keys(data.input).find((key) => `image_${key}` === obj.customId);
            if (inputKey && data.input[inputKey]?.url) {
              replaceImageAndCopyAttributes(canvas, obj,data.input[inputKey].url);
            }
          }
        });

        // Optionally, set the background image if its src is stored similarly
        if (data.input[handles.input[0]] && data.input[handles.input[0]].url) {
          setCanvasBackgroundImage(data.input[handles.input[0]].url);
        }

        canvas.renderAll();
      });

      // Mark as completed to prevent re-execution
      initialLoadCompleted.current = true;
      handleExport();
    }
  }, [canvas, data.input, data.canvas]);

  useEffect(() => {
    // Force handleExport to run after the canvas is fully loaded and data is initialized
    if (canvas && initialLoadCompleted.current) {
      handleExport();
    }
  }, [canvas, initialLoadCompleted.current]);

  const removeFrontImage = useCallback((idToRemove) => {
    if(canvas){
      const objects = canvas.getObjects('image');
      for (let i = 0; i < objects.length; i++) {
        if(objects[i].customId === idToRemove){
          const attributes = {
            left: objects[i].left,
            top: objects[i].top,
            scaleX: objects[i].scaleX,
            scaleY: objects[i].scaleY,
            angle: objects[i].angle,
          };
          canvas.remove(objects[i]);
          canvas.renderAll();

          return attributes;
        }
      }
    }
  },[canvas]);

  const removeCanvasBackgroundImage = useCallback(() => {
    if(canvas){
      canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
      setCanvasScaleFactor(1);
      debouncedExport();
    }
  },[canvas]);

  useEffect(() => {
    if (canvas && data.input) {
      Object.keys(data.input).forEach((inputKey) => {
        const currentInput = data.input[inputKey];
        const prevInput = prevInputs ? prevInputs[inputKey] : undefined;

        if (currentInput && !prevInput) {
          // Handle newly connected input
          if (inputKey === handles.input[0]) {
            setCanvasBackgroundImage(currentInput.url);
          } else {
            addFrontImage(currentInput.url);
          }
        }
        if (currentInput && prevInput) {
          if(JSON.stringify(currentInput) !== JSON.stringify(prevInput)){
            // Handle change in inputs nodes
            if (inputKey === handles.input[0]) {
              setCanvasBackgroundImage(currentInput.url);
            } else {
              const attributes = removeFrontImage(`image_${inputKey}`);
              addFrontImage(currentInput.url, attributes);
            }
          }
        }
        if (!currentInput && prevInput) {
          // Handle disconnected input
          if (inputKey === handles.input[0]) {
            removeCanvasBackgroundImage();
          } else {
            removeFrontImage(`image_${inputKey}`);
          }
        }
      });
    }
  }, [data.input]);


  ///// HANDLE SAVE //////////////////////////////////////////////////

  // useEffect(() => {
  //     if (canvas) {
  //         handleExport();
  //     }
  // }, [data.input]);

  useEffect(() => {
    if (canvas) {
      // Attach event listeners that trigger the debounced export
      const onChange = () => debouncedExport();
      canvas.on('object:modified', onChange);
      canvas.on('object:added', onChange);
      canvas.on('object:removed', onChange);

      return () => {
        // Detach event listeners on cleanup
        canvas.off('object:modified', onChange);
        canvas.off('object:added', onChange);
        canvas.off('object:removed', onChange);
      };
    }
  }, [canvas, debouncedExport]);

  useEffect(() => {
    // Ensure we don't trigger export on initial render
    if (canvasScaleFactor !== 1) {
      debouncedExport();
    }
  }, [canvasScaleFactor, debouncedExport]);

  //// EVENT HANDLERS
  useEffect(() => {
    const handleCanvasClick = () => {
      if (onCanvasInteraction) {
        // Directly check if the container is or contains the active element
        const containerElement = containerRef.current;
        if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
          onCanvasInteraction();
        }
      }
    };

    const handleKeyDown = (event) => {
      // Similar check as above for keydown events
      const containerElement = containerRef.current;
      if (containerElement && (containerElement === document.activeElement || containerElement.contains(document.activeElement))) {
        // console.log("Key pressed:", event.key); // For debugging
        if (event.key === 'Delete' || event.key === 'Backspace') {
          event.stopPropagation();
        }
      }
    };

    const upperCanvas = canvas ? canvas.upperCanvasEl : null;
    if (upperCanvas) {
      upperCanvas.addEventListener('click', handleCanvasClick);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (upperCanvas) {
        upperCanvas.removeEventListener('click', handleCanvasClick);
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [canvas, onCanvasInteraction]);


  return (
    <DynamicNode2 id={ id } data={ data } className="comp" handleColor={ colorMap.get(data.color) }>
      <Box sx={ { width: '100%', pb:1 } }>
        <Link onClick={ resetViewport } sx={ { cursor:'pointer', fontSize:'12px', mx:1 } }>Reset Viewport</Link>
      </Box>
      <Box
        sx={ { display:'flex', flexDirection:'row' } }
        ref={ containerRef }
        tabIndex="0"
        className={ isFocused ? "nowheel nodrag nopan": "" }
        id="comp-node-container"
        onFocus={ () => { setIsFocused(true); } }
        onBlur={ () => { setIsFocused(false); } }
        
      >
        <Box sx={ { position:'relative',display:'flex', justifyContent:'center', pointerEvents: role === "guest"? 'none':'' } }>
          <canvas ref={ canvasRef } onClick = { () => { setIsFocused(true); } } tabIndex="0" id="comp-node-canvas" />
        </Box>
      </Box>
    </DynamicNode2>
  );
}

export default CompNode;