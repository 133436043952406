import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, CircularProgress, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../services/axiosConfig";
import { color } from '../../../colors';
import I18N_KEYS from '../../../language/keys';
const COUNTDOWN_VALUE_SECONDS = 2;

const ShareDesignAppModal = ({ open, onClose, recipeId, posthog }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(COUNTDOWN_VALUE_SECONDS);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { t: translate } = useTranslation();

  const handleClose = () => {
    setEmail('');
    setIsLoading(false);
    setIsLinkCopied(false);
    setError('');
    setCountdown(COUNTDOWN_VALUE_SECONDS);
    onClose();
  };

  useEffect(() => {
    let countdownInterval;
    if (isLinkCopied) {
      let countdownValue = COUNTDOWN_VALUE_SECONDS;
      countdownInterval = setInterval(() => {
        countdownValue -= 0.1;
        setCountdown(countdownValue.toFixed(1));
        if (countdownValue <= 0) {
          clearInterval(countdownInterval);
          handleClose();
        }
      }, 100);
    } else {
      setCountdown(COUNTDOWN_VALUE_SECONDS);
    }
    
    return () => clearInterval(countdownInterval);
  }, [isLinkCopied]);

  const validateEmail = (emailToValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return emailRegex.test(emailToValidate);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setError('');
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleShareSubmit = async () => {
    setIsLoading(true);
    posthog.capture('clicked_share_design_app');

    try {
      const response = await axiosInstance.post(`/v1/recipes/${recipeId}/design-app/share`, { email });
      const { shareToken } = response.data;
      const shareUrl = `${window.location.origin}/design-app/share/activate?token=${shareToken}`;

      if (document.hasFocus()) {
        await navigator.clipboard.writeText(shareUrl);
      } else {
        const tempInput = document.createElement("input");
        tempInput.value = shareUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
      }

      setIsLinkCopied(true);
    } catch (e) {
      console.error("Error sharing recipe:", e);
      const errorMessage = error.response?.data?.error || translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.DEFAULT_ERROR);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={ open } onClose={ handleClose } aria-labelledby="share-design-app-modal">
      <Box sx={ {
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2,
      } }
      >
        <Typography variant="h6" component="h2" sx={ { mb: 2 } }>
          { translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.TITLE) }
        </Typography>
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          type="email"
          value={ email }
          onChange={ handleEmailChange }
          sx={ { mb: 2 } }
          autoComplete="off"
          error={ Boolean(error) }
          helperText={ error }
        />
        <Button
          variant="contained"
          color={ isLinkCopied ? "success" : "primary" }
          onClick={ handleShareSubmit }
          disabled={ !isEmailValid || isLoading || isLinkCopied }
          fullWidth
          endIcon={ isLoading ? <CircularProgress size={ 24 } /> : null }
          sx={ { position: 'relative', overflow: 'hidden' } }
        >
          {isLinkCopied ? (
            <>
              <span style={ { color: color.Yambo_Text_On_Dark } }>
                { translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.LINK_COPIED) }
              </span>
              <CheckCircleIcon
                sx={ {
                  color: color.Yambo_Text_On_Dark,
                  marginLeft: 1,
                  fontSize: 20,
                  transition: "transform 0.3s ease-in-out",
                  transform: isLinkCopied ? "scale(1.2)" : "scale(1)",
                } }
              />
            </>
          ) : translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.BUTTON_CTA) }

          {isLinkCopied && (
            <CircularProgress
              variant="determinate"
              value={ (COUNTDOWN_VALUE_SECONDS - countdown) * 33.33 }
              size={ 24 }
              thickness={ 5 }
              sx={ {
                color: color.Yambo_Text_On_Dark,
                position: "absolute",
                right: 16,
              } }
            />
          )}
        </Button>
      </Box>
    </Modal>
  );
};

export default ShareDesignAppModal;
