import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut, signInWithPopup, GoogleAuthProvider, OAuthProvider, onIdTokenChanged } from "firebase/auth";
import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { auth } from './firebase';
import server_url from './globals';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigate = useNavigate();

  const signOut = async () => {
    try {
      setCurrentUser(null);
      await firebaseSignOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const updateCurrentUser = (newData) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      settings_data: newData,
    }));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;
      } else {
        setCurrentUser(null);
        delete axios.defaults.headers.common['Authorization'];
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (route, providerType) => {
    setIsSigningIn(true);

    // Dynamically create the provider based on providerType
    const provider = providerType === 'google' ?
      new GoogleAuthProvider() :
      new OAuthProvider('microsoft.com');

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (!user?.uid) {
        console.error("signIn: uid doesn't exist");
      }

      await axios.post(`${server_url}/v1/users/signin`, user, {
        headers: { 'Authorization': `Bearer ${user.accessToken}` },
      });
      axios.defaults.headers.Authorization = `Bearer ${user.accessToken}`;
      setCurrentUser(user);
      setIsSigningIn(false);
      navigate(route);

    } catch (error) {
      console.log("error in signing in user ", error);
      if (error.response?.data?.message === "waiting_list") {
        try {
          // Attempt to delete the user
          const user = auth.currentUser;
          if (user) {
            await user.delete();
          }
        } catch (deleteError) {
          console.error("Failed to delete user: ", deleteError);
        }
        navigate('/early');
      }
      setIsSigningIn(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading || isSigningIn) {
    return (
      <Box className='App' sx={ { width:'100vw', height:'100vh', display:'flex', alignItems:'center', justifyContent:'center' } }>
        <CircularProgress sx={ { position:'relative' } } />
      </Box>
    );
  }

  return (
    <AuthContext.Provider value={ { currentUser, signIn, signOut, updateCurrentUser, isSigningIn } }>
      {children}
    </AuthContext.Provider>
  );
};
