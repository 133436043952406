import axios from "axios";
import axiosRetry from "axios-retry";
import { auth } from "../firebase";
import server_url from "../globals";

const axiosInstance = axios.create({
  baseURL: server_url,
});

axiosRetry(axiosInstance, { retries: 3 });

const refreshToken = async () => {
  try {
    // console.log("Refreshing token...");
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
      
      return token;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    // console.log("adding token to headers");
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log("Response error intercepted:", error); // Log all errors

    // if (error.config) {
    //   console.log("Error config:", error.config);
    // }

    // if (error.response) {
    //   console.log("Error response status:", error.response.status);
    //   console.log("Error response data:", error.response.data);
    // } else if (error.request) {
    //   console.log("Error request:", error.request);
    // } else {
    //   console.log("General error message:", error.message);
    // }

    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      // console.log("401 Unauthorized error detected. Attempting to refresh token...");

      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        
        return axiosInstance(originalRequest);
      } catch (tokenRefreshError) {
        // console.error("Token refresh error:", tokenRefreshError);
      }
    } else if (!error.response) {
      // console.error("Network or CORS error");
    }
    
    return Promise.reject(error);
  },
);

export default axiosInstance;
