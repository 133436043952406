import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { Typography, Box, ButtonBase, Link } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ReplayIcon from '@mui/icons-material/Replay';
import LoadingButton from '@mui/lab/LoadingButton';
import lodash from 'lodash';
import { useTranslation } from "react-i18next";
import { useUserRole } from '../Recipe/UserRoleContext';
import ModelRunContext from '../Recipe/RunFlow/ModelRunContext';
import axiosInstance from "../../services/axiosConfig";
import { CreditsContext } from "../../services/CreditsContext";
import { useMediaGalleryContext } from "../Recipe/FlowComponents/MediaGalleryContext";
import { color } from "../../colors";
import I18N_KEYS from "../../language/keys";
import NodeImageList from "../Recipe/FlowComponents/NodeImageList";
import { runModel } from "./RunModel";
import { sizeOptions } from './ModelParams';
import { renderDynamicField, cleanParamsForSaving } from "./Utils";

let isRunning = new Set();
const MINIMAL_CREDIT_COUNT = 20;

function ModelBaseComponent ({ id, data ,updateNodeData, selectedOutput, setSelectedOutput, setOutput, container, setValidationError }) {

  const posthog = usePostHog();
  const saveParamsHistoryFlag = useFeatureFlagEnabled('save_params_history');
  const { t: translate } = useTranslation();

  const { credits, setUserCredits, openUpgradeModal } = useContext(CreditsContext);
    

  const { modelRunTrigger, updateModelRunTriggerStatus } = useContext(ModelRunContext);

  const role = useUserRole();

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');


  const [predictionId, setPredictionId] = useState(null);
  const [predictionReady, setPredictionReady] = useState(false);
  const [pollingError, setPollingError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [predictionStatus, setPredictionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const predictionCanceled = useRef(null);

  const { showGallery, setShowGallery, setMediaArray, setSelectedFile } = useMediaGalleryContext();
  const [showGalleryIcon, setShowGalleryIcon] = useState(false);

  /// storing params and input for latest run model
  const lastRunParamsAndInputs = useRef({ params: null, input: null });

  const { handles, description, model, input, params, schema } = data;


  // const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const prevInput = usePrevious(data.input);
  // const runInitiatedRef = useRef(new Set());

  const hasNewResults = useRef(null);
  //seed management
  const prevSeed = useRef();

  useEffect(()=>{
    if(params && sizeOptions){
      const index = sizeOptions.findIndex((option) => option.width === params.width && option.height === params.height);
      setSelectedSize(sizeOptions[index]);
    }
  },[sizeOptions]);


  const updateOutputWithNewResults = useCallback(()=>{
    // console.log("updating selected with last index ");
    if(hasNewResults.current){
      setSelectedOutput(data.result? data.result.length -1  : 0);
      setOutput(data.result.length -1);
    }
    hasNewResults.current = null;

  },[data.result, hasNewResults]);

  useEffect(() => {
    // console.log("data.result changed");
    if (hasNewResults.current) {
      updateOutputWithNewResults();
    }
  }, [data.result, updateOutputWithNewResults]);

  ///// schema and params
  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input', 'input-integer', 'input-float'].includes(schema[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    if(schema[key]?.type === 'seed'){
      parsedValue = {
        isRandom: newValue.isRandom,
        seed: parseFloat(newValue.seed) || 1,
      };
    }

    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined;
    }

    //// quick fix for handling runway pricing dif between 5 and 10 seconds
    if(model.name === "rw_video" && key === "duration"){
      let price;
      if(newValue === 5) {
        price = 25;
      }
      else if(newValue === 10) {
        price = 50;
      }
      updateNodeData(id, {
        paid: price,
      });
    }
    updateNodeData(id,{
      params:{
        ...params,
        [key]: parsedValue,
      },
    });
  };

  const handleStopProcessing = (status) => {
    lastRunParamsAndInputs.current = { params: null, input: null };
    setIsProcessing(false);
    setPredictionId(null);
    // runInitiatedRef.current.delete(id);
    isRunning.delete(id);
    setProgress(0);

    if(status)
      updateModelRunTriggerStatus(id, status);
  };

  const handleStartProccessing = useCallback(() => {
    if(saveParamsHistoryFlag){
      lastRunParamsAndInputs.current = {
        params: cleanParamsForSaving(params, prevSeed.current), // Sending the current seed (not the one in params, as it is not yet updated)
        input,
      };
    }
  
    setPredictionId(null);
    setErrorMessage(null);
    setPredictionStatus("starting");
    setIsProcessing(true);
    predictionCanceled.value = false;
    setPredictionReady(false);
    setPollingError(false);
    // runInitiatedRef.current.add(id);
    isRunning.add(id);
  },[params, input]);

  useEffect(()=>{
    if (data.input){
      const validationResult = validateRequiredInput(handles, data.input);
      setValidationError?.(validationResult.missingKeys);
      setErrorMessage(null);
      setPollingError(false);
    }
  },[data.input]);

  const validateRequiredInput = (handles, input) => {
    if (data.version !== 2) return { isValid: true, missingKeys: null }; // backwards compatibility
    let missingKeys = [];
    if (!input) {
      missingKeys = Object.entries(handles.input)
        .filter(([, handle]) => handle.required)
        .map(([key]) => key);
  
      return { isValid: false, missingKeys };
    }
    // Iterate over handles to find missing required inputs
    for (const [key, handle] of Object.entries(handles.input)) {
      if (handle.required && !input[key]) {
        missingKeys.push(key);
      }
    }
    
    return missingKeys.length > 0 ? { isValid: false, missingKeys } : { isValid: true, missingKeys: null };
  };

  // tha main run model function
  const run =  async () => {
    setValidationError([]);
    const validationResult = validateRequiredInput(handles, data.input);
    if(!validationResult.isValid) {
      setValidationError(validationResult.missingKeys);
      setErrorMessage(`${translate(I18N_KEYS.MODEL_NODE.ERROR_REQUIRED)} ${validationResult.missingKeys}`);
      setPollingError(true);
      posthog.capture('run_model_error', { model:  model.name, type: model.name, error:'missing required input' });
      
      return;
    }
  

    
    posthog.capture('run_model_start', { model: model.name, type: model.name });
    // handle seed management
    if(params?.seed){
      if(params.seed.isRandom){
        prevSeed.current = lodash.random(1, 1000000);
      }
      else prevSeed.current = params?.seed.seed || undefined;
    }

    if(params?.seed) {
      updateNodeData(id,{
        params:{
          ...params,
          seed: {
            ...params.seed,
            seed: prevSeed.current,
          },
        },
      });
    }


    handleStartProccessing();
    /// end of seed management

    try {
      setPredictionStatus("initial_processing");
      const response = await runModel(handles, input, model, params, prevSeed.current, data.version);
      if(response.predictionId){
        setPredictionId(response.predictionId);
        updateModelRunTriggerStatus(id, "starting", 0, response.predictionId);
      } else {
        hasNewResults.current = true;
        if(saveParamsHistoryFlag){
          // console.log("adding params and input to results");
          updateNodeData(id, {
            result: data?.result ?
              [
                ...data.result,
                ...response.results.map((result) => ({
                  ...result,
                  params: lastRunParamsAndInputs.current.params,
                  input: lastRunParamsAndInputs.current.input,
                })),
              ] :
              response.results.map((result) => ({
                ...result,
                params: lastRunParamsAndInputs.current.params,
                input: lastRunParamsAndInputs.current.input,
              })),
          });
        } else {
          updateNodeData(id, {
            result: data?.result ? [...data.result, ...response.results] : response.results,
          });
        }

        setIsProcessing(false);
        updateModelRunTriggerStatus(id, "ready", 100);
        setPredictionStatus("succeeded");
        if(response.remainingCredits != null){
          setUserCredits(response.remainingCredits);
        }
        posthog.capture('run_model_end', { model: model.name, type: model.name });
      }
    } catch (error) {
      // console.error("Error running model", error);
      setPollingError(true);
      setErrorMessage(error?.response?.data?.error?.detail);
      handleStopProcessing("failed");
      posthog.capture('run_model_error', { model: model.name, type: model.name, error:error?.response?.data?.error?.detail });
    }
  };

  /// running the model from the outside (flow.jsx)
    
  useEffect(()=>{
        
    if(modelRunTrigger.length === 0)
      return;

    const currentModel = modelRunTrigger.find((m) => m.id === id);
        
    if (!currentModel) return;

    if(currentModel.status === "canceled"){
      predictionCanceled.value = true;
      handleStopProcessing();
      
      return;
    }

    // if(runInitiatedRef.current.has(id)) return;
    if(isRunning.has(id)) return;

    if(currentModel.status !== "pending")
      return;

    const inputsChanged = JSON.stringify(input) !== JSON.stringify(prevInput);
        
    setIsProcessing(true);
        
    /// prevent from running the model twice in parallel (because of the input and modelRunTriggger dependency)
    if(predictionId)
      return;

    const { predecessors } = currentModel;
    if (!predecessors || predecessors.length === 0) {
      run();
      
      return;
    }

    const predecessorsStatus = predecessors.map((predecessorId) =>
      modelRunTrigger.find((m) => m.id === predecessorId)?.status,
    );

    const allPredecessorsReady = predecessorsStatus.every((status) => status === "ready");
    const anyPredecessorFailedOrCanceled = predecessorsStatus.some((status) => status === "failed" || status === "canceled");
        
    if(anyPredecessorFailedOrCanceled){
      predictionCanceled.value = true;
      handleStopProcessing("canceled");
      
      return;
    }
    if(allPredecessorsReady && inputsChanged){
      run();
    }
           
  }, [modelRunTrigger, predictionId ,input]);

  /// POLLING
  const checkPredictionStatus = async (pollingPredictionId) => {
    try {
            
      const response = await axiosInstance.get(`/v1/models/predict/${pollingPredictionId}/status`);
      setPredictionStatus(response.data.status);

      if(predictionCanceled.value) // this is to check if there was a cancel after the status request was sent 
        return;

      switch(response.data.status){
        case "succeeded":
          if(!isRunning.has(id)) return;
          setPredictionReady(true);
          hasNewResults.current = true;

          if(saveParamsHistoryFlag){ // save history rollout
            // console.log("adding params and input to results");
            updateNodeData(id, {
              result: data?.result ?
                [
                  ...data.result,
                  ...response.data.results.map((result) => ({
                    ...result,
                    params: lastRunParamsAndInputs.current.params,
                    input: lastRunParamsAndInputs.current.input,
                  })),
                ] :
                response.data.results.map((result) => ({
                  ...result,
                  params: lastRunParamsAndInputs.current.params,
                  input: lastRunParamsAndInputs.current.input,
                })),
              mjdata: response.data?.mjdata,
            });
          } else {
            updateNodeData(id, {
              result: data?.result ? [...data.result, ...response.data.results] : response.data.results,
              mjdata: response.data?.mjdata,
            });
          }
          
          if(response.data.remainingCredits != null){
            setUserCredits(response.data.remainingCredits);
          }

          handleStopProcessing();
          posthog.capture('run_model_end', { model: model.name, type: model.name });
          break;
        case "starting":
          break;
        case "processing":
          setProgress(response.data.progress);
          updateModelRunTriggerStatus(id, "processing", response.data.progress, predictionId);
          break;
        case "failed":
          setPollingError(true);
          handleStopProcessing("failed");
          if(model.name === "minimax_i2v")
            setErrorMessage(response?.data?.error?.detail);
          else setErrorMessage(response?.data?.error);
          posthog.capture('run_model_error', { model: model.name, type: model.name, error:response?.data?.error });
          break;
        case "canceled":
          handleStopProcessing();
          break;
      }
            
    } catch (error) {
      // console.error("Error checking prediction status", error);
      setPollingError(true);
      handleStopProcessing("failed");
    }
  };

  useEffect(()=> {
    if(predictionStatus === "succeeded" && data.result) {
      updateModelRunTriggerStatus(id, "ready", 100);
      // runInitiatedRef.current.delete(id);
      isRunning.delete(id);
    }
  },[data.result, predictionStatus]);
    
  useEffect(() => {
    if (predictionId && !predictionReady && !pollingError) {
      let startTime = Date.now();
      let isMounted = true; // Flag to track the mounted status
    
      const handlePolling = () => {
        const elapsedTime = Date.now() - startTime;
    
        let interval;
        if (elapsedTime < 10000) {
          interval = 1000;
        } else if (elapsedTime < 30000) {
          interval = 2500;
        } else {
          interval = 5000;
        }
    
        checkPredictionStatus(predictionId).then(() => {
          // Check if the component is still mounted before scheduling the next poll
          if (isMounted && !predictionReady && !pollingError) {
            setTimeout(handlePolling, interval);
          }
        }).catch(() => {
          if (isMounted) {
            setPollingError(true); // Set polling error if there's an exception
          }
        });
      };
    
      handlePolling();
    
      // Cleanup function to clear pending timeouts and update the mounted flag
      return () => {
        isMounted = false; // Indicate the component has unmounted
        isRunning.delete(id);
      };
    }
  }, [predictionId, predictionReady, pollingError]);

  useEffect(() => {
    if (params && selectedSize) {
      updateNodeData(id, {
        params: {
          ...params,
          width: selectedSize.width,
          height: selectedSize.height,
        },
      });
    }
  }, [selectedSize]);


  const renderStatus = (status) => {
    switch (status){
      case "starting":
        return (
          <Typography>Starting</Typography>
        );
      case "processing":
        return (
          <Typography>{progress}%</Typography>
        );
      case "initial_processing":
        return (
          <Typography>Processing</Typography>
        );
      default:
        return (
          <Typography>Waiting</Typography>
        );
    }
  };

  const handleOpenGalleryClick = () => {
    setMediaArray(data.result);
    setSelectedFile(selectedOutput);
    setShowGallery(!showGallery);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space' && showGalleryIcon) {
        event.preventDefault();
        handleOpenGalleryClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showGalleryIcon]);
  
  return(
    <>
      {/* <DynamicNode2 id={id} data={data} className="model" handleColor={colorMap.get(data.color)} > */}
      {container === "node" &&
        <>
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={ { __html: description } }
          />
          <Box sx={ { mt:1 } }>
            {data.result &&
            <Box sx={ { position:'relative' } } onMouseEnter={ ()=>setShowGalleryIcon(true) } onMouseLeave={ ()=>setShowGalleryIcon(false) }>
              <NodeImageList images={ data.result } selected={ selectedOutput } setSelected={ setSelectedOutput } container={ "node" } />
              {data.result.length > 0 && data.result[0].type !== 'text' &&
              <ButtonBase onClick={ handleOpenGalleryClick } sx={ { position:'absolute', bottom:'11px', right:'5px', opacity:showGalleryIcon? 1:0, transition:'opacity 0.2s' } }>
                <FullscreenIcon fontSize='medium' />
              </ButtonBase>
              }
            </Box>
            }
          </Box>
          {data.paid && credits < MINIMAL_CREDIT_COUNT ? (
            <Box
              sx={ {
                display:'flex',
                justifyContent:'center',
                flexDirection:'column',
                alignItems:'center',
                backgroundColor:color.Yambo_Purple,
                borderRadius:1,
                py:.2,
              } }
            >
              <Typography variant="caption">Looks like you're running low on credits,</Typography>
              <Typography variant="caption">
                <Link sx={ { fontWeight:'bold' } } onClick={ ()=>openUpgradeModal() }>Buy more credits</Link> to run this model!
              </Typography>
            </Box>
          
          ) : (
            <LoadingButton
              size="small"
              onClick={ ()=>run() }
              endIcon={ data.result && data.result.length > 0? <ReplayIcon />: <PlayArrowIcon /> }
              loading={ isProcessing }
              loadingPosition="end"
              variant="contained"
              color="weavy_cta"
              fullWidth
              disabled={ role !== 'editor' || (data.paid && credits < MINIMAL_CREDIT_COUNT) }
              // sx={ { mt:1 } }
            >
              {isProcessing ? (
                renderStatus(predictionStatus)
              ):(
                <>{data.result && data.result.length > 0 ? 'Re-run model':'Run Model'} {data.paid && `(${data.paid} Credits)`}</>
              )}
                
            </LoadingButton>
          )}
          {model.name === 'rw_video' &&
          <Box sx={ { width:'100%', px:12, mt:1.5 } }>
            <img src='/powered-by-runway.svg' alt="powered by Runway" width="100%"  />
          </Box>
          }
        </>
      }
      {schema && Object.keys(schema).length !== 0 && container === "drawer" && <Box id='advanced-mode-container' sx={ { width:'100%', mt:1 } }>
        <Box id='advnaced-mode-content-container' className="nowheel nodrag nopan">
          {/* <Typography variant="caption">{name}</Typography> */}
          {Object.keys(schema)
            .sort((a, b) => schema[a].order - schema[b].order)
            .map((key) =>
              renderDynamicField(params, key, schema[key], handleChange),
            )}
        </Box>

      </Box>
      }
      {pollingError && <Box sx={ { mt: 1 } }>
        <Typography sx={ { width:'100%', mt:1 } } variant="caption" color='error'>
              {errorMessage 
          ? (typeof errorMessage === 'string' 
              ? errorMessage 
              : JSON.stringify(errorMessage))
          : "Something went wrong. Check inputs"}
          </Typography>
      </Box>}
    </>

  );
}

export default ModelBaseComponent;
