import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Box, ButtonBase, Divider, FormControl, Grid, InputAdornment, InputBase, Typography } from "@mui/material";
import { color } from "../../../colors";
import { LeftPanelMenuItem } from './LeftPanelMenuItem';
import { flattenMenuItems } from '../../Nodes/Utils';

const menuToShow =
    {
      search:100,
      files:0,
      toolbox:1,
      image:2,
      video:3,
      threedee:4,
      models:5,
    };
  
const CustomInput = styled(InputBase)({
  '& .MuiInputBase-input::placeholder': {
    fontSize: '12px', // Adjust placeholder font size as needed
  },
  height:'100%',
  fontSize:'12px',
  // Add other custom styles as needed
});

function CustomTabPanel(props) {
  const { children, tab, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={ tab !== index }
      id={ `simple-tabpanel-${index}` }
      aria-labelledby={ `simple-tab-${index}` }
      style={ { overflow:"auto", height:'100%' } }
      { ...other }
    >
      {tab === index && (
        <Box sx={ { p:1 } }>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
  
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
};

function LeftPanel (props){

  const { menuItems , selectedMenu, shouldHidePanel, mediaArray } = props;
  const [tab, setTab] = useState(menuToShow[selectedMenu] || 0);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState();
  const searchInput = useRef(null);

  useEffect(()=>{
    if(selectedMenu){
      setSearch("");
      setTab(menuToShow[selectedMenu]);
    }
  },[selectedMenu]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(()=>{
    if(search){
      const res = flattenMenuItems(menuItems, search);
      setSearchResults(res);
    }
  },[search]);


  const onDragStart = (event, item) => {
    const itemString = JSON.stringify(item);
    event.dataTransfer.setData('menuItem', itemString);
    event.dataTransfer.effectAllowed = 'move';
    event.target.classList.add('dragging');
    document.body.style.cursor = 'grabbing';
  };

  const onDragFileStart = (event, file) => {
    const item = {
      type:'import',
      initialData: file,
      id:'wkKkBSd0yrZGwbStnU6r',
    };
    const itemString = JSON.stringify(item);
    event.dataTransfer.setData('menuItem', itemString);
    event.dataTransfer.effectAllowed = 'move';
    event.target.classList.add('dragging');
    document.body.style.cursor = 'grabbing';
  };

  const onDragEnd = (event) => {
    event.target.classList.remove('dragging');
    document.body.style.cursor = '';
  };

  const arrageModelsMenuItem = (items) => {
    const flatModels = [];
    const extractChildren = (item) => {
      if (item?.children) {
        Object.values(item.children).forEach(extractChildren);
      } else {
        flatModels.push(item);
      }
    };
    extractChildren(items);
    
    return flatModels;
  };

  const renderSearchResults = (results) => {
    return (
      <Box sx={ { overflow:"auto", height:'100%', marginBottom:'20px', p:1 } }>
        <Grid container spacing={ 1 }>
          {results && results.map((item)=>(
            <Grid item key={ item.id } xs={ 6 }>
              <div
                onDragStart={ (event)=>onDragStart(event,item) }
                onDragEnd={ (event)=>onDragEnd(event) }
                draggable
                className="menu-item"
              >
                <LeftPanelMenuItem item={ item }  />
              </div>
            </Grid>
          ))}

        </Grid>
      </Box>
    );
  };

  // useEffect(()=>{
  //     if(menuItems){
  //         console.log("menuItems", menuItems);
  // }} ,[menuItems]);       

  return(
    <Box id="flow-left-panel" className={ !shouldHidePanel && selectedMenu ? 'slide-left-enter' : 'slide-left-exit' }
      sx={ {
        width:'100%',
        height:'100%',
        overflow:'hidden',
        background:color.Dark_Blue,
        borderTop:'1px solid',
        borderRight:'1px solid',
        borderBottom:'1px solid',
        borderColor:color.Dark_Grey,
        borderRadius:'0 4px 4px 0',
      } }
    >
      {/* search */}
      <Box sx={ { width: '100%', height:'100%' } }>
        <Box sx={ { borderBottom: 1, borderColor: 'divider', height:'40px', display:'flex' } }>
          <Box id="left-panel-search-container" sx={ { height:'40px', width:'100%', display:'flex' } }>
            <Box sx={ { width:'40px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center' } }>
              <i className="fa-solid fa-magnifying-glass fa-sm"></i>
            </Box>
            <Box sx={ {
              height:'38px',
              background:color.Dark_Blue,
              display:"flex",
              justifyContent:'center',
              width:'100%',
            } }
            >
              <FormControl sx={ {} } variant="standard" fullWidth>
                <CustomInput
                  ref={ searchInput }
                  value={ search }
                  onChange={ handleSearchChange }
                  id="left-panel-search-input"
                  placeholder="find..."
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <ButtonBase
                        onClick={ ()=>{
                          setSearch("");
                          setSearchResults();
                        } }
                        sx={ { mr:1, display: search ? 'block':'none' } }
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </ButtonBase>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
                        
          </Box>
        </Box>
        {/* end of search */}
        {search ? renderSearchResults(searchResults):(
          <Box sx={ { height:'calc(100% - 40px)', overflow:'auto' } }>
          {/* media library */}
            <CustomTabPanel tab={ tab } index={ 0 }>
              {mediaArray && mediaArray.length > 0 ? 
              (
                    <Grid container spacing={ 1 } sx={ { mt:0,mb:1 } }>
                      {mediaArray && mediaArray.length > 0 && mediaArray.map((item, index)=>{
                        return(
                          <Grid item key={ index } xs={ 6 }>
                            <Box
                              onDragStart={ (event)=>onDragFileStart(event,item) }
                              onDragEnd={ (event)=>onDragEnd(event) }
                              draggable
                              className="media-item"
                            >
                                      
                              <Box sx={ { position:'relative' } }>
                                <img src={ item.type !== 'audio' ? item.thumbnailUrl : '/audio.png' } width="100%" height="80px" style={ { objectFit:'cover', display:'block' } } />
                                { item.type === 'image' && <i className="fa-regular fa-image" style={ { position:'absolute', top:'4px', left:'4px', opacity:'.75' } }></i>}
                                { item.type === 'video' && <i className= "fa-solid fa-video" style={ { position:'absolute', top:'4px', left:'4px', opacity:'.75' } }></i> }

                                { item.type === 'audio' && <i className= "fa-solid fa-volume-high" style={ { position:'absolute', top:'4px', left:'4px', opacity:'.75' } }></i>}
                                { item.type !== 'audio' && item.width && item.height &&
                                    <Typography
                                      variant="caption"
                                      sx={ { fontSize:'8px',position:'absolute', bottom:'2px', left:'6px' } }
                                    >
                                      {item.width} <i className="fa-solid fa-x fa-xs"></i> {item.height}
                                    </Typography>}
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ):(
                    <Box sx={ { width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:"column" } }>
                    <Typography variant="caption" sx={ { mt:1 } }>No media found. Yet...</Typography>
                  </Box>
                    )
                  }
            </CustomTabPanel>
            {/* end of media library */}
            {/* toolbox */}
            <CustomTabPanel tab={ tab } index={ 1 }>
                   
              <Grid container spacing={ 1 } sx={ { mb:1, mt:.1 } }>
                        
                {menuItems && Object.keys(menuItems).map((key)=>{
                  const item = menuItems[key];
                  if(!item.children){
                    return(
                      <Grid item key={ item.id } xs={ 6 }>
                        <div
                          onDragStart={ (event)=>onDragStart(event,item) }
                          onDragEnd={ (event)=>onDragEnd(event) }
                          draggable
                          className="menu-item"
                        >
                          <LeftPanelMenuItem item={ item }  />
                        </div>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Typography variant="caption" className="left-panel-section-title">Editing</Typography>
              <Grid container spacing={ 1 } sx={ { mt:0, mb:1 } }>
                        
                {menuItems && Object.keys(menuItems["edit"].children).map((key)=>{
                  const item = menuItems["edit"].children[key];
                  // if(true){
                  
                  return(
                    <Grid item key={ item.id } xs={ 6 }>
                      <div
                        onDragStart={ (event)=>onDragStart(event,item) }
                        onDragEnd={ (event)=>onDragEnd(event) }
                        draggable
                        className="menu-item"
                      >
                        <LeftPanelMenuItem item={ item }  />
                      </div>
                    </Grid>
                  );
                  // }
                })}
              </Grid>
              <Typography variant="caption" className="left-panel-section-title">Helpers</Typography>
              <Grid container spacing={ 1 } sx={ { mt:0,mb:1 } }>
                        
                {menuItems && Object.keys(menuItems["helpers"].children).map((key)=>{
                  const item = menuItems["helpers"].children[key];
                  // if(true){
                  
                  return(
                    <Grid item key={ item.id } xs={ 6 }>
                      <div
                        onDragStart={ (event)=>onDragStart(event,item) }
                        onDragEnd={ (event)=>onDragEnd(event) }
                        draggable
                        className="menu-item"
                      >
                        <LeftPanelMenuItem item={ item }  />
                      </div>
                    </Grid>
                  );
                  // }
                })}
              </Grid>
                

            </CustomTabPanel>
            {/* end of toolbox */}
            {/* models */}
            {/* image models */}
            <CustomTabPanel tab={tab} index={2}>
              <Grid container spacing={1} sx={{ mt: 0, mb: 1 }}>
                {menuItems &&
                  Object.entries(menuItems["models"].children["image"].children).map(([key, section]) => {
                    return (
                      <React.Fragment key={key}>
                        {/* Section Title */}
                        <Grid item xs={12}>
                          <Typography variant="caption" className="left-panel-section-title">{section.displayName}</Typography>
                        </Grid>
                        
                        {/* Section Items */}
                        {section.children &&
                          Object.values(section.children).map((item) => {
                            return (
                              <Grid item key={item.id} xs={6}>
                                <div
                                  onDragStart={(event) => onDragStart(event, item)}
                                  onDragEnd={(event) => onDragEnd(event)}
                                  draggable
                                  className="menu-item"
                                >
                                  <LeftPanelMenuItem item={item} />
                                </div>
                              </Grid>
                            );
                          })}
                          <Box ></Box>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </CustomTabPanel>
              {/* end of image model */}
              {/* video models */}
            <CustomTabPanel tab={ tab } index={ 3 }>
              <Grid container spacing={1} sx={{ mt: 0, mb: 1 }}>
                {menuItems &&
                  Object.entries(menuItems["models"].children["video"].children).map(([key, section]) => {
                    return (
                      <React.Fragment key={key}>
                        {/* Section Title */}
                        <Grid item xs={12}>
                          <Typography variant="caption" className="left-panel-section-title">{section.displayName}</Typography>
                        </Grid>
                        {/* Section Items */}
                        {section.children &&
                          Object.values(section.children).map((item) => {
                            return (
                              <Grid item key={item.id} xs={6}>
                                <div
                                  onDragStart={(event) => onDragStart(event, item)}
                                  onDragEnd={(event) => onDragEnd(event)}
                                  draggable
                                  className="menu-item"
                                >
                                  <LeftPanelMenuItem item={item} />
                                </div>
                              </Grid>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </CustomTabPanel>
            {/* end of video model */}
            {/* 3d */}
            <CustomTabPanel tab={ tab } index={ 4 }>
              <Grid container spacing={1} sx={{ mt: 0, mb: 1 }}>
                {menuItems &&
                  Object.entries(menuItems["models"].children["threedee"].children).map(([key, section]) => {
                    return (
                      <React.Fragment key={key}>
                        {/* Section Title */}
                        <Grid item xs={12}>
                          <Typography variant="caption" className="left-panel-section-title">{section.displayName}</Typography>
                        </Grid>
                        {/* Section Items */}
                        {section.children &&
                          Object.values(section.children).map((item) => {
                            return (
                              <Grid item key={item.id} xs={6}>
                                <div
                                  onDragStart={(event) => onDragStart(event, item)}
                                  onDragEnd={(event) => onDragEnd(event)}
                                  draggable
                                  className="menu-item"
                                >
                                  <LeftPanelMenuItem item={item} />
                                </div>
                              </Grid>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </CustomTabPanel>
            {/* end of 3d */}
            <CustomTabPanel tab={ tab } index={ 5 }>
            {menuItems && menuItems["models"].children["myModels"] && <>
              <Typography variant="caption" className="left-panel-section-title">My Models</Typography>
              <Grid container spacing={ 1 } sx={ { mt:0,mb:1 } }>
                {menuItems && arrageModelsMenuItem(menuItems["models"].children["myModels"]).map((item)=>{
                  if(item){
                    return(
                      <Grid item key={ item.id } xs={ 6 }>
                        <div
                          onDragStart={ (event)=>onDragStart(event,item) }
                          onDragEnd={ (event)=>onDragEnd(event) }
                          draggable
                          className="menu-item"
                        >
                          <LeftPanelMenuItem item={ item }  />
                        </div>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              </>
              }
              {menuItems && menuItems["models"].children["communityModelsMenuEntry"] && <>
              <Typography variant="caption" className="left-panel-section-title">Community Models</Typography>
              <Grid container spacing={ 1 } sx={ { mt:0, mb:1 } }>
                {menuItems && arrageModelsMenuItem(menuItems["models"].children["communityModelsMenuEntry"]).map((item)=>{
                  if(item){
                    return(
                      <Grid item key={ item.id } xs={ 6 }>
                        <div
                          onDragStart={ (event)=>onDragStart(event,item) }
                          onDragEnd={ (event)=>onDragEnd(event) }
                          draggable
                          className="menu-item"
                        >
                          <LeftPanelMenuItem item={ item }  />
                        </div>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </>}
            </CustomTabPanel>
            {/* end of models */}
          </Box>
        )}
                   
      </Box>
    </Box>
  );
}

export default LeftPanel;