export const sizeOptions = [
  { label: "512 x 512", width: 512, height: 512 },
  { label: "688 x 512", width: 688, height: 512 },
  { label: "768 x 512", width: 768, height: 512 },
  { label: "912 x 512", width: 912, height: 512 },
  { label: "512 x 688", width: 512, height: 688 },
  { label: "512 x 768", width: 512, height: 768 },
  { label: "512 x 912", width: 512, height: 912 },
  { label: "704 x 1408", width: 704, height: 1408 },
  { label: "704 x 1344", width: 704, height: 1344 },
  { label: "768 x 1344", width: 768, height: 1344 },
  { label: "768 x 1280", width: 768, height: 1280 },
  { label: "832 x 1216", width: 832, height: 1216 },
  { label: "832 x 1152", width: 832, height: 1152 },
  { label: "896 x 1152", width: 896, height: 1152 },
  { label: "896 x 1088", width: 896, height: 1088 },
  { label: "960 x 1088", width: 960, height: 1088 },
  { label: "960 x 1024", width: 960, height: 1024 },
  { label: "1024 x 1024", width: 1024, height: 1024 },
  { label: "1024 x 960", width: 1024, height: 960 },
  { label: "1088 x 960", width: 1088, height: 960 },
  { label: "1088 x 896", width: 1088, height: 896 },
  { label: "1152 x 896", width: 1152, height: 896 },
  { label: "1152 x 832", width: 1152, height: 832 },
  { label: "1216 x 832", width: 1216, height: 832 },
  { label: "1280 x 768", width: 1280, height: 768 },
  { label: "1344 x 768", width: 1344, height: 768 },
  { label: "1344 x 704", width: 1344, height: 704 },
  { label: "1408 x 704", width: 1408, height: 704 },
  { label: "1472 x 704", width: 1472, height: 704 },
  { label: "1536 x 640", width: 1536, height: 640 },
  { label: "1600 x 640", width: 1600, height: 640 },
  { label: "1664 x 576", width: 1664, height: 576 },
  { label: "1728 x 576", width: 1728, height: 576 },
];


export const schedulerOptions = [
  { label:"K_EULER", scheduler:"K_EULER" },
  { label:"DDIM", scheduler:"DDIM" },
  { label:"DPMSolverMultistep", scheduler:"DPMSolverMultistep" },
  { label:"HeunDiscrete", scheduler:"HeunDiscrete" },
  { label:"KarrasDPM", scheduler:"KarrasDPM" },
  { label:"K_EULER_ANCESTRAL", scheduler:"K_EULER_ANCESTRAL" },
  { label:"PNDM", scheduler:"PNDM" },
];